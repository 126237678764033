import { Grid, Switch, Typography } from '@mui/material';
import { useState } from 'react';

const Confirmation = ({
  hideGiftcardSection,
  setHideGiftcardSection,
  doNotShowDiscountRowIfDiscountNotAdded,
  setDoNotShowDiscountRowIfDiscountNotAdded,
  hidePriceSection,
  setHidePriceSection,
  hideCouponSection,
  setHideCouponSection,
  setIsSaveBar,
}) => {
  return (
    <>
      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            setHideGiftcardSection(!hideGiftcardSection);
            setIsSaveBar(true);
          }}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            paddingX: 2,
            paddingY: 1,
            height: '40px',
          }}
        >
          <Typography variant="subtitle2" sx={{ lineHeight: '1.2 !important' }}>
            Hide giftcard section
          </Typography>
          <Switch
            size="small"
            defaultChecked
            sx={{ marginLeft: 2 }}
            onChange={(e) => {
              setHideGiftcardSection(e.target.checked);
              setIsSaveBar(true);
            }}
            checked={hideGiftcardSection}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid
          onClick={() => {
            setDoNotShowDiscountRowIfDiscountNotAdded(
              !doNotShowDiscountRowIfDiscountNotAdded
            );
            setIsSaveBar(true);
          }}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            paddingX: 2,
            paddingY: 1,
            height: '40px',
            cursor: 'pointer',
          }}
        >
          <Typography variant="subtitle2" sx={{ lineHeight: '1.2 !important' }}>
            Do not show the discount row if a discount is not added
          </Typography>
          <Switch
            size="small"
            defaultChecked
            sx={{ marginLeft: 2 }}
            onChange={(e) => {
              setDoNotShowDiscountRowIfDiscountNotAdded(e.target.checked);
              setIsSaveBar(true);
            }}
            checked={doNotShowDiscountRowIfDiscountNotAdded}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setHidePriceSection(!hidePriceSection);
            setIsSaveBar(true);
          }}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            paddingX: 2,
            paddingY: 1,
            height: '40px',
          }}
        >
          <Typography variant="subtitle2" sx={{ lineHeight: '1.2 !important' }}>
            Hide price section
          </Typography>
          <Switch
            size="small"
            defaultChecked
            sx={{ marginLeft: 2 }}
            onChange={(e) => {
              setHidePriceSection(e.target.checked);
              setIsSaveBar(true);
            }}
            checked={hidePriceSection}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setHideCouponSection(!hideCouponSection);
            setIsSaveBar(true);
          }}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            paddingX: 2,
            paddingY: 1,
            height: '40px',
          }}
        >
          <Typography variant="subtitle2" sx={{ lineHeight: '1.2 !important' }}>
            Hide coupon section
          </Typography>
          <Switch
            size="small"
            defaultChecked
            sx={{ marginLeft: 2 }}
            onChange={(e) => {
              setHideCouponSection(e.target.checked);
              setIsSaveBar(true);
            }}
            checked={hideCouponSection}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Confirmation;
