import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/slices/user.slice.js';

/**
 * A simple wrapper for Upsell features
 * @param code module code
 * @param condition optional param. a condition instead of module code
 * @returns will return children based on user access to that module
 * @example <ModuleWrapper code="SER">
             <Services />
             <UpsellService />
           </ModuleWrapper>
 */
function UpsellComponentWrapper({ code, condition, children }) {
  const { user } = useSelector(selectUser);
  if (code) {
    const access = user.modules.find((module) => {
      return module.code === code;
    });
    return access ? <></> : children;
  }

  return condition ? <></> : children;
}

export default UpsellComponentWrapper;
