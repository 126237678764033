export const getQueries = async (state) => {
  let queryText = '';
  state.map((query, i) => {
    if (query.queryLabel === 'keyword') {
      if (query.ans.length > 3) {
        queryText = queryText + `&${query.queryLabel}=${query.ans}`;
      }
    } else {
      queryText = queryText + `&${query.queryLabel}=${query.ans}`;
    }
  });

  return queryText;
};
