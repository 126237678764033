import { UpnizeBackend } from '../Axios';

export const getCustomerList = async (rows, page, queries) => {
  let response = await UpnizeBackend.get(
    `/customers?rows=${rows}&page=${page}${queries ? queries : ''}`
  );
  return response;
};

export const getCustomerById = async (id) => {
  let response = await UpnizeBackend.get(`/customers/byId/${id}`);
  return response;
};

export const addCustomer = async (body) => {
  let response = await UpnizeBackend.post(`/customers/add`, body);
  return response;
};

export const editCustomer = async (body, id) => {
  let response = await UpnizeBackend.put(
    `/customers/editCustomerNote/${id}`,
    body
  );
  return response;
};

export const getAllJournals = async (customerId) => {
  let response = await UpnizeBackend.get(`/journals/${customerId}`);
  return response;
};

export const deleteJournalById = async (id) => {
  let response = await UpnizeBackend.delete(`/journals/delete/${id}`);
  return response;
};

export const addCustomerJournal = async (data) => {
  let response = await UpnizeBackend.post(`/journals/add`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

export const editCustomerJournal = async (data, id) => {
  let response = await UpnizeBackend.put(`/journals/edit/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

export const editCustomerNote = async (body, id) => {
  let response = await UpnizeBackend.put(
    `/customers/editCustomerNote/${id}`,
    body
  );
  return response;
};

export const addCustomerTenant = async (body, id) => {
  let response = await UpnizeBackend.put(
    `/customers/addCustomerTenant/${id}`,
    body
  );
  return response;
};

export const deleteCustomernById = async (id) => {
  let response = await UpnizeBackend.delete(`/customers/remove`, {
    data: { ids: id },
  });
  return response;
};

export const importCustomers = async (file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    let response = await UpnizeBackend.post(`/customers/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(response);
    return response;
  } catch (e) {
    console.log(e);
  }
};

export const inviteCustomersByEmail = async (body) => {
  let response = await UpnizeBackend.post(`/customers/invite`, body);
  return response;
};
