import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CustomTooltip from '../CustomTooltip';

const CustomIconButton = ({ Icon, toolTipText, onClick }) => {
  return (
    <>
      {toolTipText ? (
        <CustomTooltip arrow placement="top" title={toolTipText}>
          <Button
            // size="large"
            sx={{
              // width: { xs: '100%', sm: '10px' },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '40px',
              // width: '40px',
              // p: '0px !important',
              // marginTop: 5,
              marginBottom: 1,
              // color: '#929da6',
              backgroundColor: 'white',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
              '&.MuiButtonBase-root.MuiButton-root': {
                height: '40px',
                minWidth: '40px !important',
              },
            }}
            onClick={onClick}
          >
            <Icon />
          </Button>
        </CustomTooltip>
      ) : (
        <Button
          // size="large"
          sx={{
            // width: { xs: '100%', sm: '10px' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '40px',
            // width: '40px',
            // p: '0px !important',
            // marginTop: 5,
            marginBottom: 1,
            // color: '#929da6',
            backgroundColor: 'white',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
            '&.MuiButtonBase-root.MuiButton-root': {
              height: '40px',
              minWidth: '40px !important',
            },
          }}
          onClick={onClick}
        >
          <Icon />
        </Button>
      )}
    </>
  );
};

export default CustomIconButton;
