import {
  Box,
  Button,
  Grid,
  Icon,
  MenuItem,
  Select,
  TextField,
  Typography,
  Modal as MUIModal,
  OutlinedInput,
  InputAdornment,
  Tooltip,
  IconButton,
  Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useRef, useEffect } from 'react';
// Icons
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded'; // CSV
import MailOutlinedIcon from '@mui/icons-material/MailOutlined'; // send test email
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'; // remove
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation'; // invite provider

// from 2.0
import RateReviewIcon from '@mui/icons-material/RateReview'; // review rating
import PersonAddIcon from '@mui/icons-material/PersonAdd'; // invite provider
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import useUser from '../../hooks/useUser';
import CancelIcon from '@mui/icons-material/Cancel';
import InviteCustomerModal from './Modals/InviteCustomerModal';
import CustomTextButton from '../CustomButtons/CustomTextButton';
import AddCategoryModal from './Modals/Categories/AddCategory';
import EditCategoryModal from './Modals/Categories/EditCategory';
import AddSubCategoryModal from './Modals/Categories/AddSubCategory';
import EditSubCategoryModal from './Modals/Categories/EditSubCategory';
import DeleteModal from './Modals/DeleteModal';
import HasChildrenModal from './Modals/HasChildrenModal';
import InfoIcon from '@mui/icons-material/Info';
import { emailRegex } from '../../../Regex';

const Modal = ({
  handleOpenModal,
  type,
  open,
  handleDelete,
  id,
  handleUpload,
  handleEdit,
  catName,
  catType,
  catParentId = null,
  fetchCategories,
}) => {
  // take a look here (merged from 2.0)
  const uploadedFile = useRef();
  const [isDragOver, setIsDragOver] = useState(false);
  const [bookMarkData, setBookMarkData] = useState({
    service: 'select',
    location: 'select',
    staff: 'select',
  });
  // const [selectedParentCategory, setSelectedParentCategory] = useState({
  //   name: 'Root category',
  //   id: 0,
  // });
  // const [categoryOptions, setCategoryOptions] = useState([
  //   { name: 'Root category', id: 0 },
  // ]);
  const [selectedParentCategory, setSelectedParentCategory] = useState();
  const handelChange = (e) => {
    const { name, value } = e.target;
    setBookMarkData({ ...bookMarkData, [name]: value });
  };

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const [disabled, setDisabled] = useState(false);

  const handleFileSelection = () => {
    uploadedFile.current.click();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;

    if (files && files.length) {
      // if (files[0].name.endsWith('.csv')) {
      console.log(files);
      setFile(files[0]);
      setFileName(files[0].name);
      if (files[0].type.includes('csv')) {
        setFileType('CSV');
      }
      // }
    }
    console.log('uploaded', fileName);
    setIsDragOver(false);
  };

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
    if (e.target.files[0].type.includes('csv')) {
      setFileType('CSV');
    } else {
      setFileType('');
      // setFile(null);
      // alert('Please select correct file format');
    }

    console.log('file', e.target.files[0].name);
  };

  const parentCatRef = useRef();
  const newParentCatRef = useRef();

  const [values, setValues] = useState([]);
  const [currValue, setCurrValue] = useState('');

  const handleKeyUp = (e) => {
    console.log(e.keyCode);
    if (e.keyCode == 32) {
      setValues((oldState) => [...oldState, e.target.value]);
      setCurrValue('');
    }
  };

  useEffect(() => {
    console.log(values);
  }, [values]);

  const handleChange = (e) => {
    setCurrValue(e.target.value);
  };

  const handleDeleteItem = (item, index) => {
    let arr = [...values];
    arr.splice(index, 1);
    console.log(item);
    setValues(arr);
  };

  const [currEmailValue, setEmailCurrValue] = useState('');
  const [emails, setEmails] = useState([]);
  const [errorEmail, setErrorEmail] = useState('');

  const handleChangeemail = (e) => {
    setEmailCurrValue(e.target.value);
  };

  const handleDeleteEmail = (item, index) => {
    let arr = [...values];
    arr.splice(index, 1);
    console.log(item);
    setEmails(arr);
  };

  const addEmailToList = () => {
    if (errorEmail) {
      setErrorEmail('');
    }

    if (!emailRegex.test(currEmailValue)) {
      setErrorEmail('Invalid Email');
      return;
    }
    setEmails((oldState) => [...oldState, currEmailValue]);
    setEmailCurrValue('');
  };

  return (
    <MUIModal open={open}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { md: 550, xs: '100%' },
          bgcolor: 'background.paper',
          boxShadow: 24,
        }}
      >
        {/* HEADER */}
        <Box
          borderBottom="1px solid #b7b7b7"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: '16px 20px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                backgroundColor: type === 'DELETE' ? '#EE463C' : '#0072D9',
                p: 1,
                mr: 1.5,
                borderRadius: '15px',
                width: '30px',
                height: '30px',
                display:
                  type === 'INVITE_CUSTOMER' || type === 'INVITE_CUSTOMER_CP'
                    ? 'none'
                    : 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {type === 'ADDCAT' && <AddIcon sx={{ color: '#ffffff' }} />}
              {type === 'EDITCAT' && <EditIcon sx={{ color: '#ffffff' }} />}
              {type === 'ADDSUB' && <AddIcon sx={{ color: '#ffffff' }} />}
              {type === 'EDITSUB' && <EditIcon sx={{ color: '#ffffff' }} />}
              {type === 'DELETE' && (
                <DeleteOutlinedIcon sx={{ color: '#ffffff' }} />
              )}
              {type === 'SEND_TEST_EMAIL' && (
                <MailOutlinedIcon sx={{ color: '#ffffff' }} />
              )}
              {type === 'UPLOAD' && (
                <FileUploadRoundedIcon sx={{ color: '#ffffff' }} />
              )}
              {type === 'INVITE' && (
                <InsertInvitationIcon sx={{ color: '#ffffff' }} />
              )}
              {type === 'SHARE' && (
                <InsertInvitationIcon sx={{ color: '#ffffff' }} />
              )}
              {type === 'RATE' && <RateReviewIcon sx={{ color: '#ffffff' }} />}
              {type === 'INVITE_PROVIDER' && (
                <PersonAddIcon sx={{ color: '#ffffff' }} />
              )}

              {type === 'HAS_CHILDREN_MODAL' && (
                <InfoIcon sx={{ color: '#ffffff' }} />
              )}
            </Box>
            <Typography variant="h4" fontSize="20px" fontWeight="bold">
              {type === 'ADDCAT' && 'Add Category'}
              {type === 'EDITCAT' && 'Edit Category'}
              {type === 'ADDSUB' && 'Add Subcategory'}
              {type === 'EDITSUB' && 'Edit Subcategory'}
            </Typography>

            <Typography variant="h4" fontSize="20px" fontWeight="bold">
              {type === 'DELETE' && 'Remove'}
              {type === 'SEND_TEST_EMAIL' && 'Send a test email'}
              {type === 'UPLOAD' && 'Upload'}
              {type === 'INVITE' && 'Invite provider'}
              {type === 'SHARE' && 'Share booking widget'}

              {type === 'RATE' && 'Rate your appointment'}
              {type === 'INVITE_PROVIDER' && 'Invite a provider'}
              {type === 'INVITE_CUSTOMER' && 'Invite Customer by email'}
              {type === 'INVITE_CUSTOMER_CP' && 'Invite Friend by email'}
              {type === 'HAS_CHILDREN_MODAL' && 'Category has children!'}
            </Typography>
          </Box>
          <Icon
            sx={{
              cursor: 'pointer',
              color: '#ADBFC7',
              backgroundColor: 'white',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
              borderRadius: '4px',
              padding: '3px',
            }}
            onClick={handleOpenModal}
          >
            <CloseIcon />
          </Icon>
        </Box>

        {/* body */}

        <Grid
          sx={{
            p: '0px 20px 10px 20px',
          }}
        >
          {type === 'HAS_CHILDREN_MODAL' && (
            <HasChildrenModal id={id} handleOpenModal={handleOpenModal} />
          )}
          {type === 'ADDCAT' && (
            <AddCategoryModal
              handleOpenModal={handleOpenModal}
              fetchCategories={fetchCategories}
            />
          )}
          {type === 'EDITCAT' && (
            <EditCategoryModal
              handleOpenModal={handleOpenModal}
              fetchCategories={fetchCategories}
              catName={catName}
              catType={catType}
              id={id}
            />
          )}
          {type === 'ADDSUB' && (
            <AddSubCategoryModal
              handleOpenModal={handleOpenModal}
              fetchCategories={fetchCategories}
            />
          )}
          {type === 'EDITSUB' && (
            <EditSubCategoryModal
              handleOpenModal={handleOpenModal}
              fetchCategories={fetchCategories}
              catName={catName}
              catType={catType}
              catParentId={catParentId}
              id={id}
            />
          )}
          {type === 'DELETE' && (
            <DeleteModal
              handleOpenModal={handleOpenModal}
              handleDelete={handleDelete}
              id={id}
            />
          )}
          {type === 'SHARE' && (
            <>
              <Grid container spacing={2} marginBottom="2rem">
                <Grid xs={6} item marginTop="1.5rem">
                  <Typography>Service</Typography>
                  <Select
                    sx={{ width: '100%', mt: '5px' }}
                    size="small"
                    name="service"
                    value={bookMarkData.service}
                    onChange={handelChange}
                  >
                    <MenuItem value="select" sx={{ display: 'none' }}>
                      Select
                    </MenuItem>
                    <MenuItem value="fatTansfar">Fat Tanasfar</MenuItem>
                    <MenuItem value="3dmotion">3D Motionn</MenuItem>
                  </Select>
                </Grid>
                <Grid xs={6} item marginTop="1.5rem">
                  <Typography>Location</Typography>
                  <Select
                    sx={{ width: '100%', mt: '5px' }}
                    size="small"
                    name="location"
                    value={bookMarkData.location}
                    onChange={handelChange}
                  >
                    <MenuItem value="select" sx={{ display: 'none' }}>
                      Select
                    </MenuItem>
                    <MenuItem value="dhaka">Dhaka</MenuItem>
                    <MenuItem value="india">India</MenuItem>
                  </Select>
                </Grid>
              </Grid>

              <Grid container spacing={2} marginBottom="2rem">
                <Grid xs={6} item>
                  <Typography>Staff</Typography>
                  <Select
                    sx={{ width: '100%', mt: '5px' }}
                    size="small"
                    name="staff"
                    value={bookMarkData.staff}
                    onChange={handelChange}
                  >
                    <MenuItem value="select" sx={{ display: 'none' }}>
                      Select
                    </MenuItem>
                    <MenuItem value="Alex">AlexGender</MenuItem>
                    <MenuItem value="Hasibul">Hasibul</MenuItem>
                  </Select>
                </Grid>
              </Grid>

              <Grid marginBottom="1.5rem">
                <Box
                  item
                  sx={{
                    background: '#d8d8d8',
                    padding: '13px',
                    textAlign: 'center',
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: '16px', fontWeight: '500' }}
                  >
                    https://upnize.com/?page_id=2&service=43
                  </Typography>
                </Box>
              </Grid>
              <Grid container sx={{ justifyContent: 'end' }}>
                <Button
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    backgroundColor: 'white',
                    color: '#b3a3a6',
                    height: '45px',
                    border: '1px solid #b3a3a6',
                    ':hover': {
                      backgroundColor: 'white',
                      color: '#b3a3a6',
                    },
                  }}
                  variant="contained"
                  onClick={handleOpenModal}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    marginLeft: { xs: 0, sm: 2 },
                    height: '45px',
                  }}
                  variant="contained"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      'https://upnize.com/?page_id=2&service=43'
                    );
                  }}
                >
                  Copy URL
                </Button>
              </Grid>
            </>
          )}
          {type === 'INVITE' && (
            <>
              <Grid marginBottom="1.5rem">
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ mt: 3, mb: 1, color: '#515151' }}>
                    Message
                  </Typography>
                  <TextField
                    multiline
                    maxRows={2}
                    rows={2}
                    placeholder="Your message here..."
                  ></TextField>
                  <Typography sx={{ mt: 3, mb: 1, color: '#515151' }}>
                    Provider email
                  </Typography>
                  <TextField
                    multiline
                    placeholder="example@email.com"
                  ></TextField>
                </Box>
              </Grid>

              <Grid container sx={{ justifyContent: 'end' }}>
                <Button
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    backgroundColor: 'white',
                    color: '#b3a3a6',
                    height: '45px',
                    border: '1px solid #b3a3a6',
                    ':hover': {
                      backgroundColor: 'white',
                      color: '#b3a3a6',
                    },
                  }}
                  variant="contained"
                  onClick={handleOpenModal}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    marginLeft: { xs: 0, sm: 2 },
                    height: '45px',
                  }}
                  variant="contained"
                >
                  Invite
                </Button>
              </Grid>
            </>
          )}
          {type === 'SEND_TEST_EMAIL' && (
            <>
              <Grid
                marginBottom="1.5rem"
                marginTop="1.5rem"
                sx={{ height: '200px' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Grid item xs={12} mb={2}>
                    <Typography variant="subtitle2">Email</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      // value={formData.languageName}
                      // onChange={(e) => handleFormData("email", e.target.value)}
                      id="outlined-basic"
                      size="small"
                      required
                      variant="outlined"
                      placeholder="Example@test.com"
                      sx={{
                        marginBottom: '2rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                    />
                  </Grid>
                </Box>
              </Grid>

              <Grid
                container
                sx={{ justifyContent: 'end', gap: { xs: 0, sm: 2 } }}
              >
                <CustomTextButton
                  variant="contained"
                  size="large"
                  onClick={handleOpenModal}
                >
                  Cancel
                </CustomTextButton>
                <CustomTextButton type="blue" variant="contained" size="large">
                  SEND
                </CustomTextButton>
              </Grid>
            </>
          )}
          {type === 'UPLOAD' && (
            <>
              <Grid
                marginBottom="1.5rem"
                marginTop="1.5rem"
                sx={{ height: '200px' }}
              >
                <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    // onMouseOver={() => setDisabled(true)}
                    // onMouseLeave={() => setDisabled(false)}
                    onClick={async () => {
                      const res = await fetch(
                        `${process.env.REACT_APP_UPNIZE_BACKEND_URL}/tenant/customers/export`,
                        {
                          method: 'GET',
                        }
                      )
                        .then((res) => res.blob())
                        // .then((data) => console.log(data))
                        .then((data) => {
                          const csvData = new Blob([data], {
                            type: 'text/csv;charset=utf-8;',
                          });
                          let file = URL.createObjectURL(csvData);
                          let a = document.createElement('a');
                          a.download = 'template.csv';
                          a.href = file;
                          a.click();
                          console.log(csvData);
                        });
                    }}
                    variant="contained"
                    sx={{
                      width: 'max-content',
                      mb: '1rem',
                    }}
                  >
                    Download an example
                  </Button>
                </Grid>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      border: '#D6D6D6 dashed 2px',
                      cursor: 'pointer',
                      // transition: 'all 0.25s',
                      // backgroundColor: isDragOver && '#D6D6D6',
                    }}
                    onClick={() => {
                      handleFileSelection();
                    }}
                    onDrop={(e) => handleDrop(e)}
                    onDragOver={(e) => handleDragOver(e)}
                    onDragEnter={(e) => handleDragEnter(e)}
                    onDragLeave={(e) => handleDragLeave(e)}
                  >
                    <Box>
                      <Typography color={'#D6D6D6'}>
                        Drop your CSV file here or click here to upload
                      </Typography>
                      {file && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid #eee',
                            width: '100%',
                            height: '50px',
                            padding: '10px',
                            position: 'relative',
                          }}
                        >
                          <CancelIcon
                            onMouseOver={() => setDisabled(true)}
                            onClick={() => {
                              setFile(null);
                              setDisabled(false);
                            }}
                            sx={{
                              position: 'absolute',
                              top: '-10px',
                              right: '-10px',
                              color: '#161A33',
                            }}
                          />
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Box
                              sx={{
                                fontWeight: 'bold',
                              }}
                            >
                              {fileName}
                            </Box>
                            <Box
                              sx={{
                                color: '#ccc',
                              }}
                            >
                              {fileType}
                            </Box>
                          </Box>
                        </Box>
                      )}

                      <TextField
                        disabled={disabled}
                        accept={'.csv'}
                        onChange={handleOnChange}
                        id="outlined-basic"
                        required
                        sx={{
                          marginBottom: '1rem',
                          width: '100%',
                          fontSize: '14px',
                          display: 'none',
                        }}
                        // onChange={(e) => getUserImage(e.target.files[0])}
                        inputRef={uploadedFile}
                        type="file"
                      />
                    </Box>
                  </Grid>
                </Box>
              </Grid>

              <Grid container sx={{ justifyContent: 'end' }}>
                <Button
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    backgroundColor: 'white',
                    color: '#b3a3a6',
                    height: '45px',
                    border: '1px solid #b3a3a6',
                    ':hover': {
                      backgroundColor: 'white',
                      color: '#b3a3a6',
                    },
                  }}
                  variant="contained"
                  onClick={handleOpenModal}
                >
                  Cancel
                </Button>
                <Button
                  onClick={async () => {
                    handleUpload(file, setFile);
                  }}
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    marginLeft: { xs: 0, sm: 2 },
                    height: '45px',
                  }}
                  variant="contained"
                >
                  Upload
                </Button>
              </Grid>
            </>
          )}
          {type === 'RATE' && (
            <>
              <Grid marginBottom="1.5rem" marginTop="1.5rem">
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Select>
                      <MenuItem value={1}>1 star</MenuItem>
                      <MenuItem value={2}>2 star</MenuItem>
                      <MenuItem value={3}>3 star</MenuItem>
                      <MenuItem value={4}>4 star</MenuItem>
                      <MenuItem value={5}>5 star</MenuItem>
                    </Select>
                    <TextField
                      sx={{ mt: 3 }}
                      multiline
                      maxRows={4}
                      minRows={4}
                      placeholder="You review here.."
                    ></TextField>
                  </Box>
                </Box>
              </Grid>

              <Grid container sx={{ justifyContent: 'end' }}>
                <Button
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    backgroundColor: 'white',
                    color: '#b3a3a6',
                    height: '45px',
                    border: '1px solid #b3a3a6',
                    ':hover': {
                      backgroundColor: 'white',
                      color: '#b3a3a6',
                    },
                  }}
                  variant="contained"
                  onClick={handleOpenModal}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: { xs: '100%', sm: 130 },
                    marginBottom: 1,
                    marginLeft: { xs: 0, sm: 2 },
                    height: '45px',
                  }}
                  variant="contained"
                >
                  Ok
                </Button>
              </Grid>
            </>
          )}

          {type === 'INVITE_PROVIDER' && (
            <>
              <Grid marginBottom="1.5rem" marginTop="1.5rem">
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <Grid>
                    {errorEmail && (
                      <div
                        style={{
                          backgroundColor: '#e54141',
                          borderRadius: '5px',
                          color: 'white',
                          padding: '10px',
                        }}
                      >
                        <p style={{ margin: '0' }}>{errorEmail}</p>
                      </div>
                    )}
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <TextField
                        sx={{ mt: 3 }}
                        multiline
                        maxRows={4}
                        rows={4}
                        placeholder="Your message here..."
                      ></TextField>
                      <Typography sx={{ mt: 1, mb: 1 }}>
                        Provider email
                      </Typography>

                      <Grid sx={{ paddingY: 1 }}>
                        <OutlinedInput
                          sx={{
                            width: '100%',
                          }}
                          fullWidth
                          size="small"
                          placeholder="example@email.com"
                          value={currEmailValue}
                          onChange={handleChangeemail}
                          onKeyUp={(e) => e.key === 'Enter' && addEmailToList()}
                          endAdornment={
                            <InputAdornment position="end">
                              {currEmailValue && (
                                <Tooltip
                                  title="add email to invite list"
                                  placement="top"
                                >
                                  <IconButton
                                    onClick={addEmailToList}
                                    edge="end"
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </InputAdornment>
                          }
                        />
                        <p>Providers to be Invited:</p>
                        {emails.length > 0 && (
                          <div>
                            {emails.map((item, index) => (
                              <Chip
                                style={{ margin: '2px' }}
                                size="small"
                                onDelete={() => handleDeleteEmail(item, index)}
                                label={item}
                              />
                            ))}
                          </div>
                        )}
                        {emails.length <= 0 && (
                          <p style={{ color: 'grey' }}>Empty</p>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
              </Grid>

              <Grid
                container
                sx={{ display: 'flex', justifyContent: 'end', gap: '15px' }}
              >
                <CustomTextButton text="Cancel" onClick={handleOpenModal} />
                <CustomTextButton text="Save" type="blue" />
              </Grid>
            </>
          )}
          {type === 'INVITE_CUSTOMER' && (
            <InviteCustomerModal
              tenantPanel
              handleOpenModal={handleOpenModal}
            />
          )}

          {type === 'INVITE_CUSTOMER_CP' && (
            <InviteCustomerModal handleOpenModal={handleOpenModal} />
          )}
        </Grid>
      </Box>
    </MUIModal>
  );
};

export default Modal;
