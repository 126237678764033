import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import Drawer from '../../components/Drawer';
import DrawerFooter from '../../components/DrawerFooter';
import Grid from '@mui/material/Grid';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  addLocation,
  editLocationById,
  getLocationById,
} from '../../Api/tenant/locations';
import LocationTab from './Tabs';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import { uniqueId } from 'lodash';
import { getStaffList } from '../../Api/tenant/staff';
import { getServiceList } from '../../Api/tenant/services';
import { useTranslation } from 'react-i18next';

const dayList = [
  {
    id: uniqueId('day-info-'),
    day: 'Sunday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Monday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Tuesday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Wednesday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Thursday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Friday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Saturday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
];
const requiredFields = [
  'name',
  'address',
  'zipCode',
  'country',
  'city',
  'email',
  'phone',
];

const AddLoactions = ({
  toggleDrawer,
  drawerType,
  drawerData = null,
  fetchLocations,
}) => {
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [companyLocation, setCompanyLocation] = useState({
    name: '',
    address: '',
    email: '',
    phone: '',
    country: '',
    city: '',
    zipCode: '',
  });
  const [logo, setLogo] = React.useState(null);
  const [services, setServices] = useState([]);
  const [staff, setStaff] = useState([]);

  // error handling
  const [errors, setErrors] = useState({});

  const [timesheets, setTimesheets] = useState(dayList);
  const [spts, setSpts] = useState(false);

  // Array of files
  const [image, setImage] = useState(null);
  const [interiorList, setInteriorList] = useState([]);
  const [exteriorList, setExteriorList] = useState([]);

  // Displayed images
  const [interiorCurrentList, setInteriorCurrentList] = useState([]);
  const [exteriorCurrentList, setExteriorCurrentList] = useState([]);

  // retrieved images (URLs coming from backend)
  // keeping track of the uploaded images to determine if the user deleted any images
  const [uploadedInteriorCurrentList, setUploadedInteriorCurrentList] =
    useState(drawerData?.interiorImages ? drawerData.interiorImages : []);
  const [uploadedExteriorCurrentList, setUploadedExteriorCurrentList] =
    useState(drawerData?.exteriorImages ? drawerData.exteriorImages : []);

  // location story
  const [locationStory, setLocationStory] = useState({
    'stories[ENG]': '<p><br></p>',
    'stories[SV]': '<p><br></p>',
    'stories[ESP]': '<p><br></p>',
    'stories[DU]': '<p><br></p>',
  });

  // location settings

  const [locationSettings, setLocationSettings] = useState({
    website: true,
    services: true,
    menus: true,
    products: true,
    reviews: true,
    headerImage: true,
    tcs: true,
    bookingWidget: true,
    staff: true,
    weeklyLunch: false,
    productPrices: false,
    about: true,
    IntExtImages: true,
  });

  const { t } = useTranslation('location');

  // location images handlers

  const readURL = (files) => {
    let promises = [];
    for (let file of files) {
      let filePromise = new Promise((res, rej) => {
        const reader = new FileReader();
        reader.onload = (e) => res(e.target.result);
        reader.onerror = (e) => rej(e);
        reader.readAsDataURL(file);
      });
      promises.push(filePromise);
    }
    return promises;
  };

  const handleChangeInteriorImage = async () => {
    const promises = readURL(interiorList);
    if (promises.length !== 0) {
      Promise.all(promises).then((fileContents) => {
        setInteriorCurrentList((prev) => [
          ...uploadedInteriorCurrentList,
          ...fileContents,
        ]);
      });
    } else {
      if (drawerType !== 'edit') {
        // clear the last image
        setInteriorCurrentList([]);
      } else {
        // additional check for edit modals if there is already uploaded images or not
        if (uploadedInteriorCurrentList.length === 0) {
          setInteriorCurrentList([]);
        } else {
          // if there is no files update the uploaded images
          setInteriorCurrentList([...uploadedInteriorCurrentList]);
        }
      }
    }
  };

  const handleChangeExteriorImage = async () => {
    const promises = readURL(exteriorList);
    if (promises.length !== 0) {
      // prevent same images from getting cloned over and over
      Promise.all(promises).then((fileContents) => {
        setExteriorCurrentList((prev) => [
          ...uploadedExteriorCurrentList,
          ...fileContents,
        ]);
      });
    } else {
      // if there is no images uploaded
      if (drawerType !== 'edit') {
        // clear the last image
        setExteriorCurrentList([]);
      } else {
        // if there is no uploaded images clear the last image
        if (uploadedExteriorCurrentList.length === 0) {
          setExteriorCurrentList([]);
        } else {
          // if there is no files update the uploaded images
          setExteriorCurrentList([...uploadedExteriorCurrentList]);
        }
      }
    }
  };

  useEffect(() => {
    handleChangeInteriorImage();
  }, [interiorList, uploadedInteriorCurrentList]);

  useEffect(() => {
    handleChangeExteriorImage();
  }, [exteriorList, uploadedExteriorCurrentList]);

  // API
  useLayoutEffect(() => {
    if (drawerType === 'edit') {
      const {
        name,
        address,
        phone,
        country,
        city,
        zipCode,
        email,
        headerImage,
        logo,
        settings,
        interiorImages,
        exteriorImages,
        story,
      } = drawerData;

      console.log(name);
      setCompanyLocation({
        name,
        address,
        email,
        phone,
        country,
        city,
        zipCode,
      });
      setImage(headerImage);
      setLogo(logo);
      setLocationSettings(settings);
      console.log(interiorImages);
      setInteriorCurrentList(interiorImages);
      setExteriorCurrentList(exteriorImages);

      const currentStoriesObj = {};

      for (const key in story) {
        currentStoriesObj[`story[${key}]`] =
          story[key].length !== 0 ? story[key] : '<p><br></p>';
      }

      setLocationStory(currentStoriesObj);
    }

    if (drawerType === 'add') {
      getStaffList(1000, 1).then((res) => {
        if (res.data?.rows) {
          setStaff(res.data.rows.map((st) => ({ id: st.id })));
        }
      });

      getServiceList(1000, 1).then((res) => {
        if (res.data?.rows) {
          setServices(res.data.rows.map((ser) => ({ id: ser.id })));
        }
      });
    }
  }, []);

  const fetchStaffData = useCallback(async () => {
    try {
      const response = await getLocationById(drawerData?.id);
      if (response.status === 200) {
        const result = response.data;
        console.log(result);
        setTimesheets(result.timesheets);
        setSpts(result.spts);
        setStaff(result.staff);
        setServices(result.services);
      }
    } catch (err) {
      console.log(err);
    }
  }, [drawerData?.id]);

  useEffect(() => {
    if (drawerType === 'edit' && drawerData?.id) {
      fetchStaffData();
    }
  }, [drawerData?.id, drawerType, fetchStaffData]);

  const handleAddLocation = async () => {
    // validation handling
    let err = {};

    for (const key in companyLocation) {
      if (!companyLocation[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });

    if (Object.keys(err).length === 0) {
      let formdata = new FormData();

      for (const key in companyLocation) {
        formdata.append(key, companyLocation[key]);
      }

      image && formdata.append('headerImage', image);
      formdata.append('visible', true);
      formdata.append('tcs[ENG]', locationStory[0]); // TODO: delete this

      for (const key in locationStory) {
        formdata.append(key, locationStory[key]);
      }

      interiorList.map((img) => {
        formdata.append('interiorImages', img);
      });

      exteriorList.map((img) => {
        formdata.append('exteriorImages', img);
      });

      formdata.append('settings', JSON.stringify(locationSettings));

      formdata.append('timesheets', JSON.stringify(timesheets));

      staff.map((st) => {
        formdata.append('staff', JSON.stringify(st));
      });
      services.map((sv) => {
        formdata.append('services', JSON.stringify(sv));
      });
      formdata.append('spts', spts);

      addLocation(formdata).then((res) => {
        toggleDrawer();
        fetchLocations();
      });
    }
  };

  const handleEditLocation = () => {
    // validation handling
    let err = {};

    for (const key in companyLocation) {
      if (!companyLocation[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });

    if (Object.keys(err).length === 0) {
      let formdata = new FormData();

      for (const key in companyLocation) {
        formdata.append(key, companyLocation[key]);
      }

      formdata.append('logo', logo);
      formdata.append('headerImage', image);
      formdata.append('tcs[ENG]', locationStory[0]); // TODO: delete this

      for (const key in locationStory) {
        formdata.append(key, locationStory[key]);
      }

      interiorList.map((img) => {
        formdata.append('interiorImages', img);
      });

      interiorCurrentList.map((img) => {
        if (typeof img == 'string' && !img.includes('base64')) {
          formdata.append('interiorImages', img);
        }
      });

      exteriorList.map((img) => {
        formdata.append('exteriorImages', img);
      });

      exteriorCurrentList.map((img) => {
        if (typeof img == 'string' && !img.includes('base64')) {
          formdata.append('exteriorImages', img);
        }
      });

      staff.map((st) => {
        formdata.append('staff', JSON.stringify(st));
      });
      services.map((sv) => {
        formdata.append('services', JSON.stringify(sv));
      });

      const newTimesheets = timesheets.map((timesheet) => {
        timesheet.breaks = timesheet.breaks.map((breakTime) => {
          if (breakTime?.id?.toString().includes('breaks')) delete breakTime.id;
          return breakTime;
        });
        return timesheet;
      });

      formdata.append('timesheets', JSON.stringify(newTimesheets));
      formdata.append('spts', spts);

      formdata.append('settings', JSON.stringify(locationSettings));

      editLocationById(formdata, drawerData.id).then((res) => {
        toggleDrawer();
        fetchLocations();
      });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Drawer
          toggleDrawer={toggleDrawer}
          title={
            drawerType === 'add'
              ? t('location:drawer.addTitle')
              : t('location:drawer.editTitle')
          }
          type={drawerType === 'add' ? 'ADD' : 'EDIT'}
        >
          {/* -----------FORM----- */}
          <Grid
            sx={{
              height: '100%',
              overflowY: 'auto',
              width: '100%',
            }}
          >
            <LocationTab
              {...{
                companyLocation,
                setCompanyLocation,
                timesheets,
                setTimesheets,
                spts,
                setSpts,
                interiorList,
                setInteriorList,
                exteriorList,
                setExteriorList,
                interiorCurrentList,
                setInteriorCurrentList,
                exteriorCurrentList,
                setExteriorCurrentList,
                image,
                setImage,
                locationSettings,
                setLocationSettings,
                locationStory,
                setLocationStory,
                logo,
                setLogo,
                drawerType,
                errors,
                setErrors,
                setUploadedInteriorCurrentList,
                setUploadedExteriorCurrentList,
                uploadedInteriorCurrentList,
                uploadedExteriorCurrentList,
                services,
                setServices,
                staff,
                setStaff,
              }}
            />
          </Grid>

          {/* -----Button */}

          <DrawerFooter>
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: isSmallScreen ? 'flex-start' : 'flex-end',
                }}
              ></div>

              <div
                style={{
                  display: 'flex',
                  gap: '15px',
                  justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                  width: isSmallScreen ? '100%' : 'auto',
                  alignItems: 'center',
                }}
              >
                <CustomTextButton
                  variant="contained"
                  size="large"
                  onClick={() => {
                    toggleDrawer();
                  }}
                >
                  {t('common:buttons.cancel')}
                </CustomTextButton>
                <CustomTextButton
                  type="blue"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    if (drawerType === 'add') {
                      handleAddLocation();
                    } else {
                      handleEditLocation();
                    }
                  }}
                >
                  {t('common:buttons.save')}
                </CustomTextButton>
              </div>
            </>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default AddLoactions;
