import React from 'react';
import { Box, Button, Divider, Modal, Typography } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';

function ResponsiveCart({
  open,
  handleClose,
  selectedModules,
  setNumOfUsers,
  setPerUser,
  setSelectedModules,
  numOfUsers,
  setIsPaymentStep,
  perUser,
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        // className="right-sidebar"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          boxShadow: 24,
          width: '90%',
          p: '20px',
          my: '20px',
          border: '1px solid #e5e5fd',
          borderRadius: '5px',
          background:
            'linear-gradient(180deg, rgba(236,249,254,1) 50%, rgba(212,226,253,1) 80%, rgba(255,255,255,1) 100%)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: '10px',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              fontWeight: 900,
              fontSize: '25px',
            }}
          ></Typography>
          <Typography
            sx={{
              fontWeight: 900,
              fontSize: '25px',
              color: '#32313E',
            }}
          >
            Your cart
          </Typography>
        </Box>
        <Box>
          <Divider
            sx={{
              mt: '10px',
              mb: '10px',
            }}
          />
          {selectedModules.map((m) => (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  sx={{
                    color: '#32313E',
                  }}
                >
                  {m.name}
                </Typography>
                <Typography
                  sx={{
                    color: '#39d581',
                    fontWeight: 'bold',
                  }}
                >
                  23€/Month
                </Typography>
              </Box>
              <Divider
                sx={{
                  mt: '10px',
                  mb: '10px',
                }}
              />
            </>
          ))}
          {/* <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Dashboard</Typography>
          <Typography
            sx={{
              color: '#39d581',
            }}
          >
            1€/Month
          </Typography>
        </Box>
        <Divider
          sx={{
            mt: '10px',
            mb: '10px',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Payment gateway</Typography>
          <Typography
            sx={{
              color: '#39d581',
            }}
          >
            1€/Month
          </Typography>
        </Box>
        <Divider
          sx={{
            mt: '10px',
            mb: '10px',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Reports</Typography>
          <Typography
            sx={{
              color: '#39d581',
            }}
          >
            1€/Month
          </Typography>
        </Box>
        <Divider
          sx={{
            mt: '10px',
            mb: '10px',
          }}
        /> */}
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: 500,
              mt: '30px',
              mb: '15px',
              color: '#32313E',
            }}
          >
            Choose users:
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderRadius: '10px',
              border: '1px solid #eee',
              padding: '10px',
              backgroundColor: '#fff',
              mb: '20px',
            }}
          >
            <RemoveCircleOutlineRoundedIcon
              sx={{
                cursor: 'pointer',
                color: '#32313E',
              }}
              onClick={() => {
                if (numOfUsers > 0) {
                  setNumOfUsers(numOfUsers - 1);
                  setPerUser(numOfUsers * 3 - 3);
                } else {
                  setNumOfUsers(0);
                }
              }}
            />
            <Box
              sx={{
                color: '#32313E',
              }}
            >
              {numOfUsers}
            </Box>
            <AddCircleOutlineRoundedIcon
              sx={{
                cursor: 'pointer',
                color: '#32313E',
              }}
              onClick={() => {
                setNumOfUsers((prev) => prev + 1);
                setPerUser(3 + numOfUsers * 3);
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: '10px',
          }}
        >
          <Typography
            sx={{
              color: '#32313E',
            }}
          >
            Freemium package
          </Typography>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#32313E',
            }}
          >
            0€/month
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: '10px',
          }}
        >
          <Typography
            sx={{
              color: '#32313E',
            }}
          >
            Users (Per user 3€)
          </Typography>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#32313E',
            }}
          >
            {perUser}€/month
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: '10px',
            mb: '20px',
          }}
        >
          <Typography
            sx={{
              color: '#32313E',
            }}
          >
            Extension:
          </Typography>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#32313E',
            }}
          >
            {selectedModules.length * 2}€/month
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '10px',
            border: '1px solid #eee',
            padding: '10px',
            backgroundColor: '#fff',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '20px',
                color: '#32313E',
              }}
            >
              {perUser + selectedModules.length * 2}€
            </Typography>
            <Typography
              sx={{
                color: '#32313E',
              }}
            >
              /month
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              onClick={() => {
                setIsPaymentStep(true);
              }}
            >
              Subscribe
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#ccc',
            gap: '10px',
            mt: '5px',
            cursor: 'pointer',
          }}
          onClick={() => {
            setPerUser(0);
            setSelectedModules([]);
            setNumOfUsers(0);
            window.location.reload(false);
          }}
        >
          <CancelOutlinedIcon
            sx={{
              fontSize: '22px',
            }}
          />
          <Typography
            sx={{
              fontSize: '22px',
            }}
          >
            Reset
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
}

export default ResponsiveCart;
