import { uniqueId } from 'lodash';

// Do not change the order
export const dayList = [
  {
    id: uniqueId('day-info-'),
    day: 'Sunday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Monday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Tuesday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Wednesday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Thursday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Friday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Saturday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
];
