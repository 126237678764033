import { projectEnviromentType } from '../constants';

export const disableSectionFunc = (content, options) => {
  // const { contentOnDisable } = { contentOnDisable: null };
  const contentOnDisable = null;
  const currentEnv = process.env.REACT_APP_ENV;

  if (currentEnv === projectEnviromentType.production) return contentOnDisable;
  // return content;
};
