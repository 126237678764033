import {
  Grid,
  Container,
  Typography,
  Box,
} from "@mui/material";
import { React, useMemo, useState } from "react";
import UpnizeLogo from "../../assets/images/UpnizeLogo.png";
import CustomtextField from "../../components/CustomTextField/index";
import CustomButton from "../../components/CustomButton";
import Footer from "../../components/footer";
import CustomProgress from "../../components/ProgressBar";
import { forgotPassword } from "./../../Api/common/users";
import { useDispatch } from "react-redux";
import { callSnackbar } from "../../redux/slices/snackbar.slice";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function ForgotPassword() {
  let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [email, setEmail] = useState();
  const [isCheckYourEmail, setIsCheckYourEmail] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.id) {
      navigate("/appointment");
    }
  }, [navigate, user]);

  const reset = async () => {
    try {
      if(!email) {
        return;
      }
      if (!email?.match(regexEmail) || !email) {
        setEmailValid(false);
        return;
      }

      setLoading(true);
      setEmailValid(true);

      await forgotPassword({ email })
      
      setLoading(false);
      setIsCheckYourEmail(true);
    } catch(err) {
      setLoading(false);
      dispatch(callSnackbar({ message: err.response.data, type: "error" }));
    }
  };

  const helperText = useMemo(() => {
    if(!email) return "You need to enter an Email"
    if(!emailValid) return "You need to enter a valid email"
    if(isCheckYourEmail) return "Email Sent"
    return ""
  }, [email, emailValid, isCheckYourEmail])

  return (
    <>
      {!user?.id && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#F9F8FF",
            justifyContent: "space-between",
            height: "100vh",
            overflow: "hidden",
            px: { md: "50px", xs: "10px" },
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <Grid align="center" my={6}>
              <img src={UpnizeLogo} alt="Profile" width="200px" />
            </Grid>
            <div>
              <Container
                component="section"
                maxWidth="lg"
                style={{ padding: 0 }}
              >
                <Grid
                  lg={12}
                  item
                  container
                  alignItems="stretch"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: "400px",
                  }}
                >
                  <div
                    style={{
                      boxShadow: "1px 2px 9px #C0C0C0",
                      padding: "2em",
                      backgroundColor: "white",
                      width: "100%",
                    }}
                  >
                    <Typography fontWeight="bold" sx={{ fontSize: "2rem" }}>
                      Forgot your password
                    </Typography>
                    <Typography
                      component="h1"
                      variant="h6"
                      sx={{ marginBottom: "5%", marginTop: "5px" }}
                      fontSize={"14px"}
                    >
                      Happens to the best of us. Help is a click away. Just
                      enter your Email
                    </Typography>
                    <CustomtextField
                      e2e="email"
                      customData={{ label: "Email" }}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                      error={email === "" || !emailValid}
                      helperText={helperText}
                      style={{ marginBottom: "3%" }}
                    />
                    {loading ? (
                      <CustomProgress />
                    ) : (
                      <CustomButton e2e="send-email" label="Send Email" onClick={reset} />
                    )}
                  </div>
                </Grid>
              </Container>
            </div>
          </div>
          <Footer />
        </Box>
      )}
    </>
  );
}
export default ForgotPassword;
