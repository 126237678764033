import { React, useState } from 'react';
import Button from '@mui/material/Button';
import UpnizeLogo from '../../assets/images/UpnizeLogo.png';
import Page1 from './page1';
import Page2 from './page2';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Footer from '../../components/footer';
import { welcome } from '../../Api/common/users';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { fetchUser } from '../../redux/slices/user.slice.js';
import { useDispatch } from 'react-redux';
import { activateTrial } from '../../Api/common/stripe';

const SwitchPage = ({ currentStep, data, setData, errors }) => {
  switch (currentStep) {
    case 1:
      return <Page1 data={data} setData={setData} />;
    case 2:
      return <Page2 data={data} setData={setData} errors={errors} />;
    default:
      return <Page1 data={data} setData={setData} />;
  }
};

function Welcome() {
  const [currentStep, setCurrentStep] = useState(1);
  const [data, setData] = useState({
    language: 'English',
    country: 'SE',
    timezone: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    industry: null,
    activateTrial: true,
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if (user) {
      if (!user?.isWelcome) {
        navigate('/');
      }
    } else {
      navigate('/login');
    }
  }, [navigate, user]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let err = {};
    if (currentStep === 1) {
      setCurrentStep(2);
    } else {
      for (const key in data) {
        if (!data[key] && key !== 'activateTrial') {
          setErrors({ ...errors, [key]: true });
          err = { ...err, [key]: true };
        }
      }

      if (Object.keys(err).length === 0) {
        const userData = {
          language: data.language,
          country: data.country,
          timezone: data.timezone,
          industry: data.industry,
        };
        try {
          const res = await welcome(userData, user.id);
          if (res.status === 200) {
            // activating trial
            // Note stripe's error was the issue that couldn't continue the process of updating logged in user data
            // and redirecting him to dashboard
            // Reason: catch of the welcome page was fetching stripes issue and blocked the continuing of the process
            // Solution: wrapped the stripe error to its own try and catch so the stripe's issue doesn't block the flow
            if (data.activateTrial) {
              try {
                await activateTrial(user.TenantId);
              } catch (error) {
                console.log(error);
              }
            }
            await dispatch(fetchUser()).unwrap();

            navigate('/');
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  return (
    <>
      {user && user?.isWelcome && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: '#F9F8FF',
            minHeight: '100vh',
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <Grid align="center" my={6}>
              <img src={UpnizeLogo} alt="Profile" width="200px" />
            </Grid>
            <div>
              <Container
                component="section"
                style={{ padding: 0, maxWidth: '500px', width: '100%' }}
              >
                <Grid
                  lg={12}
                  item
                  container
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    px: '10px',
                  }}
                >
                  <Box
                    style={{
                      boxShadow: '1px 2px 9px #C0C0C0',
                      padding: '2em',
                      backgroundColor: 'white',
                      width: '100%',
                    }}
                  >
                    <Typography sx={{ fontSize: '2rem' }} fontWeight="bold">
                      Welcome to Upnize!
                    </Typography>
                    <Typography component="h1" variant="h6" fontSize={'14px'}>
                      {currentStep === 1
                        ? 'Seems like this is the first time you are logging in. We just need a bit more information to get you started. 🚀'
                        : 'Just a few more details to create the best experience for you'}
                    </Typography>
                    <SwitchPage
                      data={data}
                      setData={setData}
                      currentStep={currentStep}
                      errors={errors}
                    />
                    <Box
                      width="100%"
                      component="form"
                      onSubmit={handleSubmit}
                      noValidate
                      sx={{ mt: 1 }}
                    >
                      <Button
                        e2e="continue"
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, textTransform: 'capitalize' }}
                      >
                        Continue
                      </Button>
                      <Grid container spacing={2}>
                        <Grid item md={11} sm={11} xs={11}>
                          <MobileStepper
                            variant="progress"
                            steps={3}
                            position="static"
                            activeStep={currentStep}
                            sx={{
                              width: '200%',
                              backgroundColor: 'transparent',
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          md={1}
                          sm={1}
                          xs={1}
                          sx={{ color: '#4473cf' }}
                        >
                          {currentStep}/2
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Container>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}

export default Welcome;
