import { Box, Card, Checkbox, Divider, Typography } from '@mui/material';
import React from 'react';

function ModuleCard({ item, handleModuleClick, isSelected, comingSoon }) {
  return (
    <Card
      key={item.id}
      sx={{
        color: '#32313E',
        borderRadius: '5px',
        border: '1px solid #e5e5fd',
        boxShadow: '#e5e5fd 0px 8px 24px',
        height: '300px',
        width: '100%',
        boxSizing: 'border-box',
        cursor: comingSoon ? 'default' : 'pointer',
      }}
      onClick={() => {
        if (!comingSoon) handleModuleClick(item);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between',
          backgroundColor: isSelected(item) ? '#F2F0F8' : '#fff',
        }}
      >
        <Box
          sx={{
            padding: '20px 20px 0 20px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontWeight: 1000,
                fontSize: '20px',
                height: '85px',
                color: '#32313E',
              }}
            >
              {item.name}
            </Typography>

            <Checkbox checked={isSelected(item)} disabled={comingSoon} />
          </Box>
          <Typography
            sx={{
              fontWeight: 1000,
              fontSize: '34px',
              color: '#32313E',
            }}
          >
            {item.monthlyPrice}€/month
          </Typography>
          <a
            href={'#'}
            target="_blank"
            style={{
              textDecoration: 'none',
              color: '#32313E',
              width: 'fit-content',
            }}
          >
            {comingSoon ? (
              <Typography
                sx={{
                  padding: '10px',
                  border: '1px solid #D9DAE2',
                  width: 'fit-content',
                  borderRadius: '5px',
                  color: '#32313E',
                  mt: '10px',
                  mb: '20px',
                }}
              >
                Coming soon
              </Typography>
            ) : (
              <Typography
                sx={{
                  padding: '10px',
                  border: '1px solid #D9DAE2',
                  width: 'fit-content',
                  borderRadius: '5px',
                  mt: '10px',
                  mb: '20px',
                }}
              >
                View details
              </Typography>
            )}
          </a>
          <Typography
            sx={{
              fontSize: '14px',
              color: '#32313E',
            }}
          >
            {item.tagline}
          </Typography>
        </Box>
        <Divider color="#32313E" sx={{ mx: '20px', mb: '15px' }} />
      </Box>
    </Card>
  );
}

export default ModuleCard;
