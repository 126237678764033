import {
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { editCategoryById } from '../../../../Api/tenant/categories';
import { useState } from 'react';
import { customHelperTextErr } from '../../../../utils/CustomHelperTextErr';
import CustomTextButton from '../../../CustomButtons/CustomTextButton';

const EditCategoryModal = ({
  handleOpenModal,
  fetchCategories,
  catName,
  catType,
  id,
}) => {
  const [categoryName, setCategoryName] = useState(catName);
  const [categoryType, setCategoryType] = useState(catType);
  const [errors, setErrors] = useState({
    name: [],
    type: [],
  });

  const handleSubmit = async () => {
    try {
      const errs = {
        name: [],
        type: [],
      };
      let hasError = false;

      if (!categoryName) {
        errs.name.push('Category Name Required');
        hasError = true;
      }

      if (!categoryType) {
        errs.type.push('Category Type Required');
        hasError = true;
      }

      if (hasError) {
        setErrors(errs);
        return;
      }

      await editCategoryById({ name: categoryName, type: categoryType }, id);
      handleOpenModal();
      fetchCategories();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Grid sm={12} xs={12}>
        <Grid sx={{ py: 5 }}>
          <Typography variant="subtitle2" sx={{ my: 1 }}>
            Category name<sapn style={{ color: 'red' }}>*</sapn>
          </Typography>
          <TextField
            fullWidth
            size="small"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            error={errors.name.length > 0}
            helperText={
              errors.name.length > 0 ? customHelperTextErr(errors.name) : ''
            }
          />
          <Typography variant="subtitle2" sx={{ my: 1 }}>
            Category Type<sapn style={{ color: 'red' }}>*</sapn>
          </Typography>
          <Select
            fullWidth
            size="small"
            value={categoryType}
            onChange={(e) => setCategoryType(e.target.value)}
            error={errors.name.length > 0}
            helperText={
              errors.name.length > 0 ? customHelperTextErr(errors.type) : ''
            }
          >
            <MenuItem value="product">Product</MenuItem>
            <MenuItem value="restaurant">Restaurant</MenuItem>
            <MenuItem value="service">Service</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container sx={{ justifyContent: 'end', gap: '15px' }}>
        <CustomTextButton
          variant="contained"
          size="large"
          onClick={handleOpenModal}
        >
          Cancel
        </CustomTextButton>
        <CustomTextButton
          type="blue"
          variant="contained"
          size="large"
          onClick={handleSubmit}
        >
          Save
        </CustomTextButton>
      </Grid>
    </>
  );
};

export default EditCategoryModal;
