export const defaultPageSizeOptions = [10, 25, 50, 25];

export const projectEnviromentType = {
  test: 'TEST',
  production: 'PRODUCTION',
};

export const premiumPages = [
  { path: '/task', code: 'KBTM' },
  { path: '/report', code: 'RPT' },
  { path: '/appointment', code: 'APP' },
  { path: '/calendar', code: 'GCAL' },
  { path: '/service', code: 'SER' },
  { path: '/restaurant', code: 'RM' },
  { path: '/products', code: 'PROD' },
  { path: '/review', code: 'GREV' },
  { path: '/giftcard', code: 'GFTC' },
  { path: '/invoice', code: 'INV' },
  { path: '/notification', code: 'SMS' },
  { path: '/workflow', code: 'WF' },
  { path: '/role', code: 'STRM' },
];
