import { useState } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import SaveSlideBar from '../../SaveSlideBar';
import NoData from '../../NoData';

export const tableData = [
  {
    id: 1,
    email: 'Alexender Inci',
    date: '09/07/2022 04:00 AM - 12:00 AM',
    service: '4D Ultrsound',
    serviceExtra: '4D Ultrsound',
    status: 'Pending',
    price: 12000,
    duration: '2 hours',
  },

  {
    id: 3,
    email: 'Inci Alexender',
    date: '09/07/2022 04:00 AM',
    service: '4D Ultrsound',
    serviceExtra: '4D ult',
    status: 'Pending',
    statusText: 'Custom one',
    statusColor: '#063970',
    statusBgColor: '#2596be',
  },

  {
    id: 4,
    email: 'Inci Alexender',
    date: '09/07/2022 04:00 AM',
    service: '4D Ultrsound',
    status: 'Connected',
    price: 1000,
  },

  {
    id: 5,
    email: 'Inci Alexender',
    date: '09/07/2022 04:00 AM',
    service: '4D Ultrsound',
    serviceExtra: '4D Ultrsound',
    status: 'Connected',
  },
];

const ConnectSubCustomers = ({ fetchCurrentSettings, currentSettings }) => {
  const [isSaveBar, setIsSaveBar] = useState(false);
  // setIsSaveBar(true); add on change events
  const mediaQuery1238 = useMediaQuery('(min-width:1238px)');

  const handelClick = () => {};

  const handleSave = () => {
    // const userFormData = new FormData();
    // for (const el in formData) {
    //   userFormData.append(el, formData[el]);
    // }
    // userFormData.append("image", image);
    // userFormData.append("displayCompanyLogo", displayCompanyLogo);
    // editSettings(userFormData).then((res) => {
    //   if (res.status === 200) {
    //     fetchCurrentSettings();
    //     setIsSaveBar(false);
    //   }
    // });
  };

  const handleCancel = () => {
    // setFormData(currentSettings.CompanyDetails);
    // setDisplayCompanyLogo(currentSettings.CompanyDetails.displayCompanyLogo);
    // setImage(currentSettings.CompanyDetails.companyImage || null);
    // setIsSaveBar(false);
  };

  const header = [
    {
      field: 'email',
      headerName: 'EMAIL',
      width: 250,
      flex: 1,

      renderCell: (params) => (
        <Grid container xs={7} sx={{ marginY: { lg: 2 }, ml: { lg: 2 } }}>
          <div
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {params.value}
          </div>
        </Grid>
      ),
      renderHeader: (params) => (
        <Grid
          container
          xs={7}
          sx={{ marginY: 1, ml: 2, fontWeight: 900, fontSize: 12 }}
        >
          {params.colDef.headerName}
        </Grid>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      flex: 1,
      renderCell: (params) =>
        params.value === 'Pending' ? (
          <Typography color={'#EBA086'} fontSize={'14px'}>
            {params.value}
          </Typography>
        ) : params.value === 'Connected' ? (
          <Typography color={'#9E87ED'} fontSize={'14px'}>
            {params.value}
          </Typography>
        ) : (
          '-'
        ),
    },

    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      flex: 1,
      minWidth: 100,
      getActions: (params) => [
        <GridActionsCellItem
          label="Send invitation"
          onClick={() => handelClick('DETAILS', params.id, params)}
          showInMenu
        />,
        <GridActionsCellItem label="Remove" onClick={() => {}} showInMenu />,
      ],
    },
  ];

  return (
    <>
      <Grid
        xs={12}
        sx={{
          marginTop: { xs: 9, sm: 14 },
          backgroundColor: 'white',
          pb: 1,
          mb: 5,
        }}
      >
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
            Connect
            <span style={{ color: '#c1c1c1' }}>
              <CircleIcon sx={{ fontSize: '7px', marginX: '3px' }} /> Sub
              customers
            </span>
          </Typography>
        </Grid>
        <Grid container style={{ padding: '0' }}>
          <Grid container xs={12}>
            <Grid xs={12} sm={12}>
              <DataGrid
                sx={{
                  border: 0,
                  '& .MuiDataGrid-virtualScroller': {
                    minHeight: '300px !important',
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontSize: '12px',
                    fontWeight: '900 !important',
                    textOverflow: 'clip',
                    whiteSpace: 'break-spaces',
                    lineHeight: 1,
                  },
                  '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                    backgroundColor: 'white',
                    border: 'none',
                  },
                  height: '400px',
                }}
                rows={tableData}
                columns={header}
                components={{
                  NoRowsOverlay: NoData,
                  NoResultsOverlay: NoData,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default ConnectSubCustomers;
