import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useState } from 'react';
import TimeSheet from './TimeSheet';
import StaffDetails from './staffDetails';
import SpecialDays from './SpecialDays';
import HoliDays from './HoliDays';
import { StaffServices } from './StaffServices';
import { StaffLocations } from './StaffLocations';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledTab = styled(Tab)({
  fontSize: '12px',
  fontWeight: 'bold',
  padding: 0,
});

export default function StaffTab({
  staffData,
  setStaffData,
  image,
  setImage,
  specialDays,
  setSpecialDays,
  userData,
  setUserData,
  holidays,
  setHolidays,
  drawerType,
  drawerData,
  errors,
  timesheets,
  setTimesheets,
  spts,
  setSpts,
  locations,
  setLocations,
  services,
  setServices,
}) {
  // tabBar hanldling
  const [value, setValue] = useState(0);
  const { t } = useTranslation('common');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mx: 3, mt: 2 }}>
        <Box>
          <Tabs
            variant="scrollable"
            sx={{ fontSize: '10px' }}
            value={value}
            onChange={handleChange}
          >
            <StyledTab
              label={t('staff:drawer.staffDetails.title')}
              {...a11yProps(0)}
              sx={{ mr: '7px' }}
            />
            <StyledTab
              label={t('staff:drawer.services.title')}
              {...a11yProps(1)}
              sx={{ mr: '7px' }}
            />
            <StyledTab
              label={t('staff:drawer.location.title')}
              {...a11yProps(2)}
              sx={{ mr: '7px' }}
            />
            <StyledTab
              label={t('staff:drawer.weeklySchedule.title')}
              {...a11yProps(3)}
              sx={{ mx: '7px' }}
            />
            <StyledTab
              label={t('staff:drawer.specialDays.title')}
              {...a11yProps(4)}
              sx={{ mx: '7px' }}
            />
            <StyledTab
              label={t('staff:drawer.holidays.title')}
              {...a11yProps(5)}
              sx={{ mx: '7px' }}
            />
          </Tabs>
        </Box>
      </Box>
      <TabPanel value={value} index={0}>
        <StaffDetails
          staffData={staffData}
          setStaffData={setStaffData}
          userData={userData}
          setUserData={setUserData}
          image={image}
          setImage={setImage}
          drawerType={drawerType}
          drawerData={drawerData}
          errors={errors}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <StaffServices services={services} setServices={setServices} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <StaffLocations locations={locations} setLocations={setLocations} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TimeSheet
          timesheets={timesheets}
          setTimesheets={setTimesheets}
          spts={spts}
          setSpts={setSpts}
          errors={errors}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <SpecialDays
          specialDays={specialDays}
          setSpecialDays={setSpecialDays}
          errors={errors}
        />
      </TabPanel>
      <TabPanel value={value} index={5} errors={errors}>
        <HoliDays
          holidays={holidays}
          setHolidays={setHolidays}
          errors={errors}
        />
      </TabPanel>
    </Box>
  );
}
