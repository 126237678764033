import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  DataGrid,
  GridActionsCellItem,
  GridMoreVertIcon,
} from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CustomTableFooter from '../../components/CustomTableFooter';
import Modal from '../../components/Modal';
import { styled, useTheme } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';
import {
  deleteServiceById,
  editServiceById,
  getServiceList,
} from '../../Api/tenant/services';
import { useMediaQuery } from '@mui/material';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CustomTooltip from '../../components/CustomTooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoData from '../../components/NoData';
import useFilterBar from '../../hooks/useFilterBar';
import { getQueries } from '../../utils/GetQueries';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { durationFormater } from '../../utils/durationFormater';
import { useDispatch } from 'react-redux';
import { drawerTypes } from '../../constants/drawerTypes';
import { crudTypes } from '../../constants/crudTypes';
import { drawersActions } from '../../redux/slices/drawers.slice';
import { useTranslation } from 'react-i18next';

const filters = [
  {
    id: 1,
    title: 'Quick search',
    type: 'search',
    queryLabel: 'keyword',
  },
];

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    color: theme.palette.grey[500],
  },
  [`& .${gridClasses.columnHeader}`]: {
    backgroundColor: '#EFF1FC',
    color: '#3A3A50',
  },
}));

const Services = () => {
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);
  const [services, setServices] = useState(null);
  const [rows, setRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const { FilterBar, state, FilterButton } = useFilterBar({
    filters,
  });
  const { t } = useTranslation(['service', 'common']);

  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };
  // delete child
  const handleDeleteOpenModal = (id) => {
    setOpenModal({ open: true, type: 'delete-modal', id: id });
  };

  // const handelHide = (body, id) => {
  //   let formdata = new FormData();
  //   formdata.append('visible', body.visible);
  //   editServiceById(formdata, id).then((res) => {
  //     fetchServices();
  //   });
  // };

  const handelHide = (body, id) => {
    editServiceById(body, id);
    fetchServices();
  };

  // API

  const fetchServices = async () => {
    setTableLoading(true);
    getServiceList(100, 1).then((res) => {
      setServices(res.data.rows);
    });
  };

  // fetch on render
  useEffect(() => {
    fetchServices();
  }, []);

  // fetch on search
  useEffect(() => {
    getQueries(state).then((queries) => {
      setTableLoading(true);
      getServiceList(100, 1, queries).then((res) => {
        setServices(res.data.rows);
      });
    });
  }, [state]);

  // update table rows on fetch
  useEffect(() => {
    if (services) {
      setRows(services);
      setTableLoading(false);
    }
  }, [services]);

  const deleteService = async (id) => {
    deleteServiceById(id).then((res) => {
      fetchServices();
    });
  };

  //  Table Columns
  const columns = [
    {
      field: 'name',
      headerName: t('common:tableHeaders.name'),
      minWidth: 280,
      flex: 1,
    },
    {
      field: 'category',
      headerName: t('common:tableHeaders.category'),
      flex: 1,
      minWidth: 200,
      valueGetter: ({ value }) => {
        return value.name;
      },
    },
    {
      field: 'price',
      headerName: t('common:tableHeaders.price'),
      flex: 1,
      minWidth: 180,
    },
    {
      field: 'duration',
      headerName: t('common:tableHeaders.duration'),
      flex: 1,
      minWidth: 190,
      valueGetter: ({ value }) => {
        return durationFormater(value);
      },
    },
    {
      field: 'actions',
      headerName: t('common:tableHeaders.actions'),
      type: 'actions',
      align: 'right',
      minWidth: 120,
      getActions: (params) => {
        if (params.row.isHidden) {
          return [
            <GridActionsCellItem
              icon={<VisibilityOffIcon />}
              label="Hide Section"
            />,
            <GridActionsCellItem
              label="hide"
              icon={
                params.row.visible ? (
                  <CustomTooltip
                    arrow
                    placement="top"
                    title="Disable item may not be available in other pages and network"
                  >
                    <Box
                      sx={{
                        backgroundColor: '#fff',
                        padding: '3px 5px',
                        borderRadius: '5px',
                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                      }}
                    >
                      <VisibilityIcon />
                    </Box>
                  </CustomTooltip>
                ) : (
                  <Box
                    sx={{
                      backgroundColor: '#fff',
                      padding: '3px 5px',
                      borderRadius: '5px',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                    }}
                  >
                    <VisibilityOffIcon />
                  </Box>
                )
              }
              onClick={() =>
                handelHide(
                  {
                    visible: !params.row.visible,
                  },
                  params.row.id
                )
              }
            />,
            <GridActionsCellItem
              label="Edit"
              onClick={() => {
                dispatch(
                  drawersActions.openDrawer({
                    type: drawerTypes.SERVICE,
                    title: t('service:drawer.editTitle'),
                    crudType: crudTypes.EDIT,
                    data: params.row,
                    refetchData: () => {
                      fetchServices();
                    },
                  })
                );
              }}
              showInMenu
            />,
            <GridActionsCellItem
              label="Delete"
              onClick={() => {
                setOpenModal({
                  open: true,
                  type: 'delete-modal',
                  id: params.id,
                });
              }}
              showInMenu
            />,
            <GridActionsCellItem
              label="Share"
              onClick={() => {
                setOpenModal({ open: true, type: 'share-modal' });
              }}
              showInMenu
            />,
          ];
        }
        return [
          <GridActionsCellItem
            label="hide"
            icon={
              params.row.visible ? (
                <CustomTooltip
                  arrow
                  placement="top"
                  title="Disable item may not be available in other pages and network"
                >
                  <Box
                    sx={{
                      backgroundColor: '#fff',
                      padding: '3px 5px',
                      borderRadius: '5px',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                    }}
                  >
                    <VisibilityIcon />
                  </Box>
                </CustomTooltip>
              ) : (
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    padding: '3px 5px',
                    borderRadius: '5px',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                  }}
                >
                  <VisibilityOffIcon />
                </Box>
              )
            }
            onClick={() =>
              handelHide(
                {
                  visible: !params.row.visible,
                },
                params.row.id
              )
            }
          />,
          <GridActionsCellItem
            label="Edit"
            onClick={() => {
              dispatch(
                drawersActions.openDrawer({
                  type: drawerTypes.SERVICE,
                  title: t('service:drawer.editTitle'),
                  crudType: crudTypes.EDIT,
                  data: params.row,
                  refetchData: () => {
                    fetchServices();
                  },
                })
              );
            }}
            showInMenu
          />,
          <GridActionsCellItem
            label="Delete"
            onClick={() => {
              setOpenModal({ open: true, type: 'delete-modal', id: params.id });
            }}
            showInMenu
          />,
          <GridActionsCellItem
            label="Share"
            onClick={() => {
              setOpenModal({ open: true, type: 'share-modal' });
            }}
            showInMenu
          />,
        ];
      },
    },
  ];

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        // paddingLeft: 4,
        // paddingRight: 4,
        px: '20px',
      }}
    >
      {openModal.type === 'delete-modal' ? (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'DELETE'}
          open={openModal.open}
          id={openModal.id}
          handleDelete={deleteService}
        />
      ) : (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'SHARE'}
          open={openModal.open}
        />
      )}

      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          sx={{
            marginTop: 2.5,
            marginBottom: 1,
            fontSize: 24,
            color: '#3A3A50',
          }}
        >
          {t('service:title')}
        </Typography>
        <Box sx={{ width: { xs: '100%', sm: 'fit-content' } }}></Box>
      </Grid>
      <Box
        sx={{
          width: '100%',
          // width: { xs: '100%', sm: 'fit-content' },
          display: 'flex',
          // alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <CustomTextButton
          text={'+ ' + t('service:addServiceBtn')}
          onClick={() => {
            dispatch(
              drawersActions.openDrawer({
                type: drawerTypes.SERVICE,
                title: t('service:drawer.addTitle'),
                crudType: crudTypes.ADD,
                refetchData: () => {
                  fetchServices();
                },
              })
            );
          }}
        />
        <Box sx={{ display: 'flex', gap: '10px', ml: '10px' }}>
          {FilterButton}
        </Box>
      </Box>
      {/* --------------------SEARCH------------------------------- */}
      {FilterBar}

      {/* --------------------TABLE--------------------------------- */}
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ marginTop: '20px' }}
      >
        <div style={{ width: '100%' }}>
          <StripedDataGrid
            sx={{
              border: 0,
              '& .MuiDataGrid-virtualScroller': {
                minHeight: '300px !important',
              },
              minHeight: '60vh',
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px',
                fontWeight: '900 !important',
              },
              '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
                border: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
            getRowClassName={(params) => {
              return params.row.visible ? 'odd' : 'even';
            }}
            rows={rows}
            columns={columns}
            loading={tableLoading}
            // checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            autoHeight
            componentsProps={{
              columnMenu: { background: 'red' },
            }}
            components={{
              MoreActionsIcon: () => (
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    padding: '3px 5px',
                    borderRadius: '5px',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                  }}
                >
                  <MoreVertIcon sx={{}} />
                </Box>
              ),
              NoRowsOverlay: NoData,
              NoResultsOverlay: NoData,
              Footer: () => (
                <CustomTableFooter
                  selectionModel={selectionModel}
                  handleDeleteOpenModal={handleDeleteOpenModal}
                />
              ),
              MoreActionsIcon: () => (
                <GridMoreVertIcon
                  sx={{
                    backgroundColor: 'white',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                    borderRadius: '4px',
                    padding: '3px',
                    cursor: 'pointer',
                  }}
                />
              ),
              NoRowsOverlay: () => {
                return (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'Center',
                      gap: '20px',
                    }}
                  >
                    <img
                      src="https://user-images.githubusercontent.com/109676648/254145031-57027cd6-fecd-4ce2-96fc-9d891c8de53a.svg"
                      alt="no data"
                      style={{
                        width: '100px',
                        height: '100px',
                      }}
                    />
                    No data at the moment 😔
                  </Box>
                );
              },
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Services;
