import { Box } from '@mui/system';
import React from 'react';

const NoData = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'Center',
        gap: '20px',
      }}
    >
      <img
        src="https://user-images.githubusercontent.com/109676648/254145031-57027cd6-fecd-4ce2-96fc-9d891c8de53a.svg"
        alt="no data"
        style={{
          width: '100px',
          height: '100px',
        }}
      />
      No data at the moment 😔
    </Box>
  );
};

export default NoData;
