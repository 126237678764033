import { Box, Grid, Typography } from '@mui/material';
import CustomTextButton from '../../CustomButtons/CustomTextButton';

const DeleteModal = ({ handleOpenModal, handleDelete, id }) => {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography>Are you sure you want to remove?</Typography>
      </Box>
      <Grid container sx={{ justifyContent: 'end', gap: '15px' }}>
        <CustomTextButton
          variant="contained"
          size="large"
          onClick={handleOpenModal}
        >
          No
        </CustomTextButton>
        <CustomTextButton
          variant="contained"
          size="large"
          type="delete"
          onClick={() => {
            handleDelete(id);
            handleOpenModal();
          }}
        >
          yes
        </CustomTextButton>
      </Grid>
    </>
  );
};

export default DeleteModal;
