import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  DataGrid,
  GridActionsCellItem,
  GridMoreVertIcon,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import UploadIcon from '@mui/icons-material/Upload';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CustomTableFooter from '../../components/CustomTableFooter';
import UsageHistory from './UsageHistory';
import AddLoactions from './AddLoactions';
import Modal from '../../components/Modal';
import { styled, useTheme } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';
import AvatarComp from '../../components/Avatar';
import {
  deleteLocationById,
  editLocationById,
  getLocationList,
} from '../../Api/tenant/locations';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import CustomIconButton from '../../components/CustomButtons/CustomIconButton';
import { defaultPageSizeOptions } from '../../constants';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CustomTooltip from '../../components/CustomTooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useFilterBar from '../../hooks/useFilterBar';
import { getQueries } from '../../utils/GetQueries';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useMediaQuery } from '@mui/material';
import NoData from '../../components/NoData';
import { Avatar } from '@mui/material';
import crown from '../../assets/images/crown1.svg';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import CustomTypography from '../../components/CustomTypography';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    color: theme.palette.grey[500],
  },
  [`& .${gridClasses.columnHeader}`]: {
    backgroundColor: '#EFF1FC',
    color: '#3A3A50',
  },
}));

const filters = [
  {
    id: 1,
    title: 'Quick search',
    type: 'search',
    queryLabel: 'keyword',
  },
];

const Locations = () => {
  const [addCouponDrawer, setAddCouponDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState('add');
  const [drawerData, setDrawerData] = useState(null);
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);
  const [drawer, setDrawer] = useState(null);
  const [tableLoading, setTableLoading] = useState(true);
  const [locations, setLocations] = useState();
  const [rows, setRows] = useState([]);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('lg'));
  const { FilterBar, state, FilterButton } = useFilterBar({
    filters,
  });
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  const { t } = useTranslation(['location', 'common']);

  const toggleAddCouponDrawer = () => {
    setAddCouponDrawer(!addCouponDrawer);
  };

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  const handleDeleteOpenModal = () => {
    setOpenModal({ open: true, type: 'delete-modal' });
  };

  const toggleUsageHistoryModal = (e) => {
    e.stopPropagation();
    setDrawer((prevState) =>
      prevState !== 'usage-history' ? 'usage-history' : ''
    );
  };
  //POPOVER
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // API

  const fetchLocations = async () => {
    setTableLoading(true);
    getLocationList(pageSize, pageCount).then((res) => {
      setLocations(res.data.rows);
    });
  };

  // fetch on render
  useEffect(() => {
    fetchLocations();
  }, []);

  // fetch on search
  useEffect(() => {
    getQueries(state).then((queries) => {
      setTableLoading(true);
      getLocationList(pageSize, pageCount, queries).then((res) => {
        setLocations(res.data.rows);
      });
    });
  }, [state, pageSize, pageCount]);

  // update table rows on fetch
  useEffect(() => {
    if (locations) {
      setRows(locations);
      setTableLoading(false);
    }
  }, [locations]);

  // handlers
  const deleteLocation = (id) => {
    deleteLocationById(id).then((res) => {
      fetchLocations();
    });
  };

  const handelHide = (body, id) => {
    let formdata = new FormData();
    formdata.append('visible', body.visible);
    editLocationById(formdata, id).then((res) => {
      fetchLocations();
    });
  };

  // table cols
  const columns = [
    {
      field: 'name',
      headerName: t('common:tableHeaders.name'),
      sortComparator: (v1, v2) =>
        gridStringOrNumberComparator(v1.name, v2.name),
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography fontSize="14px" marginLeft={'10px'} color="inherit">
            {params.row.name}
          </Typography>
        </Box>
      ),
      flex: 1,
      minWidth: 330,
    },
    {
      field: 'phone',
      headerName: t('common:tableHeaders.phone'),
      flex: 1,
      minWidth: 180,
    },
    {
      field: 'address',
      headerName: t('common:tableHeaders.address'),
      flex: 1,
      minWidth: 320,
    },
    {
      field: 'actions',
      headerName: t('common:tableHeaders.actions'),
      type: 'actions',
      align: 'right',
      minWidth: 100,
      maxWidth: 100,
      getActions: (params) => {
        if (!params.row.visible) {
          return [
            <GridActionsCellItem
              label="hide"
              icon={
                params.row.visible ? (
                  <CustomTooltip
                    arrow
                    placement="top"
                    title="Disable item may not be available in other pages and network"
                  >
                    <VisibilityIcon />
                  </CustomTooltip>
                ) : (
                  <Box
                    sx={{
                      backgroundColor: '#fff',
                      padding: '3px 5px',
                      borderRadius: '5px',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                    }}
                  >
                    <VisibilityOffIcon />
                  </Box>
                )
              }
              onClick={() =>
                handelHide(
                  {
                    visible: !params.row.visible,
                  },
                  params.row.id
                )
              }
            />,
            <GridActionsCellItem
              label="Edit"
              onClick={() => {
                setDrawerType('edit');
                setDrawerData(params.row);
                toggleAddCouponDrawer();
              }}
              showInMenu
            />,
            <GridActionsCellItem
              label="Delete"
              onClick={() => {
                setOpenModal({
                  open: true,
                  type: 'delete-modal',
                  id: params.id,
                });
              }}
              showInMenu
            />,
            <GridActionsCellItem
              label="Share"
              onClick={() => {
                setOpenModal({ open: true, type: 'share-modal' });
              }}
              showInMenu
            />,
          ];
        }
        return [
          <GridActionsCellItem
            label="hide"
            icon={
              params.row.visible ? (
                <CustomTooltip
                  arrow
                  placement="top"
                  title="Disable item may not be available in other pages and network"
                >
                  <Box
                    sx={{
                      backgroundColor: '#fff',
                      padding: '3px 5px',
                      borderRadius: '5px',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                    }}
                  >
                    <VisibilityIcon />
                  </Box>
                </CustomTooltip>
              ) : (
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    padding: '3px 5px',
                    borderRadius: '5px',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                  }}
                >
                  <VisibilityOffIcon />
                </Box>
              )
            }
            onClick={() =>
              handelHide(
                {
                  visible: !params.row.visible,
                },
                params.row.id
              )
            }
          />,
          <GridActionsCellItem
            label="Edit"
            onClick={() => {
              setDrawerType('edit');
              setDrawerData(params.row);
              toggleAddCouponDrawer();
            }}
            showInMenu
          />,
          <GridActionsCellItem
            label="Delete"
            onClick={() => {
              setOpenModal({ open: true, type: 'delete-modal', id: params.id });
            }}
            showInMenu
          />,
          <GridActionsCellItem
            label="Share"
            onClick={() => {
              setOpenModal({ open: true, type: 'share-modal' });
            }}
            showInMenu
          />,
        ];
      },
    },
  ];

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        px: '20px',
      }}
    >
      {openModal.type === 'delete-modal' ? (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'DELETE'}
          open={openModal.open}
          handleDelete={deleteLocation}
          id={openModal.id}
        />
      ) : (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'SHARE'}
          open={openModal.open}
        />
      )}

      {addCouponDrawer && (
        <AddLoactions
          toggleDrawer={toggleAddCouponDrawer}
          drawerType={drawerType}
          drawerData={drawerType === 'add' ? null : drawerData}
          handelHide={handelHide}
          fetchLocations={fetchLocations}
        />
      )}
      {drawer === 'usage-history' && (
        <UsageHistory toggleDrawer={toggleUsageHistoryModal} />
      )}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        {/* -----------Hover over crown image and popover in Locations------------------------------ */}
        <Typography
          sx={{
            marginTop: 2.5,
            marginBottom: 1,
            fontSize: 24,
            color: '#3A3A50',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {t('location:title')}
            <div onClick={handleClick} style={{ cursor: 'pointer' }}>
              <CustomTooltip arrow title="Upsell for multiple locations">
                <Avatar
                  sx={{
                    ml: 1,
                    backgroundColor: '#3A3A50',
                    width: 20,
                    height: 20,
                    padding: '6px',
                  }}
                  alt="Avatar"
                  src={crown}
                  /*   onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose} */
                />
              </CustomTooltip>
            </div>
          </div>
          <Popover
            sx={{ p: 4 }}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <div
              style={{
                backgroundColor: '#32313f',
                padding: '16px',
                color: 'white',
              }}
            >
              <Typography
                sx={{
                  p: 2,
                  fontWeight: 'bold',
                  fontSize: 'larger',
                  color: 'white',
                }}
              >
                Upsell multiple locations
              </Typography>
              <Typography sx={{ p: 2 }}>
                Unlock the power of managing multiple locations seamlessly from
                a single application.
              </Typography>
              <Stack spacing={2} direction="row" marginTop={4} paddingLeft={2}>
                <Link to="https://upnize.com/help-center/location-management">
                  <CustomTextButton
                    sx={{ textTransform: 'capitalize' }}
                    variant="contained"
                    size="large"
                  >
                    Learn more
                  </CustomTextButton>
                </Link>
                <Link to="/subscription">
                  <CustomTextButton
                    sx={{ textTransform: 'capitalize' }}
                    type="blue"
                    variant="contained"
                    size="large"
                  >
                    Upgrade
                  </CustomTextButton>
                </Link>
              </Stack>
            </div>
          </Popover>

          {/*  <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}> Upsell multiple locations</Typography>
      </Popover> */}
        </Typography>
        {/* -----------Popover ends here------------------------------ */}
        <Box sx={{ width: { xs: '100%', sm: 'fit-content' } }}></Box>
      </Grid>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <CustomTextButton
          text={'+ ' + t('location:addLocationBtn')}
          onClick={() => {
            setDrawerType('add');
            toggleAddCouponDrawer();
          }}
        />
        <Box sx={{ display: 'flex', gap: '10px', ml: '10px' }}>
          {FilterButton}
        </Box>
      </Box>
      {/* -------------------------------SEARCH------------------------------- */}
      {FilterBar}

      {/* -------------------------------TABLE--------------------------------- */}
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ marginTop: '20px' }}
      >
        <div style={{ width: '100%' }}>
          <StripedDataGrid
            sx={{
              border: 0,
              '& .MuiDataGrid-virtualScroller': {
                minHeight: '300px !important',
              },
              minHeight: '60vh',
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px',
                fontWeight: '900 !important',
              },
              '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
                border: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
            rows={rows}
            columns={columns}
            // checkboxSelection
            autoHeight
            getRowClassName={(params) => {
              return !params.row.visible ? 'even' : 'odd';
            }}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            loading={tableLoading}
            selectionModel={selectionModel}
            rowCount={1000}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
            }}
            rowsPerPageOptions={defaultPageSizeOptions}
            page={pageCount - 1}
            onPageChange={(newPage) => {
              setPageCount(newPage + 1);
            }}
            paginationMode="server"
            components={{
              MoreActionsIcon: () => (
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    padding: '3px 5px',
                    borderRadius: '5px',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                  }}
                >
                  <MoreVertIcon sx={{}} />
                </Box>
              ),
              NoRowsOverlay: NoData,
              NoResultsOverlay: NoData,
              Footer: () => (
                <CustomTableFooter
                  selectionModel={selectionModel}
                  handleDeleteOpenModal={handleDeleteOpenModal}
                />
              ),
              MoreActionsIcon: () => (
                <GridMoreVertIcon
                  sx={{
                    backgroundColor: 'white',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                    borderRadius: '4px',
                    padding: '3px',
                    cursor: 'pointer',
                  }}
                />
              ),
              NoRowsOverlay: () => {
                return (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'Center',
                      gap: '20px',
                    }}
                  >
                    <img
                      src="https://user-images.githubusercontent.com/109676648/254145031-57027cd6-fecd-4ce2-96fc-9d891c8de53a.svg"
                      alt="no data"
                      style={{
                        width: '100px',
                        height: '100px',
                      }}
                    />
                    No data at the moment 😔
                  </Box>
                );
              },
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Locations;
