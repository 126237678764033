import React from 'react';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
// drawer icons
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'; // add
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'; // info
import EditRoundedIcon from '@mui/icons-material/EditRounded'; // edit
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded'; // payment
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'; // share
import SetMealIcon from '@mui/icons-material/SetMeal';

const CustomDrawer = ({
  toggleDrawer,
  title,
  type,
  children,
  state,
  noHeaderBorder,
}) => {
  const getIcon = () => {
    switch (type) {
      case 'LUNCH':
        return (
          <Box
            sx={{
              backgroundColor: '#1976D2',
              padding: 1.3,
              borderRadius: 3,
              display: 'flex',
            }}
          >
            <SetMealIcon sx={{ color: 'white' }} />
          </Box>
        );
      case 'ADD':
        return (
          <Box
            sx={{
              backgroundColor: '#1976D2',
              padding: 1.3,
              borderRadius: 3,
              display: 'flex',
            }}
          >
            <AddCircleOutlineRoundedIcon sx={{ color: 'white' }} />
          </Box>
        );

      case 'INFO':
        return (
          <Box
            sx={{
              backgroundColor: '#1976D2',
              padding: 1.3,
              borderRadius: 3,
              display: 'flex',
            }}
          >
            <InfoOutlinedIcon sx={{ color: 'white' }} />
          </Box>
        );
      case 'EDIT':
        return (
          <Box
            sx={{
              backgroundColor: '#1976D2',
              padding: 1.3,
              borderRadius: 3,
              display: 'flex',
            }}
          >
            <EditRoundedIcon sx={{ color: 'white' }} />
          </Box>
        );
      case 'PAYMENT':
        return (
          <Box
            sx={{
              backgroundColor: '#1976D2',
              padding: 1.3,
              borderRadius: 3,
              display: 'flex',
            }}
          >
            <PaymentRoundedIcon sx={{ color: 'white' }} />
          </Box>
        );

      case 'SHARE':
        return (
          <Box
            sx={{
              backgroundColor: '#1976D2',
              padding: 1.3,
              borderRadius: 3,
              display: 'flex',
            }}
          >
            <ShareRoundedIcon sx={{ color: 'white' }} />
          </Box>
        );
      default:
        break;
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Drawer
          variant="temporary"
          anchor="right"
          open={state ? state.open : true}
          onClose={() => {}}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: { xs: '100vw', md: '660px' },
              backgroundColor: 'white',
            },
          }}
        >
          {/* -----------HEADER---------------- */}
          <Grid
            item
            container
            sx={{
              borderBottom: noHeaderBorder ? 'none' : '1px solid #e3eaf3',
              paddingX: 3,
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContents: 'space-between',
                alignItems: 'center',
                height: '81px',
              }}
            >
              <Grid
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContents: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box>{getIcon()}</Box>
                <Typography
                  sx={{
                    fontSize: 22,
                    fontWeight: 500,
                    marginLeft: 2,
                    color: '#292D32',
                  }}
                >
                  {title}
                </Typography>
              </Grid>
              <IconButton>
                <CloseIcon
                  sx={{
                    color: '#ADBFC7',
                    cursor: 'pointer',
                    backgroundColor: 'white',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                    borderRadius: '4px',
                    padding: '3px',
                  }}
                  onClick={toggleDrawer}
                />
              </IconButton>
            </Grid>
          </Grid>
          {/* -----------BODY------------ */}

          {children}
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default CustomDrawer;
