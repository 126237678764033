import { useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import SaveSlideBar from '../../SaveSlideBar';
import { selectUser } from '../../../redux/slices/user.slice.js';
import { useDispatch, useSelector } from 'react-redux';
import { editUserById } from '../../../Api/common/users';
import { callSnackbar } from '../../../redux/slices/snackbar.slice';

const ChangePasswordSettings = ({ fetchCurrentSettings, currentSettings }) => {
  const [isSaveBar, setIsSaveBar] = useState(false);
  const [formData, setFormData] = useState({
    newPassword: '',
    reTypePassword: '',
  });
  const { user } = useSelector(selectUser);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);

  const handleSelectedValue = (key, selectedValue) => {
    setFormData({ ...formData, [key]: selectedValue });
    setIsSaveBar(true);
  };

  // click handlers
  const handleSave = () => {
    if (formData.newPassword !== formData.reTypePassword) {
      setError(true);
      return;
    }
    editUserById({ password: formData.newPassword }, user.id).then((res) => {
      if (res.status === 200) {
        setIsSaveBar(false);
        dispatch(
          callSnackbar({
            message: 'Password changed successfully',
            type: 'success',
          })
        );
      }
    });
  };

  const handleCancel = () => {
    setFormData({ newPassword: '', reTypePassword: '' });
    setIsSaveBar(false);
  };

  return (
    <>
      <Grid
        xs={12}
        sx={{
          marginTop: { xs: 9, sm: 14 },
          backgroundColor: 'white',
          pb: 1,
          mb: 5,
        }}
      >
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>
            Account Settings
            <span style={{ color: '#c1c1c1' }}>
              <CircleIcon sx={{ fontSize: '7px', marginX: '3px' }} /> Password
            </span>
          </Typography>
        </Grid>
        <Grid container sx={{ padding: { xs: '15px', md: '15px 25px' } }}>
          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                New Password
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                value={formData.newPassword}
                onChange={(e) =>
                  handleSelectedValue('newPassword', e.target.value)
                }
                error={error}
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                Re-type Password
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                value={formData.reTypePassword}
                onChange={(e) =>
                  handleSelectedValue('reTypePassword', e.target.value)
                }
                error={error}
                helperText={error ? "Passwords doesn't match" : ' '}
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default ChangePasswordSettings;
