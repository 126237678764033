import { useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  MenuList,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CustomSettingsTitle from '../../CustomSettingsTitle/CustomSettingsTitle';
import { IOSSwitch } from '../../CustomCheckbox';

const keywordsInitialValue = [
  { label: 'Appointment ID', value: '{appointment_id}' },
  { label: 'Appointment date', value: '{appointment_date}' },
  { label: 'Appointment date-time', value: '{appointment_date_time}' },
  {
    label: 'Appointment date (customer timezone)',
    value: '{appointment_date_client}',
  },
];
const syncMethodForBusySlotsFromGoogleCalendar = [
  'Live sync',
  'Background sync',
  "Don't sync busy slots",
];
const sinceWhatDateDoEventsInGoogleCalendarSync = [
  'Events up to 1 month',
  'Events up to 2 month',
  'Events up to 3 month',
];
const appointmentsOptions = [
  'Pending',
  'Approved',
  'Canceled',
  'Rejected',
  'Completed',
  'No show',
  'Waiting for payment',
];

const RadioButton = ({ checked }) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" fontSize="24px">
    {!checked && (
      <circle
        cx="50%"
        cy="50%"
        r="11px"
        stroke="#cfd8dc"
        stroke-width="1px"
        fill="none"
      />
    )}

    {checked && (
      <circle
        cx="50%"
        cy="50%"
        r="8px"
        stroke="#53D56C"
        stroke-width="6px"
        fill="none"
      />
    )}
  </svg>
);

const GoogleCalendar = () => {
  const [isEnabled, setIsEnabled] = useState('disabled');
  const mediaQuery1238 = useMediaQuery('(min-width:1238px)');
  const [eventTitleValue, setEventTitleValue] = useState('');
  const [eventDescriptionValue, setEventDescriptionValue] = useState('');
  const [syncMethodGoogleCalendar, setSyncMethodGoogleCalendar] = useState('');
  const [addCustomersAsAttendees, setAddCustomersAsAttendees] = useState(false);
  const [sendEmailToAttendeesByGoogle, setSendEmailToAttendeesByGoogle] =
    useState(false);
  const [customersSeeOtherAttendees, setCustomersSeeOtherAttendees] =
    useState(false);
  const [
    appointmentsAppearOnGoogleCalendar,
    setAppointmentsAppearOnGoogleCalendar,
  ] = useState([]);

  const [anchorEl, setAnchorEl] = useState({
    element: null,
    textFieldName: null,
  });
  const [filterMenuList, setFilterMenuList] = useState(keywordsInitialValue);
  const [menuFilterInput, setMenuFilterInput] = useState('');

  const openMenu = Boolean(anchorEl.element);

  const handleMenuClick = (event, textFieldName) => {
    setFilterMenuList(keywordsInitialValue);

    setAnchorEl({ element: event.currentTarget, textFieldName });
  };

  const handleMenuFilterInput = (e) => {
    const value = e.target.value;

    if (value.length === 0) {
      setFilterMenuList(keywordsInitialValue);
    } else {
      setFilterMenuList((prevState) =>
        prevState.filter((prevValue) => prevValue.label.startsWith(value))
      );
    }

    setMenuFilterInput(e.target.value);
  };

  const handleMenuClose = () => {
    setAnchorEl({ element: null, textFieldName: null });
    setMenuFilterInput('');
  };

  const handleSelectedTag = (tag) => {
    if (anchorEl.textFieldName === 'event-title') {
      setEventTitleValue((prevState) => prevState + tag);
    } else if (anchorEl.textFieldName === 'event-description') {
      setEventDescriptionValue((prevState) => prevState + tag);
    }
    setAnchorEl({ element: null, textFieldName: null });
  };

  const handleSelectionChipChange = (e) => {
    const {
      target: { value },
    } = e;
    setAppointmentsAppearOnGoogleCalendar(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl.element}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openMenu}
        onClose={handleMenuClose}
        elevation={0}
        MenuListProps={{
          sx: {
            width: anchorEl.element && anchorEl.element.offsetWidth + 200,
            marginTop: 0.5,
            paddingTop: 1,
            border: '1px solid #E4EBF4',
            borderRadius: '4px',
          },
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <input
            value={menuFilterInput}
            onChange={handleMenuFilterInput}
            type="text"
            style={{
              border: '1px solid #e3eaf3',
              outline: 'none',
              fontSize: '14px',
              borderRadius: '2px',
              height: '1.5rem',
              width: '95%',
            }}
          />
        </div>
        <div>
          <MenuList dense>
            {filterMenuList.map((keyword, index) => (
              <Grid
                container
                justifyContent="flex-end"
                flexWrap="nowrap"
                sx={{
                  width: '100%',
                  padding: '5px 0',
                  cursor: 'pointer',
                  ':hover': {
                    backgroundColor: '#adbfc7',
                    color: 'white',
                  },
                }}
                key={`draft-filter-${index}`}
                onClick={() => handleSelectedTag(keyword.value)}
              >
                <Grid
                  sx={{ width: '100%', fontSize: '13px', color: '#007bff' }}
                >
                  {keyword.label}
                </Grid>{' '}
                <Grid
                  sx={{
                    width: '180px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    fontSize: '12px',
                    color: '#9ba6af',
                  }}
                >
                  {keyword.value}
                </Grid>
              </Grid>
            ))}
          </MenuList>
        </div>
      </Menu>
      <Grid container xs={12} style={{ justifyContent: 'flex-end' }}>
        <Button
          sx={{
            width: { xs: '100%', sm: 'auto' },
            marginTop: { xs: 9, sm: 5 },
            marginBottom: 1,
            marginLeft: { xs: 0, sm: 1 },
            backgroundColor: '#4abf60',
            height: '45px',
            borderRadius: '2px',
            fontSize: '12px',
            fontWeight: 600,
          }}
          variant="contained"
          color="success"
          onClick={() => {}}
        >
          <CheckIcon sx={{ marginRight: 1, fontSize: '20px' }} />
          Save Changes
        </Button>
      </Grid>

      <Grid xs={12} style={{ backgroundColor: 'white', marginTop: '20px' }}>
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <CustomSettingsTitle
            title="Integrations Settings"
            subTitle="Google calendar"
          />
        </Grid>
        <Grid container>
          <FormControl
            sx={{
              width: '100%',
              boxShadow: '0 15px 15px -5px rgb(0 0 0 / 5%)',
              padding: { xs: '15px', md: '15px 25px' },
            }}
          >
            <RadioGroup
              row
              value={isEnabled}
              onChange={(e) => setIsEnabled(e.target.value)}
            >
              <FormControlLabel
                value="disabled"
                control={
                  <Radio
                    icon={<RadioButton />}
                    checkedIcon={<RadioButton checked />}
                  />
                }
                label="Disabled"
              />
              <FormControlLabel
                value="enabled"
                sx={{ marginLeft: '20px' }}
                control={
                  <Radio
                    icon={<RadioButton />}
                    checkedIcon={<RadioButton checked />}
                  />
                }
                label="Enabled"
              />
            </RadioGroup>
          </FormControl>

          <Grid container sx={{ marginTop: '40px', paddingX: '25px' }}>
            {isEnabled === 'enabled' && (
              <>
                <Grid xs={12}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Redirect URL</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      value="https://upnize.com/admin?gmail_smtp=true"
                      onChange={(e) => {
                        /*handleSelectedValue("newPassword", e.target.value)*/
                      }}
                      id="outlined-basic"
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '2rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid xs={12} sm={6} sx={{ paddingRight: 1 }}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Client ID</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value=""
                      onChange={(e) => {
                        /*handleSelectedValue("newPassword", e.target.value)*/
                      }}
                      id="outlined-basic"
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '2rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid xs={12} sm={6}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Client secret</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value=""
                      onChange={(e) => {
                        /*handleSelectedValue("newPassword", e.target.value)*/
                      }}
                      id="outlined-basic"
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '2rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid xs={12}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Event Title</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={eventTitleValue}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            onClick={(e) => handleMenuClick(e, 'event-title')}
                            position="end"
                            sx={{ cursor: 'pointer', color: '#6C70DC' }}
                          >
                            <LocalOfferIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setEventTitleValue(e.target.value)}
                      id="outlined-basic"
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '2rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container xs={12} sx={{ marginY: 1, marginBottom: 2 }}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Event Description
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ position: 'relative', width: '100%' }}
                  >
                    <TextareaAutosize
                      id="outlined-basic"
                      size="small"
                      required
                      variant="outlined"
                      value={eventDescriptionValue}
                      onChange={(e) => setEventDescriptionValue(e.target.value)}
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                      style={{
                        width: '100%',
                        font: 'inherit !important',
                        minHeight: '120px',
                        fontSize: '14px',
                        outlineColor: '#3f50b5',
                        borderColor: '#bdbdbd',
                      }}
                    />
                    <LocalOfferIcon
                      onClick={(e) => handleMenuClick(e, 'event-description')}
                      sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '12px',
                        cursor: 'pointer',
                        color: '#6C70DC',
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container xs={12}>
                  <Grid
                    xs={12}
                    sm={mediaQuery1238 ? 6 : 12}
                    sx={{ paddingRight: { sm: 1 } }}
                  >
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">
                        Sync method for busy slots from Google Calendar
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={syncMethodForBusySlotsFromGoogleCalendar}
                        value={syncMethodGoogleCalendar}
                        sx={{ marginBottom: '2rem', fontSize: '14px' }}
                        onChange={(e, data) =>
                          setSyncMethodGoogleCalendar(data)
                        }
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </Grid>
                  </Grid>

                  {syncMethodGoogleCalendar === 'Background sync' && (
                    <Grid xs={12} sm={mediaQuery1238 ? 6 : 12}>
                      <Grid item xs={12} sx={{ marginY: 1 }}>
                        <Typography variant="subtitle2">
                          Since what date do events in Google calendar sync
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={sinceWhatDateDoEventsInGoogleCalendarSync}
                          value={''}
                          sx={{ marginBottom: '2rem', fontSize: '14px' }}
                          onChange={(e, data) => {}}
                          renderInput={(params) => (
                            <TextField {...params} size="small" />
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <Grid container xs={12}>
                  <Grid
                    container
                    item
                    xs={mediaQuery1238 ? 6 : 12}
                    sx={{ paddingRight: 1 }}
                  >
                    <Grid
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setAddCustomersAsAttendees(!addCustomersAsAttendees);
                      }}
                      xs={12}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        borderRadius: '4px',
                        paddingX: 2,
                        paddingY: 1,
                        height: '40px',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ lineHeight: '1.2 !important' }}
                      >
                        Add customers as attendees in your calendar events
                      </Typography>
                      <IOSSwitch
                        size="small"
                        defaultChecked
                        sx={{ marginLeft: 2 }}
                        onChange={(e) => {
                          setAddCustomersAsAttendees(e.target.checked);
                        }}
                        checked={addCustomersAsAttendees}
                      />
                    </Grid>
                  </Grid>

                  {addCustomersAsAttendees && (
                    <Grid
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSendEmailToAttendeesByGoogle(
                          !sendEmailToAttendeesByGoogle
                        );
                      }}
                      item
                      xs={mediaQuery1238 ? 6 : 12}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        borderRadius: '4px',
                        paddingX: 2,
                        paddingY: 1,
                        height: '40px',
                      }}
                    >
                      <Typography variant="subtitle2">
                        Send email invitations to attendees by Google
                      </Typography>
                      <IOSSwitch
                        size="small"
                        defaultChecked
                        sx={{ marginLeft: 2 }}
                        onChange={(e) => {
                          setSendEmailToAttendeesByGoogle(e.target.checked);
                        }}
                        checked={sendEmailToAttendeesByGoogle}
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid
                  container
                  item
                  xs={mediaQuery1238 ? 6 : 12}
                  sx={{ paddingRight: 1, marginTop: 3 }}
                >
                  <Grid
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setCustomersSeeOtherAttendees(
                        !customersSeeOtherAttendees
                      );
                    }}
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      border: '1px solid rgba(0, 0, 0, 0.23)',
                      borderRadius: '4px',
                      paddingX: 2,
                      paddingY: 1,
                      height: '40px',
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{ lineHeight: '1.2 !important' }}
                    >
                      import CustomSettingsTitle from
                      './../../CustomSettingsTitle/CustomSettingsTitle';
                      Customers can see other attendees
                    </Typography>
                    <IOSSwitch
                      size="small"
                      defaultChecked
                      sx={{ marginLeft: 2 }}
                      onChange={(e) => {
                        setCustomersSeeOtherAttendees(e.target.checked);
                      }}
                      checked={customersSeeOtherAttendees}
                    />
                  </Grid>
                </Grid>

                <Grid container xs={12}>
                  <Grid
                    container
                    xs={12}
                    sm={6}
                    sx={{ marginY: 3, paddingRight: 1 }}
                  >
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">
                        Which appointments should be appear on Google Calendar
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        size="small"
                        style={{ width: '100%' }}
                        multiple
                        value={appointmentsAppearOnGoogleCalendar}
                        onChange={handleSelectionChipChange}
                        input={<OutlinedInput />}
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                          >
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                      >
                        {appointmentsOptions.map((name) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GoogleCalendar;
