import { UpnizeAdminBackend } from '../Axios';

export const getModuleById = async (id) => {
  let response = await UpnizeAdminBackend.get(`/modules/byId/${id}`);
  return response;
};

export const getModulesList = async (rows, page, searchQuery) => {
  let response = await UpnizeAdminBackend.get(
    `modules/list?rows=${rows}&page=${page}`
  );
  return response;
};
