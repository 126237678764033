import { Suspense, useContext, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import System from './components/System';
import CustomerSystem from './components/CustomerSystem';
import { Context } from './context/Context';
import Coupon from './pages/Coupon';
import Dashboard from './pages/Dashboard';
import Giftcard from './pages/Giftcard';
import Locations from './pages/Locations';
import NotFound from './pages/NotFound';
import Payment from './pages/Payment';
import Roles from './pages/Role';
import Services from './pages/Services';
import UpsellTask from './pages/UpsellTask';
import TaskManagement from './pages/Taskmanagement';
import UpsellAppointment from './pages/UpsellAppointment';
import UpsellJournal from './pages/UpsellJournal';
import UpsellService from './pages/UpsellService';
import UpsellNotification from './pages/UpsellNotification';
import Staff from './pages/Staff';
import Tax from './pages/Tax';
import Welcome from './pages/Welcome';
import Workflow from './pages/Workflow';
import CustomerDashboard from './pages/CustomerPanel/CustomerDashboard';
import CustomerAppointments from './pages/CustomerPanel/CustomerAppointments';
import CustomerSettings from './pages/CustomerPanel/CustomerSettings';
import CustomerInvoices from './pages/CustomerPanel/CustomerInvoices';
import CustomerProviders from './pages/CustomerPanel/CustomerProviders';
import Review from './pages/Review';
import Login from './pages/Login/Login';
import Notification from './pages/Notification';
import Billing from './pages/Billing';
import Report from './pages/Report';
import Invoice from './pages/Invoice';
import Settings from './pages/Settings';
import Appointment from './pages/Appointment';
import Calendar from './pages/Calendar';
import ResetPassword from './pages/PasswordForms/ResetPassword';
import ForgotPassword from './pages/PasswordForms/ForgotPassword';
import Register from './pages/Register/Register';
import RestaurantMenu from './pages/RestaurantMenu/RestaurantMenu';
import Products from './pages/Products/Products';
import ModulesSubscription from './pages/ModulesSubscription';
import AuthProvider from './providers/AuthProvider';
import Customer from './pages/Customer';
import CustomerDetails from './pages/Customer/CustomerDetails';
import { disableSectionFunc } from './utils/disableSectionFunc';
import ModuleWrapper from './components/ModuleWrapper';
import { Box, CircularProgress } from '@mui/material';
import FilterProvider from './context/DashboardFilterProvider';

const SuspenseFallback = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '90vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
};




export const Routes = () => {
  const context = useContext(Context);
  const element = useRoutes(routes.filter((route) => route));
  const customerElement = useRoutes(customerRoutes.filter((route) => route));
  // shard between both cp and tp
  const unauthRoutes = useRoutes([
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/register',
      element: <Register />,
    },
    {
      path: '/reset-password/:token',
      element: <ResetPassword />,
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: '*',
      element: <AuthProvider></AuthProvider>,
    },
  ]);

  if (context.user?.type === 'tenant') {
    return element;
  } else if (context.user?.type === 'customer') {
    return customerElement;
  } else {
    return unauthRoutes;
  }
};

const routes = [
  {
    path: '/',
    element: (
      <AuthProvider>
        <System>
          <FilterProvider>
            <Suspense fallback={<SuspenseFallback />}>  
              <Dashboard />
            </Suspense>
          </FilterProvider>
        </System>
      </AuthProvider>
    ),
  },
  disableSectionFunc({
    path: '/appointment',
    element: (
      <AuthProvider>
        <System>
          <ModuleWrapper code="APP">
            <Appointment />
            <UpsellAppointment />
          </ModuleWrapper>
        </System>
      </AuthProvider>
    ),
  }),
  disableSectionFunc({
    path: '/calendar',
    element: (
      <System>
        <Calendar />
      </System>
    ),
  }),
  disableSectionFunc({
    path: '/role',

    element: (
      <System>
        <Roles />
      </System>
    ),
  }),
  disableSectionFunc({
    path: '/payment',
    element: (
      <System>
        <Payment />
      </System>
    ),
  }),
  {
    path: '/customer',
    element: (
      <AuthProvider>
        <System>
          <Suspense fallback={<SuspenseFallback />}>
            <Customer />
          </Suspense>
        </System>
      </AuthProvider>
    ),
  },

  {
    path: '/customer/:customerId',
    element: (
      <AuthProvider>
        <System>
          <CustomerDetails />
        </System>
      </AuthProvider>
    ),
  },

  disableSectionFunc({
    path: '/service',
    element: (
      <AuthProvider>
        <System>
          <ModuleWrapper code="SER">
            <Suspense fallback={<SuspenseFallback />}>
              <Services />
            </Suspense>
            <UpsellService />
          </ModuleWrapper>
        </System>
      </AuthProvider>
    ),
  }),

  disableSectionFunc({
    path: '/task',
    element: (
      <AuthProvider>
        <System>
          <ModuleWrapper code="KBTM">
            <TaskManagement />
            <UpsellTask />
          </ModuleWrapper>
        </System>
      </AuthProvider>
    ),
  }),

  disableSectionFunc({
    path: '/products',
    element: (
      <AuthProvider>
        <System>
          <Products />
        </System>
      </AuthProvider>
    ),
  }),
  disableSectionFunc({
    path: '/restaurant',
    element: (
      <AuthProvider>
        <System>
          <RestaurantMenu />
        </System>
      </AuthProvider>
    ),
  }),
  {
    path: '/staff',

    element: (
      <AuthProvider>
        <System>
          <Suspense fallback={<SuspenseFallback />}>
            <Staff />
          </Suspense>
        </System>
      </AuthProvider>
    ),
  },
  {
    path: '/location',
    element: (
      <AuthProvider>
        <System>
          <Suspense fallback={<SuspenseFallback />}>
            <Locations />
          </Suspense>
        </System>
      </AuthProvider>
    ),
  },
  {
    path: '/setting',
    element: (
      <AuthProvider>
        <System>
          <Suspense fallback={<SuspenseFallback />}>
            <Settings />
          </Suspense>
        </System>
      </AuthProvider>
    ),
  },
  disableSectionFunc({
    path: '/coupon',
    element: (
      <AuthProvider>
        <System>
          <Coupon />
        </System>
      </AuthProvider>
    ),
  }),
  disableSectionFunc({
    path: '/giftcard',
    element: (
      <AuthProvider>
        <System>
          <Giftcard />
        </System>
      </AuthProvider>
    ),
  }),
  {
    path: '/tax',
    element: (
      <AuthProvider>
        <System>
          <Suspense fallback={<SuspenseFallback />}>
            <Tax />
          </Suspense>
        </System>
      </AuthProvider>
    ),
  },
  {
    path: '/welcome',
    element: <Welcome />,
  },
  disableSectionFunc({
    path: '/workflow',

    element: (
      <System>
        <Workflow />
      </System>
    ),
  }),
  disableSectionFunc({
    path: '/invoice',

    element: (
      <System>
        <Invoice />
      </System>
    ),
  }),
  disableSectionFunc({
    path: '/review',

    element: (
      <AuthProvider>
        <System>
          <Review />
        </System>
      </AuthProvider>
    ),
  }),

  {
    path: '/billing',
    element: (
      <System>
        <Billing />
      </System>
    ),
  },
  disableSectionFunc({
    path: '/notification',
    element: (
      <System>
        <ModuleWrapper code="NO CODE">
          <Notification />
          <UpsellNotification />
        </ModuleWrapper>
      </System>
    ),
  }),
  disableSectionFunc({
    path: '/report',
    element: (
      <System>
        <Report />
      </System>
    ),
  }),
  {
    path: '/billing',
    element: (
      <System>
        <Billing />
      </System>
    ),
  },

  {
    path: '/subscription',
    element: (
      <AuthProvider>
        <ModulesSubscription />
      </AuthProvider>
    ),
  },
  {
    path: '/subscription/success',
    element: (
      <AuthProvider>
        <ModulesSubscription />
      </AuthProvider>
    ),
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/reset-password/:token',
    element: <ResetPassword />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '*',
    element: (
      <AuthProvider>
        <System>
          <NotFound />
        </System>
      </AuthProvider>
    ),
  },
];

const customerRoutes = [
  {
    path: '/',
    element: (
      <AuthProvider>
        <CustomerSystem>
          <Suspense fallback={<SuspenseFallback />}>
            <CustomerDashboard />
          </Suspense>
        </CustomerSystem>
      </AuthProvider>
    ),
  },
  {
    path: '/appointments',
    element: (
      <AuthProvider>
        <CustomerSystem>
          <Suspense fallback={<SuspenseFallback />}>
            <CustomerAppointments />
          </Suspense>
        </CustomerSystem>
      </AuthProvider>
    ),
  },
  {
    path: '/setting',
    element: (
      <AuthProvider>
        <CustomerSystem>
          <Suspense fallback={<SuspenseFallback />}>
            <CustomerSettings />
          </Suspense>
        </CustomerSystem>
      </AuthProvider>
    ),
  },
  disableSectionFunc({
    path: '/invoice',
    element: (
      <AuthProvider>
        <CustomerSystem>
          <Suspense fallback={<SuspenseFallback />}>
            <CustomerInvoices />
          </Suspense>
        </CustomerSystem>
      </AuthProvider>
    ),
  }),
  disableSectionFunc({
    path: '/providers',
    element: (
      <AuthProvider>
        <CustomerSystem>
          <Suspense fallback={<SuspenseFallback />}>
            <CustomerProviders />
          </Suspense>
        </CustomerSystem>
      </AuthProvider>
    ),
  }),
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '*',
    element: (
      <AuthProvider>
        <CustomerSystem>
          <NotFound />
        </CustomerSystem>
      </AuthProvider>
    ),
  },
];
