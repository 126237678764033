import { useState, useLayoutEffect } from 'react';
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CustomSettingsTitle from '../../CustomSettingsTitle/CustomSettingsTitle';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../../redux/slices/user.slice.js';
import SaveSlideBar from '../../SaveSlideBar';
import { editSettings } from '../../../Api/tenant/settings';
import { IOSSwitch } from '../../CustomCheckbox';

const customersCanChangeAppointmentStatusToOptions = [
  'Pending',
  'Approved',
  'Canceled',
  'Rejected',
  'Completed',
  'No show',
  'Waiting for payment',
];

const customersCanRescheduleWithAppointmentStatusesOptions = [
  'Pending',
  'Approved',
  'Canceled',
  'Rejected',
  'Completed',
  'No show',
  'Waiting for payment',
];

const timeRestrictionToChangeAppointmentsOptions = [
  '1m',
  '2m',
  '3m',
  '4m',
  '5m',
  '10m',
  '15m',
];

const RadioButton = ({ checked }) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" fontSize="24px">
    {!checked && (
      <circle
        cx="50%"
        cy="50%"
        r="11px"
        stroke="#cfd8dc"
        stroke-width="1px"
        fill="none"
      />
    )}

    {checked && (
      <circle
        cx="50%"
        cy="50%"
        r="8px"
        stroke="#53D56C"
        stroke-width="6px"
        fill="none"
      />
    )}
  </svg>
);

const CustomerPanel = ({ fetchCurrentSettings, currentSettings }) => {
  const mediaQuery1238 = useMediaQuery('(min-width:1238px)');
  const [isSaveBar, setIsSaveBar] = useState(false);
  const [isEnabled, setIsEnabled] = useState('disabled');
  const [formData, setFormData] = useState({
    changeStatus: true,
    enabled: true,
    hidePayNow: true,
    reschedule: false,
    timeRestriction: 4,
    changeStatusTo: '',
    rescheduleOn: '',
  });
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    setFormData(currentSettings.FEPanels.CPanel);
    setIsEnabled(
      currentSettings.FEPanels.CPanel.enabled ? 'enabled' : 'disabled'
    );
  }, []);

  // click handlers
  const handleSave = () => {
    editSettings({
      ...currentSettings,
      FEPanels: {
        ...currentSettings.FEPanels,
        CPanel: {
          ...formData,
          enabled: isEnabled == 'disabled' ? false : true,
        },
      },
    }).then((res) => {
      if (res.status === 200) {
        fetchCurrentSettings();
        dispatch(fetchUser())
          .unwrap()
          .then((res) => {
            setIsSaveBar(false);
          });
      }
    });
  };

  const handleCancel = () => {
    setFormData(currentSettings.FEPanels.CPanel);
    setIsEnabled(
      currentSettings.FEPanels.CPanel.enabled ? 'enabled' : 'disabled'
    );
    setIsSaveBar(false);
  };

  return (
    <>
      <Grid xs={12} style={{ backgroundColor: 'white', marginTop: '20px' }}>
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <CustomSettingsTitle
            title="Front-end Panels"
            subTitle="Customer Panel"
          />
        </Grid>

        <Grid container>
          <FormControl
            sx={{
              width: '100%',
              boxShadow: '0 15px 15px -5px rgb(0 0 0 / 5%)',
              padding: { xs: '15px', md: '15px 25px' },
            }}
          >
            <RadioGroup
              row
              value={isEnabled}
              onChange={(e) => setIsEnabled(e.target.value)}
            >
              <FormControlLabel
                value="disabled"
                control={
                  <Radio
                    icon={<RadioButton />}
                    checkedIcon={<RadioButton checked />}
                  />
                }
                label="Disabled"
              />
              <FormControlLabel
                value="enabled"
                sx={{ marginLeft: '20px' }}
                control={
                  <Radio
                    icon={<RadioButton />}
                    checkedIcon={<RadioButton checked />}
                  />
                }
                label="Enabled"
              />
            </RadioGroup>
          </FormControl>

          <Grid container sx={{ marginTop: '40px', paddingX: '25px' }}>
            {isEnabled === 'enabled' && (
              <>
                <Grid container xs={12}>
                  <Grid
                    xs={12}
                    sm={mediaQuery1238 ? 6 : 12}
                    sx={{ paddingRight: 1 }}
                  >
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">
                        Time restriction to change appointment
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={timeRestrictionToChangeAppointmentsOptions}
                        value={formData.timeRestriction}
                        sx={{ marginBottom: '2rem', fontSize: '14px' }}
                        onChange={(e, data) => {
                          setFormData((prev) => {
                            return { ...prev, timeRestriction: data };
                          });
                          setIsSaveBar(true);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container xs={12}>
                  <Grid
                    container
                    item
                    xs={mediaQuery1238 ? 6 : 12}
                    sx={{ paddingRight: 1, marginTop: 1 }}
                  >
                    <Grid xs={12}></Grid>
                    <Grid
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        {
                          setFormData((prev) => {
                            return {
                              ...prev,
                              changeStatus: !prev.changeStatus,
                            };
                          });
                          setIsSaveBar(true);
                        }
                      }}
                      xs={12}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        borderRadius: '4px',
                        paddingX: 2,
                        paddingY: 1,
                        height: '40px',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ lineHeight: '1.2 !important' }}
                      >
                        Allow customers to change appointment status
                      </Typography>
                      <IOSSwitch
                        size="small"
                        defaultChecked
                        sx={{ marginLeft: 2 }}
                        onChange={(e) => {
                          setFormData((prev) => {
                            return {
                              ...prev,
                              changeStatus: e.target.checked,
                            };
                          });
                          setIsSaveBar(true);
                        }}
                        checked={formData.changeStatus}
                      />
                    </Grid>
                  </Grid>

                  {formData.changeStatus ? (
                    <Grid xs={12} sm={mediaQuery1238 ? 6 : 12}>
                      <Grid item xs={12} sx={{ marginY: 1 }}>
                        <Typography variant="subtitle2">
                          Customers can change appointment status to
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          disablePortal
                          options={customersCanChangeAppointmentStatusToOptions}
                          value={formData.changeStatusTo}
                          sx={{ marginBottom: '2rem', fontSize: '14px' }}
                          onChange={(e, data) => {
                            setFormData((prev) => {
                              return {
                                ...prev,
                                changeStatusTo: data,
                              };
                            });
                            setIsSaveBar(true);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} size="small" />
                          )}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      xs={12}
                      sm={mediaQuery1238 ? 6 : 12}
                      sx={{ height: '110px' }}
                    ></Grid>
                  )}
                </Grid>

                <Grid container xs={12}>
                  <Grid
                    container
                    item
                    xs={mediaQuery1238 ? 6 : 12}
                    sx={{ paddingRight: 1, marginTop: 1 }}
                  >
                    <Grid xs={12}></Grid>
                    <Grid
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setFormData((prev) => {
                          return {
                            ...prev,
                            reschedule: !prev.reschedule,
                          };
                        });

                        setIsSaveBar(true);
                      }}
                      xs={12}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        borderRadius: '4px',
                        paddingX: 2,
                        paddingY: 1,
                        height: '40px',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ lineHeight: '1.2 !important' }}
                      >
                        Allow customers to reschedule their appointments
                      </Typography>
                      <IOSSwitch
                        size="small"
                        defaultChecked
                        sx={{ marginLeft: 2 }}
                        onChange={(e) => {
                          setFormData((prev) => {
                            return {
                              ...prev,
                              reschedule: e.target.checked,
                            };
                          });
                          setIsSaveBar(true);
                        }}
                        checked={formData.reschedule}
                      />
                    </Grid>
                  </Grid>

                  {formData.reschedule ? (
                    <Grid xs={12} sm={mediaQuery1238 ? 6 : 12}>
                      <Grid item xs={12} sx={{ marginY: 1 }}>
                        <Typography variant="subtitle2">
                          Customers can reschedule with these appointment
                          statuses
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          disablePortal
                          options={
                            customersCanRescheduleWithAppointmentStatusesOptions
                          }
                          value={formData.rescheduleOn}
                          sx={{ marginBottom: '2rem', fontSize: '14px' }}
                          onChange={(e, data) => {
                            setFormData((prev) => {
                              return {
                                ...prev,
                                rescheduleOn: e.target.data,
                              };
                            });
                            setIsSaveBar(true);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} size="small" />
                          )}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      xs={12}
                      sm={mediaQuery1238 ? 6 : 12}
                      sx={{ height: '110px' }}
                    ></Grid>
                  )}
                </Grid>

                <Grid container xs={12}>
                  <Grid
                    container
                    item
                    xs={mediaQuery1238 ? 6 : 12}
                    sx={{ paddingRight: 1, marginTop: 1, marginBottom: 3 }}
                  >
                    <Grid xs={12}></Grid>
                    <Grid
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setFormData((prev) => {
                          return {
                            ...prev,
                            hidePayNow: !prev.hidePayNow,
                          };
                        });
                        setIsSaveBar(true);
                      }}
                      xs={12}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        borderRadius: '4px',
                        paddingX: 2,
                        paddingY: 1,
                        height: '40px',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ lineHeight: '1.2 !important' }}
                      >
                        Hide the "Pay now" button on the Customer Panel
                      </Typography>
                      <IOSSwitch
                        size="small"
                        defaultChecked
                        sx={{ marginLeft: 2 }}
                        onChange={(e) => {
                          setFormData((prev) => {
                            return {
                              ...prev,
                              hidePayNow: e.target.checked,
                            };
                          });
                          setIsSaveBar(true);
                        }}
                        checked={formData.hidePayNow}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>

      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default CustomerPanel;
