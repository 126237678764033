import { Checkbox, Grid, TextField, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CircleIcon from '@mui/icons-material/Circle';
import { useState } from 'react';
import { ReactSortable } from 'react-sortablejs';

const AppointmentStatuses = ({ currentSettings }) => {
  const [appointmentStatuses, setAppointmentStatuses] = useState(
    Object.keys(currentSettings.appointmentStatuses).map((key, i) => {
      return { ...currentSettings.appointmentStatuses[key], id: i };
    })
  );
  const [activeAppointmentStatus, setActiveAppointmentStatus] = useState(1);

  const addNewAppointmentStatus = () => {
    setAppointmentStatuses((prevState) => [
      ...prevState,
      { id: prevState.length + 1, title: 'Status' },
    ]);
  };

  return (
    <>
      <Grid
        xs={12}
        sx={{
          marginTop: { xs: 9, sm: 14 },
          backgroundColor: 'white',
          pb: 1,
          mb: 5,
        }}
      >
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
            General Settings{' '}
            <span style={{ color: '#c1c1c1' }}>
              <CircleIcon sx={{ fontSize: '5px', marginX: '3px' }} />{' '}
              Appointment Status
            </span>
          </Typography>
        </Grid>
        <Grid
          container
          rowGap="30px"
          sx={{ padding: { xs: '15px', md: '15px 25px' } }}
        >
          <Grid
            container
            rowGap="10px"
            xs={12}
            lg={5}
            sx={{ paddingRight: { xs: '0px', lg: '30px' } }}
          >
            <ReactSortable
              list={appointmentStatuses}
              setList={setAppointmentStatuses}
              animation={200}
              delayOnTouchStart={true}
              delay={2}
              handle=".dragable-icon"
              style={{
                display: 'flex',
                rowGap: '10px',
                flexWrap: 'wrap',
                width: '100%',
              }}
            >
              {appointmentStatuses.map((appointmentStatus, index) => (
                <Grid
                  key={index}
                  onClick={() =>
                    setActiveAppointmentStatus(appointmentStatus.id)
                  }
                  xs={12}
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    width: '320px',
                    border: '1px solid #e3eaf3',
                    padding: '11px 15px',
                    borderRadius: '2px',
                    cursor: 'pointer',
                    background:
                      activeAppointmentStatus === appointmentStatus.id
                        ? 'rgba(108,112,220,.1)'
                        : 'none',
                  }}
                >
                  <Grid container alignItems="center" xs={10}>
                    <MenuIcon
                      className="dragable-icon"
                      sx={{
                        color:
                          activeAppointmentStatus === appointmentStatus.id
                            ? '#6C70DC'
                            : '#ADBFC7',
                      }}
                    />
                    <Typography sx={{ marginLeft: '10px', fontSize: '14px' }}>
                      {appointmentStatus.title}
                    </Typography>
                  </Grid>
                  <DeleteIcon sx={{ color: '#FB3E6E' }} />
                </Grid>
              ))}
            </ReactSortable>

            <Grid
              container
              xs={12}
              alignItems="center"
              justifyContent="center"
              onClick={addNewAppointmentStatus}
              sx={{
                width: '320px',
                border: '1px solid #e3eaf3',
                padding: '11px 15px',
                borderRadius: '2px',
                cursor: 'pointer',
              }}
            >
              <AddIcon />
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            lg={7}
            sx={{
              padding: '25px',
              background:
                'linear-gradient(to right,#c0d6df 50%,transparent 0%),linear-gradient(#c0d6df 50%,transparent 0%),linear-gradient(to right,#c0d6df 50%,transparent 0%),linear-gradient(#c0d6df 50%,transparent 0%)',
              backgroundPosition: 'top,right,bottom,left',
              backgroundRepeat: 'repeat-x,repeat-y',
              backgroundSize: '8px 1px,1px 8px',
            }}
          >
            <Grid container xs={12} sx={{ height: 'fit-content' }}>
              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Title</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    disabled
                    value={
                      appointmentStatuses.filter((stat) => {
                        return activeAppointmentStatus === stat.id;
                      })[0].title
                    }
                    onChange={() => {}}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Key</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    disabled
                    value={
                      appointmentStatuses.filter((stat) => {
                        return activeAppointmentStatus === stat.id;
                      })[0].key
                    }
                    onChange={() => {}}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Text color</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="color"
                    id="outlined-basic"
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    disabled
                    value={
                      appointmentStatuses.filter((stat) => {
                        return activeAppointmentStatus === stat.id;
                      })[0].color
                    }
                    onChange={() => {}}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Background color</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="color"
                    id="outlined-basic"
                    size="small"
                    required
                    variant="outlined"
                    disabled
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    value={
                      appointmentStatuses.filter((stat) => {
                        return activeAppointmentStatus === stat.id;
                      })[0].bgColor
                    }
                    onChange={() => {}}
                  />
                </Grid>
              </Grid>

              <Grid
                xs={12}
                sm={6}
                sx={{ paddingRight: { sm: 1 }, marginTop: 1 }}
              >
                <Grid
                  id="once-per-person"
                  container
                  alignItems="center"
                  item
                  xs={12}
                  onClick={() => {}}
                  sx={{
                    cursor: 'pointer',
                    borderRadius: '4px',
                    height: 40,
                  }}
                >
                  <Checkbox
                    size="small"
                    sx={{ color: '#8b97a1' }}
                    disabled
                    checked={true}
                  />
                  <Typography variant="subtitle2">Mark as busy slot</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AppointmentStatuses;
