import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { Chip, TableFooter } from '@mui/material';
import PriceFormatter from '../../utils/PriceFormatter';
import StatusChip from '../../components/StatusChip';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';

const headCells = [
  {
    id: 1,
    numeric: false,
    disablePadding: true,
    label: 'DATE',
  },
  {
    id: 2,
    numeric: true,
    disablePadding: false,
    label: 'CUSTOMER',
  },
  {
    id: 3,
    numeric: true,
    disablePadding: false,
    label: 'STATUS',
  },
  {
    id: 4,
    numeric: true,
    disablePadding: false,
    label: 'STAFF',
  },
  {
    id: 5,
    numeric: true,
    disablePadding: false,
    label: 'SERVICE',
  },
  {
    id: 6,
    numeric: true,
    disablePadding: false,
    label: 'DURATION',
  },
  {
    id: 7,
    numeric: true,
    disablePadding: false,
    label: 'CREATED',
  },
  {
    id: 8,
    numeric: true,
    disablePadding: false,
    label: 'ACTIONS',
  },
];

function EnhancedTableHead() {
  return (
    <TableHead sx={{ margin: '10px', backgroundColor: '#EFF1FC' }}>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell key={headCell.id} align={index === 7 ? 'right' : 'left'}>
            <TableSortLabel
              sx={{
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                fontSize: '12px',
              }}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const AppointmentPage = ({ data }) => {
  const TableData = () =>
    data.map((inf) => {
      return (
        <TableRow hover key={inf.id}>
          {/* <TableCell /> */}
          <TableCell
            component="th"
            scope="row"
            padding="none"
            sx={{
              pl: '20px',
              maxWidth: '250px',
            }}
          >
            {inf.employee}
          </TableCell>
          <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
            {inf.date}
          </TableCell>
          <TableCell
            align="left"
            sx={{
              maxWidth: '300px',
              width: '300px',
            }}
          >
            {inf.customers}
          </TableCell>
          <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
            {inf.service}
          </TableCell>
          <TableCell align="left">
            <>
              {inf.status === 'Custom' ? (
                <StatusChip
                  color={inf.status.statusColor}
                  bgColor={inf.status.statusBgColor}
                  text={inf.status.statusText}
                  rounded={true}
                  width={'max-content'}
                />
              ) : (
                <StatusChip
                  type={'appointment' + inf.status}
                  width={'max-content'}
                />
              )}
            </>
            {/* <Chip label={inf.status} sx={{ background: "#84FFDE" }} /> */}
          </TableCell>
          <TableCell align="left">
            {PriceFormatter.format(inf.payment)}
          </TableCell>
          <TableCell align="left">{inf.createdAt}</TableCell>
        </TableRow>
      );
    });
  return (
    <Box sx={{ width: '100%', zIndex: 0 }}>
      <CustomTextButton text="+ Create appointment" />
      <Paper
        sx={{
          width: '100%',
          mt: '12px',
          mb: 2,
          fontSize: '14px',
          shadow: 'none !important',
        }}
      >
        <TableContainer
          sx={{
            shadow: 'none !important',
          }}
        >
          <Table
            sx={{ minWidth: 750, shadow: 'none !important' }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead />
            <TableBody
              sx={{
                shadow: 'none !important',
              }}
            >
              {
                data.length ? <TableData /> : null
                // <>
                //   <TableRow colSpan={7}>
                //     <TableCell
                //       colSpan={7}
                //       align="center"
                //       sx={{
                //         display: 'flex',
                //         flexDirection: 'column',
                //         alignItems: 'center',
                //         justifyContent: 'Center',
                //         width: '100%',
                //       }}
                //     >
                //       <Box
                //         sx={{
                //           width: '100px',
                //           height: '100px',
                //         }}
                //       >
                //         <img
                //           src="https://user-images.githubusercontent.com/109676648/254145031-57027cd6-fecd-4ce2-96fc-9d891c8de53a.svg"
                //           alt="no data"
                //           style={{
                //             width: '100%',
                //             height: '100%',
                //           }}
                //         />
                //       </Box>
                //       No data at the moment 😔
                //     </TableCell>
                //   </TableRow>
                // </>
              }
            </TableBody>
            <TableFooter
              sx={{
                shadow: 'none !important',
              }}
            >
              {data.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={8}
                    sx={{
                      py: '50px',
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'Center',
                        gap: '20px',
                      }}
                    >
                      <img
                        src="https://user-images.githubusercontent.com/109676648/254145031-57027cd6-fecd-4ce2-96fc-9d891c8de53a.svg"
                        alt="no data"
                        style={{
                          width: '100px',
                          height: '100px',
                        }}
                      />
                      No data at the moment 😔
                    </Box>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default AppointmentPage;
