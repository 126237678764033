import React from 'react';
import Drawer from '../../../components/Drawer';
import DrawerFooter from '../../../components/DrawerFooter';
import Grid from '@mui/material/Grid';
import CustomTextButton from '../../../components/CustomButtons/CustomTextButton';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { Typography, TextareaAutosize, Autocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import useTaskDrawer from './useTaskDrawer';
import { crudTypes } from '../../../constants/crudTypes';

const statuslist = [
  'To-Do',
  'In Progress',
  'On Hold',
  'Completed',
  'Overdue',
  'Deferred',
  'Cancelled',
  'Waiting',
  'Scheduled',
  'Blocked',
];

const prioritylist = ['Critical', 'High', 'Medium', 'Low'];

const hardCodedRelatedOptions = [
  { name: 'staff', id: 1 },
  { name: 'customer', id: 2 },
];

// Add Attachment
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const TaskDrawer = ({ crudType, data = null, refetchData = null }) => {
  const {
    formData,
    loading,
    isStartDateOpen,
    setIsStartDateOpen,
    isEndDateOpen,
    setIsEndDateOpen,
    staff,
    customers,
    locations,
    attachments,
    selectedStaff,
    setSelectedStaff,
    isSmallScreen,
    toggleDrawer,
    handleAttachmentChange,
    handleDeleteAttachment,
    handleSelectData,
    handleAddTask,
    handleEditTask,
    errors,
    setErrors,
  } = useTaskDrawer({
    crudType,
    data,
    refetchData,
  });

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      /*   style={{ padding: '20px' }} */
    >
      <Grid item xs={12} sm={2}>
        <Drawer
          type={crudType}
          title={crudType === crudTypes.ADD ? 'Add Task' : 'Edit Task'}
          toggleDrawer={toggleDrawer}
        >
          {/* -----------FORM----- */}
          <Grid
            sx={{
              height: '100%',
              overflowY: 'auto',
              width: '100%',
              padding: '20px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: isSmallScreen ? 'flex-start' : 'flex-start',
              }}
            >
              <CustomTextButton
                component="label"
                role={undefined}
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Add attachment
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleAttachmentChange}
                />
              </CustomTextButton>
            </div>
            {/* -----------------Attachments added here----------------------- */}
            {attachments &&
              attachments.map((attachment, index) => (
                <div
                  key={index}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {/*   <a
                    href={attachment}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                </a> */}
                  {attachment.name}
                  <CloseRoundedIcon
                    onClick={() => handleDeleteAttachment(index)}
                  />
                </div>
              ))}

            {/* ---------------------Title------------------------------- */}
            <Grid container>
              <Grid
                xs={12}
                sm={12}
                sx={{ paddingRight: { sm: 0 }, marginTop: 1 }}
              >
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">
                    Title <span style={{ color: 'red' }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    type="text"
                    onChange={(e) => {
                      handleSelectData(e.target.value, 'title');
                      setErrors({ ...errors, name: false });
                    }}
                    value={formData.title}
                    helperText={errors.title ? 'Value is missing' : ' '}
                    error={errors.title}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* ----------------Description------------------- */}
            <Grid container>
              <Grid xs={12} sm={12} sx={{ paddingRight: { sm: 0 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Description</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputProps={{
                      inputComponent: TextareaAutosize,
                      inputProps: {
                        style: {
                          resize: 'auto',
                          height: '120px',
                        },
                      },
                    }}
                    size="small"
                    required
                    variant="outlined"
                    placeholder="Describe task"
                    style={{
                      minHeight: '120px',
                      fontSize: '16px',
                      width: '100%',
                    }}
                    onChange={(e) => {
                      handleSelectData(e.target.value, 'description');
                      setErrors({ ...errors, description: false });
                    }}
                    value={formData.description}
                    error={errors.description}
                  />
                  <FormHelperText error>
                    {errors.description ? 'Value is missing' : ' '}
                  </FormHelperText>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              {/* ------------------Status---------------- */}
              <Grid item xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">
                    Status <span style={{ color: 'red' }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    onChange={(event, data) => handleSelectData(data, 'status')}
                    value={formData.status}
                    disablePortal
                    options={statuslist}
                    getOptionLabel={(option) => option}
                    sx={{
                      width: '100%',
                      fontSize: '14px',
                    }}
                    renderInput={(params) => (
                      //errormessage
                      <TextField
                        {...params}
                        placeholder={statuslist[0]}
                        helperText={errors.status ? 'Value is missing' : ' '}
                        error={errors.status}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              {/* -------------Responsible-------------- */}

              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Responsible</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    options={staff}
                    value={selectedStaff}
                    onChange={(event, newValue) => setSelectedStaff(newValue)}
                    getOptionLabel={(option) =>
                      option?.User?.firstName
                        ? option.User.firstName
                        : option.firstName || ''
                    }
                    sx={{
                      width: '100%',
                      fontSize: '14px',
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select..."
                        helperText={
                          errors.responsible ? 'Value is missing' : ' '
                        }
                        error={errors.responsible}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* ---------------------Related to -------------------- */}
            <Grid container>
              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Related To</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    onChange={(event, data) => {
                      handleSelectData(data.name, 'relatedTo');
                      handleSelectData(null, 'relatedToValue');
                    }}
                    value={
                      hardCodedRelatedOptions.filter(
                        (i) => i.name == formData.relatedTo
                      )[0]
                    }
                    disablePortal
                    options={hardCodedRelatedOptions}
                    getOptionLabel={(option) => option.name}
                    sx={{
                      width: '100%',
                      fontSize: '14px',
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select..."
                        value={formData.relatedTo}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {formData.relatedTo !== null && (
                <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">
                      {formData.relatedTo === 'customer'
                        ? 'Customers'
                        : 'Staff'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      size="small"
                      onChange={(event, data) =>
                        handleSelectData(data, 'relatedToValue')
                      }
                      value={formData.relatedToValue}
                      disablePortal
                      options={
                        formData.relatedTo === 'staff' ? staff : customers
                      }
                      getOptionLabel={(option) =>
                        option.User?.firstName
                          ? option.User?.firstName
                          : option.firstName
                      }
                      sx={{
                        width: '100%',
                        fontSize: '14px',
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select..."
                          value={formData.relatedTo}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>

            {/* ----------------StartDate---------------------- */}
            <Grid container>
              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Start date</Typography>
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      open={isStartDateOpen}
                      onOpen={() => setIsStartDateOpen(!isStartDateOpen)}
                      onClose={() => setIsStartDateOpen(!isStartDateOpen)}
                      value={formData.startDate}
                      onChange={(newValue) => {
                        handleSelectData(newValue, 'startDate');
                        setErrors({ ...errors, date: false });
                      }}
                      InputProps={{
                        style: {
                          flexDirection: 'row-reverse',
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          onClick={() => setIsStartDateOpen(!isStartDateOpen)}
                          onTouchStart={() =>
                            setIsStartDateOpen(!isStartDateOpen)
                          }
                          fullWidth
                          error={errors.date}
                          helperText={errors.date ? 'Value is missing' : ' '}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>

              {/* -------------DueDate-------------- */}
              <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Due date</Typography>
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      open={isEndDateOpen}
                      onOpen={() => setIsEndDateOpen(!isEndDateOpen)}
                      onClose={() => setIsEndDateOpen(!isEndDateOpen)}
                      value={formData.endDate}
                      onChange={(newValue) => {
                        handleSelectData(newValue, 'endDate');
                        setErrors({ ...errors, endDate: false });
                      }}
                      InputProps={{
                        style: {
                          flexDirection: 'row-reverse',
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          onClick={() => setIsEndDateOpen(!isEndDateOpen)}
                          onTouchStart={() => setIsEndDateOpen(!isEndDateOpen)}
                          fullWidth
                          error={errors.endDate}
                          helperText={errors.endDate ? 'Value is missing' : ' '}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>

            {/* ----------------Location---------------------- */}
            <Grid container>
              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Location</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    options={locations}
                    loading={loading}
                    value={formData.selectedLocation}
                    onChange={(event, newValue) =>
                      handleSelectData(newValue, 'selectedLocation')
                    }
                    getOptionLabel={(option) => option.name} // Assuming each location object has a 'name' property
                    sx={{
                      width: '100%',
                      fontSize: '14px',
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select..."
                        helperText={errors.location ? 'Value is missing' : ' '}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              {/* -------------Priority-------------- */}
              <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">
                    Priority<span style={{ color: 'red' }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    onChange={(event, data) =>
                      handleSelectData(data, 'priority')
                    }
                    value={formData.priority}
                    disablePortal
                    options={prioritylist}
                    getOptionLabel={(option) => option}
                    sx={{
                      width: '100%',
                      fontSize: '14px',
                    }}
                    renderInput={(params) => (
                      //errormessage
                      <TextField
                        {...params}
                        placeholder={prioritylist[1]}
                        value={formData.priority}
                        error={errors.priority}
                        helperText={errors.priority ? 'Value is missing' : ' '}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* ----------------ReferenceID---------------------- */}
            <Grid container>
              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Reference ID</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    type="text"
                    onChange={(e) => {
                      handleSelectData(e.target.value, 'referenceId');
                      setErrors({ ...errors, name: false });
                    }}
                    value={formData.referenceId}
                    helperText={errors.referenceId ? 'Value is missing' : ' '}
                    error={errors.referenceId}
                  />
                </Grid>
              </Grid>

              {/* -------------OrderID-------------- */}
              <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Order ID</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    type="text"
                    onChange={(e) => {
                      handleSelectData(e.target.value, 'orderId');
                      setErrors({ ...errors, name: false });
                    }}
                    value={formData.orderId}
                    helperText={errors.referenceId ? 'Value is missing' : ' '}
                    error={errors.referenceId}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* ----------Footer------------ */}
          <DrawerFooter>
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: isSmallScreen ? 'flex-start' : 'normal',
                }}
              ></div>

              <div
                style={{
                  display: 'flex',
                  gap: '15px',
                  justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                  width: isSmallScreen ? '100%' : 'auto',
                  alignItems: 'center',
                }}
              >
                <CustomTextButton
                  variant="contained"
                  size="large"
                  onClick={toggleDrawer}
                >
                  Cancel
                </CustomTextButton>
                <CustomTextButton
                  type="blue"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    crudType === crudTypes.ADD
                      ? handleAddTask()
                      : handleEditTask();
                  }}
                >
                  Save
                </CustomTextButton>
              </div>
            </>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default TaskDrawer;
