import { useEffect, useLayoutEffect, useState } from 'react';
import { crudTypes } from '../../../constants/crudTypes';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../redux/slices/user.slice.js';
import { useMediaQuery, useTheme } from '@mui/material';
import { getLocationList } from '../../../Api/tenant/locations';
import { addTask } from '../../../Api/tenant/tasks';
import { getStaffList } from '../../../Api/tenant/staff';
import { editTaskById } from '../../../Api/tenant/tasks';
import { getCustomerList } from '../../../Api/tenant/customers';
import { drawersActions } from '../../../redux/slices/drawers.slice';

const requiredFields = ['title', 'status', 'priority'];
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

function useTaskDrawer({ crudType, data, refetchData = null }) {
  const [formData, setFormData] = useState({
    title: null,
    description: null,
    status: 'To-Do',
    relatedTo: null,
    startDate: new Date(),
    endDate: new Date(),
    selectedLocation: null,
    priority: 'Medium',
    referenceId: null,
    orderId: null,
    relatedTo: null,
    relatedToValue: null,
  });
  const { user } = useSelector(selectUser);
  const [loading, setLoading] = useState(true);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [attachments, setAttachments] = useState([]);

  //data picker
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);

  // options for select
  const [locations, setLocations] = useState([]);
  const [staff, setStaff] = useState([]);
  const [customers, setCustomers] = useState([]);

  // error handling
  const [errors, setErrors] = useState({ endDate: false });

  const dispatch = useDispatch();

  const toggleDrawer = () => {
    dispatch(drawersActions.closeDrawer());
  };

  useLayoutEffect(() => {
    if (crudType === crudTypes.EDIT) {
      // Here we will find selectedLocation with locatinId we have and then populating formData
      let foundLocation;
      if (locations.length !== 0) {
        foundLocation = locations.filter((loc) => loc.id == data.locationId);
      }

      setFormData({
        ...data,
        relatedToValue: data.relatedToUser,
        selectedLocation: foundLocation ? foundLocation[0] : null,
      });

      setSelectedStaff(data.responsibility);
    }
  }, [crudType, data, locations.length]);

  //Fetch locations function
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch locations
        const locationResponse = await getLocationList();
        if (
          locationResponse.status === 200 &&
          Array.isArray(locationResponse.data.rows)
        ) {
          setLocations(locationResponse.data.rows);
        } else {
          console.error(
            'Error fetching locations:',
            locationResponse.statusText
          );
        }

        // Fetch staff
        const staffResponse = await getStaffList();
        if (
          staffResponse.status === 200 &&
          Array.isArray(staffResponse.data.rows)
        ) {
          setStaff(staffResponse.data.rows);
          if (crudType === crudTypes.ADD) {
            setSelectedStaff(
              staffResponse.data.rows.filter((i) => i.UserId === user.id)[0]
            );
          }
        } else {
          console.error('Error fetching staff:', staffResponse.statusText);
        }

        // Fetch customers
        const customersResponse = await getCustomerList(100, 1);
        if (
          customersResponse.status === 200 &&
          Array.isArray(customersResponse.data)
        ) {
          setCustomers(customersResponse.data);
        } else {
          console.error('Error fetching staff:', customersResponse.statusText);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  //show attachment link
  const handleAttachmentChange = (event) => {
    const files = event.target.files;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.size <= MAX_FILE_SIZE) {
          const newAttachment = file;
          setAttachments((prevAttachments) => [
            ...prevAttachments,
            newAttachment,
          ]);
        } else {
          alert(
            `File is to big. Max size is ${MAX_FILE_SIZE / (1024 * 1024)} MB.`
          );
        }
      }
    }
  };

  //delete atachment
  const handleDeleteAttachment = (index) => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);
    setAttachments(updatedAttachments);
  };

  // API
  const handleSelectData = (dataOrSetter, fieldName) => {
    setFormData((prevState) => {
      if (typeof dataOrSetter === 'function') {
        return dataOrSetter(prevState);
      } else {
        return {
          ...prevState,
          [fieldName]: dataOrSetter,
        };
      }
    });
  };

  const handleAddTask = async () => {
    // Validation handling
    let err = {};

    for (const key in formData) {
      if (!formData[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
      }
    }

    setErrors({ ...err });

    if (Object.keys(err).length === 0) {
      let formdata = new FormData();
      try {
        formdata.append('title', formData.title);
        formdata.append('description', formData.description);
        formdata.append('status', formData.status);
        formdata.append('priority', formData.priority);
        formData.startDate && formdata.append('startDate', formData.startDate);
        formData.endDate && formdata.append('endDate', formData.endDate);
        formdata.append('referenceId', formData.referenceId);
        formdata.append('orderId', formData.orderId);
        formData.relatedTo && formdata.append('relatedTo', formData.relatedTo);
        formData.relatedToValue &&
          formdata.append('relatedToId', formData.relatedToValue.UserId);
        formdata.append('public', true);
        selectedStaff && formdata.append('responsible', selectedStaff?.id);
        formData?.selectedLocation &&
          formdata.append('locationId', formData?.selectedLocation?.id);
        formdata.append(
          'creator',
          JSON.stringify({
            firstName: formData.selectedStaff?.firstName,
            image: formData.selectedStaff?.image,
          })
        );
        attachments.map((attachment) => {
          formdata.append('attachments', attachment);
        });
      } catch {}

      try {
        const response = await addTask(formdata);
        if (response.status === 200) {
          const newTaskData = response.data; // Assuming response.data contains the new task data
          refetchData();
          setFormData([newTaskData, ...Object.values(formData)]); // Prepend the new task data to the array
          toggleDrawer();
        } else {
          console.error('Failed to add task:', response);
        }
      } catch (error) {
        console.error('Error adding task:', error);
      }
    }
  };

  const handleEditTask = () => {
    setLoading(true);
    const {
      id,
      description,
      title,
      status,
      priority,
      startDate,
      endDate,
      referenceId,
      orderId,
      image,
      attachment,
    } = formData;

    if (!id) {
      setLoading(false);
      return;
    }

    let formdata = new FormData();
    formdata.append('id', id);
    formdata.append('title', title);
    description && formdata.append('description', description);
    status && formdata.append('status', status);
    priority && formdata.append('priority', priority);
    startDate && formdata.append('startDate', startDate);
    endDate && formdata.append('endDate', endDate);
    referenceId && formdata.append('referenceId', referenceId);
    orderId && formdata.append('orderId', orderId);
    selectedStaff && formdata.append('responsible', selectedStaff?.id);
    formData.relatedTo && formdata.append('relatedTo', formData.relatedTo);
    formData.relatedToValue &&
      formdata.append(
        'relatedToId',
        formData.relatedToValue.UserId
          ? formData.relatedToValue.UserId
          : formData.relatedToValue.id
      );
    formdata.append('public', true);

    formdata.append(
      'creator',
      JSON.stringify({
        firstName: selectedStaff?.firstName,
        image: selectedStaff?.image,
      })
    );

    if (attachments && attachments.length > 0) {
      attachments.forEach((attachment) => {
        formdata.append('attachments', attachment);
      });
    }

    editTaskById(formdata, id)
      .then((res) => {
        toggleDrawer();
        refetchData();
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error editing task: ', error);
        setLoading(false);
      });
  };

  return {
    formData,
    loading,
    isStartDateOpen,
    setIsStartDateOpen,
    isEndDateOpen,
    setIsEndDateOpen,
    staff,
    customers,
    locations,
    attachments,
    selectedStaff,
    setSelectedStaff,
    isSmallScreen,
    toggleDrawer,
    handleAttachmentChange,
    handleDeleteAttachment,
    handleSelectData,
    handleAddTask,
    handleEditTask,
    errors,
    setErrors,
  };
}

export default useTaskDrawer;
