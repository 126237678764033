import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import Drawer from '../../components/Drawer';
import DrawerFooter from '../../components/DrawerFooter';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useMediaQuery, useTheme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import {
  addCoupon,
  editCouponById,
  getCouponById,
} from '../../Api/tenant/coupon';
import SelectService from '../../components/AysncAutoComplete/SelectService';
import SelectStaff from '../../components/AysncAutoComplete/SelectStaff';

const AddCoupon = ({ toggleDrawer, drawerType, fetchCoupons, drawerData }) => {
  const [formData, setFormData] = useState({
    code: null,
    dValue: null,
    dType: 0,
    from: null,
    to: null,
    usage: 0,
    oncePerCustomer: true,
    status: 'Active',
    services: [], // Selected services
    staff: [], // Selected staff
  });

  const matches = useMediaQuery(useTheme().breakpoints.up('sm'));
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  const handleFormData = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  // API

  const fetchCouponsData = useCallback(async () => {
    try {
      const response = await getCouponById(drawerData.id);
      if (response.status === 200) {
        const result = response.data;
        console.log(result);
        setFormData({ ...formData, ...result });
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  useLayoutEffect(() => {
    if (drawerType === 'edit' && drawerData?.id) {
      fetchCouponsData();
    }
  }, [drawerType, fetchCouponsData]);

  // error handling
  const [errors, setErrors] = useState({});
  const requiredFields = [
    'code',
    'dValue',
    'from',
    'to',
    'usage',
    'status',
    'services', // Selected services
    'staff',
  ];

  const handleAddCoupon = () => {
    let data = formData;
    let err = {};
    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
        console.log('s', data[key], data);
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    console.log(err);
    setErrors({ ...err });
    if (Object.keys(err).length === 0) {
      const body = {
        ...formData,
        services: formData.services.map((ser) => ser),
        staff: formData.staff.map((staff) => staff.id),
      };
      addCoupon(body).then((res) => {
        toggleDrawer();
        fetchCoupons();
      });
    }
  };

  const handleEditCoupon = () => {
    let data = formData;
    let err = {};
    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
        console.log('s', data[key], data);
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });
    if (Object.keys(err).length === 0) {
      const body = {
        ...formData,
        services: formData.services.map((ser) => ser),
        staff: formData.staff.map((staff) => staff.id),
      };

      editCouponById(body, drawerData.id).then((res) => {
        toggleDrawer();
        fetchCoupons();
      });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Drawer
          toggleDrawer={toggleDrawer}
          title={drawerType === 'add' ? 'Add Coupon' : 'Edit Coupon'}
          type={drawerType === 'add' ? 'ADD' : 'EDIT'}
        >
          {/* -----------FORM----- */}

          <Grid
            sx={{
              paddingX: '24px',
              paddingTop: 3,
              height: '100%',
              overflowY: 'auto',
            }}
          >
            {(formData.code || drawerType == 'add') && (
              <Grid container>
                <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">
                      Code <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '0rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                      value={formData.code}
                      onChange={(e) => {
                        handleFormData('code', e.target.value);
                        setErrors({ ...errors, code: false });
                      }}
                      helperText={errors.code ? 'Value is missing' : ' '}
                      error={errors.code}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} sm={6}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">
                      Discount <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        width: '50%',
                        marginBottom: '0rem',
                        fontSize: '14px',
                        '& fieldset': {
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        },
                      }}
                      disabled={drawerType !== 'add' ? true : false}
                      onChange={(e) => {
                        handleFormData('dValue', e.target.value);
                        setErrors({ ...errors, dValue: false });
                      }}
                      value={formData.dValue}
                      helperText={errors.dValue ? 'Value is missing' : ' '}
                      error={errors.dValue}
                    />
                    <select
                      style={{
                        width: matches ? '47%' : '50%',
                        height: 40,
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        outline: 'none',
                        borderTopRightRadius: '4px',
                        borderBottomRightRadius: '4px',
                        fontSize: '14px',
                        color: '#292D32',
                        paddingLeft: 6,
                      }}
                      onChange={(e) => {
                        handleFormData('dType', e.target.value);
                      }}
                      value={formData.dType}
                    >
                      <option value={0}>%</option>
                      <option value={1}>$</option>
                    </select>
                  </Grid>
                </Grid>

                <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">
                      Applies date from <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="dd-MM-yyyy"
                        value={formData.from}
                        onChange={(v) => {
                          handleFormData('from', v);
                          setErrors({ ...errors, from: false });
                        }}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            sx={{ width: '100%' }}
                            helperText={errors.from ? 'Value is missing' : ' '}
                            error={errors.from}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">
                      Applies date to <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="dd-MM-yyyy"
                        value={formData.to}
                        onChange={(v) => {
                          handleFormData('to', v);
                          setErrors({ ...errors, to: false });
                        }}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            sx={{ width: '100%' }}
                            helperText={errors.to ? 'Value is missing' : ' '}
                            error={errors.to}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Grid
                  xs={12}
                  sm={6}
                  sx={{ paddingRight: { sm: 1 }, marginTop: 1 }}
                >
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">
                      Usage Limit <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '0rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                      value={formData.usage}
                      onChange={(e) => {
                        handleFormData('usage', e.target.value);
                        setErrors({ ...errors, usage: false });
                      }}
                      helperText={errors.usage ? 'Value is missing' : ' '}
                      error={errors.usage}
                    />
                  </Grid>
                </Grid>

                <Grid
                  xs={12}
                  sm={6}
                  sx={{ paddingRight: { sm: 1 }, marginTop: 1 }}
                >
                  <Grid
                    container
                    alignItems="center"
                    item
                    xs={12}
                    onClick={() =>
                      handleFormData(
                        'oncePerCustomer',
                        !formData.oncePerCustomer
                      )
                    }
                    sx={{
                      cursor: 'pointer',
                      border: '1px solid rgba(0, 0, 0, 0.23)',
                      borderRadius: '4px',
                      marginTop: 4.8,
                      height: 40,
                    }}
                  >
                    <Checkbox
                      size="small"
                      sx={{ color: '#8b97a1' }}
                      checked={formData.oncePerCustomer}
                    />
                    <Typography sx={{ color: '#8b97a1' }} variant="subtitle2">
                      Once per customer
                    </Typography>
                  </Grid>
                </Grid>

                <Grid xs={12}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">
                      Services <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <SelectService
                      value={formData.services}
                      onChange={(e, v) => {
                        handleFormData('services', v);
                        setErrors({ ...errors, services: false });
                      }}
                      sx={{ marginBottom: '0rem' }}
                      multiple
                      helperText={errors.services ? 'Value is missing' : ' '}
                      error={errors.services}
                    />
                  </Grid>
                </Grid>

                <Grid xs={12}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Staff</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <SelectStaff
                      selectedValue={formData.staff}
                      setSelectedValue={(v) => {
                        handleFormData('staff', v);
                      }}
                      sx={{ marginBottom: '0rem' }}
                      multiple
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>

          {/* -----Button */}

          <DrawerFooter>
            <div
              style={{
                display: 'flex',
                gap: '15px',
                justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                width: '100%',
              }}
            >
              <CustomTextButton
                variant="contained"
                size="large"
                onClick={toggleDrawer}
              >
                Cancel
              </CustomTextButton>
              <CustomTextButton
                type="blue"
                variant="contained"
                size="large"
                onClick={() => {
                  if (drawerType === 'add') {
                    handleAddCoupon();
                  } else {
                    handleEditCoupon();
                  }
                }}
              >
                Save
              </CustomTextButton>
            </div>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default AddCoupon;
