import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AvatarComp from '../../components/Avatar';
import { useTranslation } from 'react-i18next';

function LowestCustomerRevenueWidget({ lowestCustomers }) {
  const { t } = useTranslation('dashboard');

  return (
    <Grid
      item
      xs={12}
      sm={6}
      lg={3}
      sx={{
        mb: '20px',
        height: '368px',
      }}
    >
      <Card
        sx={{
          borderRadius: '0px',
          height: '368px',
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          boxShadow: 'rgba(58, 58, 80, 0.1) 0px 0px 15px',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            px: '20px !important',
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#3A3A50',
            }}
          >
            {t('lowRevenueLast30DaysWidget.title')}
          </Typography>
          {/* TODO: this should be id it should be array of customer objects  */}
          {lowestCustomers?.User &&
            lowestCustomers.map((customer) => {
              return (
                <>
                  {customer && (
                    <Grid
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Grid
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        <Box sx={{}}>
                          <AvatarComp
                            img={customer.User.image}
                            username={`${customer.User.firstName} ${customer.User.lastName}`}
                          />
                        </Box>
                        <Typography
                          sx={{
                            color: '#3A3A50',
                          }}
                        >
                          {customer.User.firstName +
                            ' ' +
                            customer.User.lastName}
                        </Typography>
                      </Grid>
                      <a
                        href={`mailto: ${customer.User.email}`}
                        style={{
                          color: '#85888a',
                          fontWeight: 'bold',
                        }}
                      >
                        Offer
                      </a>
                    </Grid>
                  )}
                </>
              );
            })}
        </CardContent>
      </Card>
    </Grid>
  );
}

export default LowestCustomerRevenueWidget;
