import GeneralSettingsComp from './GeneralSettings';
import AppointmentStatuses from './AppointmentStatuses';
import WaitingList from './WaitingList';
import PaymentMethods from '../PaymentSettings/PaymentMethods';

const GeneralSettings = ({ subTab, fetchCurrentSettings, currentSettings }) => {
  return (
    <>
      {subTab === 'general-settings' && (
        <GeneralSettingsComp
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {subTab === 'appointment-statuses' && (
        <AppointmentStatuses
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {subTab === 'waiting-list' && (
        <WaitingList
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {subTab === 'payment-methods' && (
        <PaymentMethods
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
    </>
  );
};

export default GeneralSettings;
