import { useLayoutEffect, useState } from 'react';
import { Grid, Switch, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import PhoneInput from 'react-phone-number-input';
import SaveSlideBar from '../../SaveSlideBar';
import { editCustomerSettings } from '../../../Api/customer/settings';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 1.5,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#8468FA' : '#8468FA',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 17,
    height: 17,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const InvoiceDetails = ({ fetchCurrentSettings, currentSettings }) => {
  const [isCompany, setIsCompany] = useState(false);
  const [formData, setFormData] = useState({
    company: '',
    address: '',
    zip: '',
    phoneNumber: '',
    country: '',
    city: '',
    ref: '',
    vat: '',
    coAddress: '',
    place: '',
  });
  const [isSaveBar, setIsSaveBar] = useState(false);

  const handleFormData = (key, selectedValue) => {
    setFormData({ ...formData, [key]: selectedValue });
    setIsSaveBar(true);
  };

  useLayoutEffect(() => {
    setIsCompany(
      currentSettings.invoiceDetails.company.length > 0 ? true : false
    );
    setFormData(currentSettings.invoiceDetails);
  }, []);

  const handleSave = () => {
    editCustomerSettings({
      ...currentSettings,
      invoiceDetails: {
        ...formData,
      },
    }).then((res) => {
      if (res.status === 200) {
        fetchCurrentSettings().then(() => {
          setIsSaveBar(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setFormData(currentSettings?.invoiceDetails || {});
    setIsCompany(currentSettings?.invoiceDetails?.company);
    setIsSaveBar(false);
  };

  return (
    <>
      <Grid
        xs={12}
        sx={{
          marginTop: { xs: 9, sm: 14 },
          backgroundColor: 'white',
          pb: 1,
          mb: 5,
        }}
      >
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>
            Invoice details
            <span style={{ color: '#c1c1c1' }}></span>
          </Typography>
        </Grid>
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                Switch on if you are a company.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  borderRadius: '4px',
                  paddingX: 2,
                  paddingY: 1,
                  height: '40px',
                }}
                onClick={() => {
                  setIsCompany((prev) => {
                    if (prev) {
                      handleFormData('company', '');
                    }
                    return !prev;
                  });
                  setIsSaveBar(true);
                }}
              >
                <Typography variant="subtitle2">Company</Typography>
                <IOSSwitch
                  size="small"
                  sx={{ marginLeft: 2 }}
                  checked={isCompany}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}></Grid>
          {isCompany && (
            <>
              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 700, marginTop: '2rem' }}
                  >
                    Company Name
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={formData.company}
                    onChange={(e) => handleFormData('company', e.target.value)}
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 700, marginTop: '2rem' }}
                  >
                    Vat no.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={formData.vat}
                    onChange={(e) => handleFormData('vat', e.target.value)}
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                    Address
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={formData.address}
                    onChange={(e) => handleFormData('address', e.target.value)}
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                    C/o address
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={formData.coAddress}
                    onChange={(e) =>
                      handleFormData('coAddress', e.target.value)
                    }
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                    Zip code
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={formData.zip}
                    onChange={(e) => handleFormData('zip', e.target.value)}
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                    Place
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={formData.place}
                    onChange={(e) => handleFormData('place', e.target.value)}
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                    Country
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={formData.country}
                    onChange={(e) => handleFormData('country', e.target.value)}
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                    City
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={formData.city}
                    onChange={(e) => handleFormData('city', e.target.value)}
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                    Refrence
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={formData.ref}
                    onChange={(e) => handleFormData('ref', e.target.value)}
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '2rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                    Phone Number
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={formData.phoneNumber}
                    onChange={(value) => handleFormData('phoneNumber', value)}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {!isCompany && (
            <>
              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 700, marginTop: '2rem' }}
                  >
                    Address
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={formData.address}
                    onChange={(e) => handleFormData('address', e.target.value)}
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 700, marginTop: '2rem' }}
                  >
                    C/o address
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={formData.coAddress}
                    onChange={(e) =>
                      handleFormData('coAddress', e.target.value)
                    }
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                    Zip code
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={formData.zip}
                    onChange={(e) => handleFormData('zip', e.target.value)}
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                    Place
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={formData.place}
                    onChange={(e) => handleFormData('place', e.target.value)}
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default InvoiceDetails;
