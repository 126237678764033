import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './style.css';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { getModulesList } from '../../Api/tenant/modules';
import ModulesStep from './ModulesStep';
import PaymentStep from './PaymentStep';

const modulesData = [
  {
    id: '1',
    name: 'Dashboard',
    tagline: 'A concise overview for quick insights and data visualization.',
    c: false,
  },
  {
    id: '2',
    name: 'Calendar',
    tagline:
      'organize and schedule events, tasks, and appointments efficiently.',
    c: false,
  },
  {
    id: '3',
    name: 'Staff login + Role management',
    tagline: 'Let your user help you manage pages by giving them role access.',
    c: false,
  },
  {
    id: '4',
    name: 'Booking widget',
    tagline:
      'Easily share URLs from your services or staff and incorporate it on your website.',
    c: false,
  },
];

const tooltipListData = [
  'As a tenant, you can still add multiple staff members',
  'You can add and manage customers',
  'Add and manage your staff members',
  'Add and manage service item',
  'A full management appointment module is included in the free version',
  'Manage your sub- and categories for service, product, and restaurant menu',
  'Let customers book group appointments',
  'Add and manage taxes for services and products',
  'Add and manage coupons',
  'All customers with completed appointments can rate and send you a review',
  'Send and communicate with customers with an appointment',
  '+ more feature',
];

const comingSoonLinks = [
  'https://upnize.com/help-center/multiple-locations',
  'https://upnize.com/help-center/product-management',
  'https://upnize.com/help-center/email-notifications',
  'https://upnize.com/help-center/reports',
  'https://upnize.com/help-center/restaurants',
];
const viewDetailsLinks = [
  'https://upnize.com/help-center/dahsboard',
  'https://upnize.com/help-center/calendar',
  'https://upnize.com/help-center/role-management',
  'https://upnize.com/help-center/booking-widget',
];

const comingSoonData = [
  {
    name: 'Multiple locations',
    tagline: 'Manage all your locations from one application',
  },
  {
    name: 'Product management',
    tagline: 'Add / Edit and market your product.',
  },
  {
    name: 'Email notifications',
    tagline: 'Customise your email for customer and staff member.',
  },
  {
    name: 'Reports',
    tagline: 'Valuable data analysis, enabling informed decision-making.',
  },
  {
    name: 'Restaurants',
    tagline: 'Marketing your food and lunch menu in network.',
  },
];

const listData = [
  'Up to 1 team members',
  'Unlimited customers',
  'Unlimited Staff member',
  'Unlimited services',
  'Unlimited appointments',
  'Multi-level categories',
  'Group appointments',
  'Manage taxes',
  'Coupons',
  'Rating and reviews',
  'Private messages',
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: '3px',
      ml: '-5px',
    }}
  >
    <AddOutlinedIcon
      sx={{
        fontSize: '14px',
      }}
    />
    more
  </Box>,
];

const ModulesSubscription = () => {
  // modules vars
  const [perUser, setPerUser] = useState(0);
  const [extension, setExtension] = useState(0);
  const [isLoading, setIsLoading] = useState(0);
  const [modules, setModules] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [numOfUsers, setNumOfUsers] = useState(0);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    let tempM = modules.map((m) =>
      m.name === name ? { ...m, isChecked: checked } : m
    );
    setModules(tempM);
  };

  // payment step vars
  const [isPaymentStep, setIsPaymentStep] = useState(false);

  // API
  const fetchModules = async () => {
    setIsLoading(true);
    getModulesList(100, 1).then((res) => {
      setModules(res.data);
    });
  };

  // fetch on render
  useEffect(() => {
    fetchModules();
  }, []);

  useEffect(() => {
    console.log(selectedModules);
  }, [selectedModules]);

  return (
    <Box
      sx={{
        backgroundColor: '#f5f7fa',
        height: '100%',
        width: '100%',
      }}
    >
      {!isPaymentStep && selectedModules ? (
        <ModulesStep
          modules={modules}
          setModules={setModules}
          setSelectedModules={setSelectedModules}
          selectedModules={selectedModules}
          numOfUsers={numOfUsers}
          setNumOfUsers={setNumOfUsers}
          setIsPaymentStep={setIsPaymentStep}
          perUser={perUser}
          setPerUser={setPerUser}
        />
      ) : (
        <PaymentStep selectedModules={selectedModules} />
      )}
    </Box>
  );
};

export default ModulesSubscription;
