import * as React from 'react';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Slide from '@mui/material/Slide';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, Grid, useMediaQuery } from '@mui/material';
import CustomTextButton from '../CustomButtons/CustomTextButton';

export default function SaveSlideBar({
  handleCancel,
  handleSave,
  setIsSaveBar,
  isSaveBar,
  type,
}) {
  const containerRef = React.useRef(null);
  const mediaQuery1250 = useMediaQuery('(min-width:1250px)');

  return (
    <Slide direction="up" in={isSaveBar} container={containerRef.current}>
      <Box
        sx={{
          height: 80,
          width: { md: 'calc(100% - 240px)', xs: '100%' },
          position: 'fixed',
          right: 0,
          bottom: 0,
          zIndex: 99999,
          display: 'flex',
          borderRadius: 1,
          justifyContent: 'end',
        }}
        ref={containerRef}
      >
        <Grid
          md={
            type === 'NOTIFICATIONS'
              ? 6
              : type === 'INVOICE'
              ? 12
              : mediaQuery1250
              ? 9.5
              : 8.5
          }
          sx={{
            width: '100%',
            bottom: 0,
            display: 'flex',
            overflow: 'hidden',
            justifyContent: 'end',
          }}
        >
          <Box
            sx={{
              width:
                type === 'NOTIFICATIONS'
                  ? { md: 'calc(100% - 66px)', xs: '100%' }
                  : { md: 'calc(100% - 64px)', xs: '100%' },
              mr:
                type === 'NOTIFICATIONS'
                  ? { md: '32px', xs: 0 }
                  : { md: '32px', xs: 0 },
              display: 'flex',
              backgroundColor: 'white',
              alignItems: 'center',
              border: '#EBEBEB solid 1px',
            }}
          >
            <CustomTextButton
              text={'Cancel'}
              onClick={handleCancel}
              sx={{
                marginLeft: { xs: 2, sm: 2 },
                mr: { xs: 2, sm: 0 },
              }}
            />
            <CustomTextButton
              text={'Save'}
              type={'blue'}
              onClick={handleSave}
              sx={{
                marginLeft: { xs: 2, sm: 2 },
                mr: { xs: 2, sm: 0 },
              }}
            />
          </Box>
        </Grid>
        {type === 'NOTIFICATIONS' && <Grid md={3}></Grid>}
      </Box>
    </Slide>
  );
}
