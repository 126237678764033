import { useDispatch, useSelector } from 'react-redux';
import { drawersActions } from '../../../redux/slices/drawers.slice';
import { useLayoutEffect, useState } from 'react';
import { addCustomer } from '../../../Api/tenant/customers';
import { editUserById } from '../../../Api/common/users';
import { crudTypes } from '../../../constants/crudTypes';
import countrydata from '../../Locations/Tabs/Countrydata.json';
import { selectUser } from '../../../redux/slices/user.slice.js';

const requiredFields = ['firstName', 'lastName', 'email'];

const useCustomerDrawer = ({ crudType, data, refetchData = null }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('+46');
  const [datevalue, setDateValue] = useState(null);
  const [image, setImage] = useState(null);
  const [errors, setErrors] = useState({});
  const { user } = useSelector(selectUser);
  const [formData, setFormData] = useState({
    firstName: null,
    lastName: null,
    email: null,
    gender: null,
    country: null,
    city: null,
    note: null,
  });
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);

  const toggleDrawer = () => {
    dispatch(drawersActions.closeDrawer());
  };

  const handleFormData = (fieldName, value) => {
    if (fieldName === 'country') {
      const getStates = countrydata.find(
        (country) => country.country_name === value
      ).states;
      setStates(getStates);
    }
    setFormData({
      ...formData,
      [fieldName]: typeof value === 'string' ? value.split(',') : value,
    });
  };

  const handelDate = (value) => {
    setDateValue(value);
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  useLayoutEffect(() => {
    if (crudType === crudTypes.EDIT) {
      setFormData({
        firstName: data.User.firstName,
        lastName: data.User.lastName,
        email: data.User.email,
        gender: data.User.gender,
        country: data.User.country,
        city: data.User.city,
        note: data.note,
      });
      setImage(data.User.image);
      setValue(data.User.phone || '+46');
      setDateValue(data.User.dob);
    }
  }, [
    crudType,
    data?.User?.city,
    data?.User?.country,
    data?.User?.dob,
    data?.User?.email,
    data?.User?.firstName,
    data?.User?.gender,
    data?.User?.image,
    data?.User?.lastName,
    data?.User?.phone,
    data?.note,
  ]);

  //  API

  const handleSubmit = () => {
    switch (crudType) {
      case crudTypes.ADD:
        handleAddCustomer();
        break;
      case crudTypes.EDIT:
        handleEditCustomer();
        break;
      default:
        break;
    }
  };

  const handleAddCustomer = () => {
    setLoading(true);
    let err = {};

    for (const key in formData) {
      if (!formData[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });

    if (Object.keys(err).length === 0) {
      const { firstName, lastName, city, country, email, gender, note } =
        formData;

      let formdata = new FormData();

      firstName && formdata.append('firstName', firstName);
      lastName && formdata.append('lastName', lastName);
      city && formdata.append('city', city);
      country && formdata.append('country', country);
      email && formdata.append('email', email);
      gender && formdata.append('gender', gender);
      value && formdata.append('phone', value);
      datevalue && formdata.append('dob', datevalue);
      formdata.append('type', 'customer');
      formdata.append('belongsTo', 'barbaros');
      formdata.append('active', 'true');
      note && formdata.append('note', note);
      image && formdata.append('image', image);
      formdata.append('userId', user?.id);

      addCustomer(formdata).then(
        (res) => {
          toggleDrawer();
          if (refetchData) refetchData();
          setLoading(false);
        },
        (rej) => {
          rej?.response.status === 409 &&
            setErrors({
              email: { message: 'Email already exists', isError: true },
            });
        }
      );
    }
    setLoading(false);
  };

  const handleEditCustomer = () => {
    setLoading(true);
    const { firstName, lastName, city, country, email, gender, note } =
      formData;
    let formdata = new FormData();

    firstName && formdata.append('firstName', firstName);
    lastName && formdata.append('lastName', lastName);
    city && formdata.append('city', city);
    country && formdata.append('country', country);
    email && formdata.append('email', email);
    gender && formdata.append('gender', gender);
    value.length > 4 && formdata.append('phone', value);
    datevalue && formdata.append('dob', datevalue);
    formdata.append('active', 'true');
    note && formdata.append('note', note);
    image && formdata.append('image', image);

    editUserById(formdata, data.UserId).then(
      (res) => {
        toggleDrawer();
        if (refetchData) refetchData();
        setLoading(false);
      },
      (rej) => {
        rej?.response.status === 409 &&
          setErrors({
            email: { message: 'Email already exists', isError: true },
          });
      }
    );
  };

  return {
    errors,
    states,
    loading,
    formData,
    datevalue,
    value,
    image,
    handelDate,
    handleFormData,
    handleChange,
    handleSubmit,
    toggleDrawer,
    setErrors,
    setImage,
  };
};

export default useCustomerDrawer;
