import {
  Box,
  Button,
  Checkbox,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import PriceFormatter from "../../../../utils/PriceFormatter";
import classes from "./Payment.module.css";

const tempInfo = {
  staff: "Mark S.borchert MD",
  location: "california",
  service: "tooth whitening",
  date: "10/5/2022 12:50",
};

const CouponTab = () => {
  const [coupon, setCoupon] = useState("");
  const [error, setError] = useState("");
  const findCoupon = false;
  const total = 100000;
  const handleSubmit = async (e) => {};
  const handelChnage = (e) => {
    setCoupon(e.target.value);
    setError("");
  };
  return (
    <Box>
      <Box marginY="15px">
        <form onSubmit={handleSubmit}>
          <TextField
            placeholder="Coupon Code"
            onChange={handelChnage}
            value={coupon}
            sx={{ width: { md: "45%", xs: "100%" } }}
            error={!!error}
            helperText={error ? error : ""}
            inputProps={{
              style: {
                height: "45px",
                padding: "0 10px",
              },
            }}
          />
          <Button
            variant="contained"
            sx={{
              width: "15%",
              height: "45px",
              marginLeft: { xs: "0", md: "18px" },
              marginTop: { xs: "20px", md: "0" },
              padding: { xs: "10px", md: "14px" },
            }}
            type="submit"
          >
            Okay
          </Button>
        </form>
        {findCoupon && (
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ margin: "1rem 0", borderBottom: "1px solid #ddd" }}
          >
            <Box marginY="7px">
              <Typography
                color="gray"
                variant="p"
                sx={{ textTransform: "uppercase" }}
                fontWeight="400"
                fontSize="16px"
              >
                {Object.keys(findCoupon)}
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{ fontSize: "16px", color: "#4B64E9" }}
              >
                ${findCoupon.coupon}
              </Typography>
            </Box>
          </Box>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ margin: "1rem 0", borderBottom: "1px solid #ddd" }}
        >
          <Box marginY="7px">
            <Typography
              color="gray"
              variant="p"
              sx={{ textTransform: "uppercase" }}
              fontWeight="400"
              fontSize="16px"
            >
              Total Price
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "16px", color: "#4B64E9" }}
            >
              {PriceFormatter.format(total)}
            </Typography>
          </Box>
          <Box marginY="7px" textAlign="end">
            <Typography
              color="gray"
              variant="p"
              sx={{ textTransform: "uppercase" }}
              fontWeight="400"
              fontSize="16px"
            >
              Tax
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "16px", color: "#4B64E9" }}
            >
              {PriceFormatter.format(1000)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const GiftcardTab = () => {
  const [coupon, setCoupon] = useState("");
  const [error, setError] = useState("");
  const findCoupon = false;
  const total = 100000;
  const handleSubmit = async (e) => {};
  const handelChnage = (e) => {
    setCoupon(e.target.value);
    setError("");
  };
  return (
    <Box>
      <Box marginY="15px">
        <form onSubmit={handleSubmit}>
          <TextField
            placeholder="Giftcard Code"
            onChange={handelChnage}
            value={coupon}
            sx={{ width: { md: "45%", xs: "100%" } }}
            error={!!error}
            helperText={error ? error : ""}
            inputProps={{
              style: {
                height: "45px",
                padding: "0 10px",
              },
            }}
          />
          <Button
            variant="contained"
            sx={{
              width: "15%",
              height: "45px",
              marginLeft: { xs: "0", md: "18px" },
              marginTop: { xs: "20px", md: "0" },
              padding: { xs: "10px", md: "14px" },
            }}
            type="submit"
          >
            Okay
          </Button>
        </form>
        {findCoupon && (
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ margin: "1rem 0", borderBottom: "1px solid #ddd" }}
          >
            <Box marginY="7px">
              <Typography
                color="gray"
                variant="p"
                sx={{ textTransform: "uppercase" }}
                fontWeight="400"
                fontSize="16px"
              >
                {Object.keys(findCoupon)}
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{ fontSize: "16px", color: "#4B64E9" }}
              >
                ${findCoupon.coupon}
              </Typography>
            </Box>
          </Box>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ margin: "1rem 0", borderBottom: "1px solid #ddd" }}
        >
          <Box marginY="7px">
            <Typography
              color="gray"
              variant="p"
              sx={{ textTransform: "uppercase" }}
              fontWeight="400"
              fontSize="16px"
            >
              Total Price
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "16px", color: "#4B64E9" }}
            >
              {PriceFormatter.format(total)}
            </Typography>
          </Box>
          <Box marginY="7px" textAlign="end">
            <Typography
              color="gray"
              variant="p"
              sx={{ textTransform: "uppercase" }}
              fontWeight="400"
              fontSize="16px"
            >
              Tax
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "16px", color: "#4B64E9" }}
            >
              {PriceFormatter.format(1000)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SummrayTab = ({drawerData}) => {
  const infoList = [
    {
      id: 1,
      option: `${drawerData?.location?.address || '-'}, ${drawerData?.location?.name || "-"}`,
      type: "location",
    },
    {
      id: 2,
      option: `${drawerData?.staff?.User?.firstName || "-"} ${drawerData?.staff?.User?.lastName || "-"}`,
      type: "Staff",
    },
    {
      id: 3,
      subId: 4,
      option: `${drawerData?.service?.name || '-'}`,
      type: "service",
      price: 200,
    },
    {
      id: 4,
      option: "-",
      type: "Extra Service",
      price: 500,
    },
    {
      id: 5,
      date: drawerData?.date || '-',
      time: drawerData?.time || "-",
      type: "Date",
    },
    {
      id: 6,
      people: typeof drawerData?.service?.capacity === "number" ? drawerData?.service?.capacity  : "-",
      type: "people",
    },
  ];
  const CouponData = { coupon: 100 };
  const giftCardData = { amount: 1000 };
  const total = 100;
  const dateData = infoList.find((info) => info.type === "Date");
  const date = dateData.date;
  const year = date.split("-")[2];
  const month = date.split("-")[1];
  const day = date.split("-")[0];
  return (
    <>
      {infoList.map((info) => (
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ borderBottom: "1px solid #ddd" }}
          key={info.id}
        >
          <Box marginY="7px">
            <Typography
              color="gray"
              variant="p"
              sx={{ textTransform: "uppercase" }}
              fontWeight="400"
              fontSize="16px"
            >
              {info.type}
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "16px", color: "#4B64E9" }}
            >
              {info.option
                ? info.option
                : info.date
                ? `${year}-${month}-${day}`
                : info.discount
                ? info.discount
                : info.balance
                ? info.balance
                : info.people}
              {info.discount ? "$" : ""}
            </Typography>
          </Box>
          <Box marginY="7px" textAlign="end">
            <Typography
              color="gray"
              variant="p"
              sx={{ textTransform: "uppercase" }}
              fontWeight="400"
              fontSize="16px"
              textAlign="end"
            >
              {info.price
                ? "Price"
                : info.date
                ? "Time"
                : info.type === "people"
                ? ""
                : ""}
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "16px", color: "#4B64E9" }}
            >
              {info.price ? "$" : ""}
              {info.price
                ? info.price
                : info.date
                ? info.time
                : info.type === "people"
                ? info.quantity
                : ""}
            </Typography>
          </Box>
        </Box>
      ))}
      {CouponData || giftCardData ? (
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ margin: "1rem 0", borderBottom: "1px solid #ddd" }}
        >
          {CouponData ? (
            <Box marginY="7px">
              <Typography
                color="gray"
                variant="p"
                sx={{ textTransform: "uppercase" }}
                fontWeight="400"
                fontSize="16px"
              >
                COUPON
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{ fontSize: "16px", color: "#4B64E9" }}
              >
                ${CouponData?.coupon}
              </Typography>
            </Box>
          ) : (
            ""
          )}
          {giftCardData ? (
            <Box marginY="7px" textAlign={!CouponData ? "" : "end"}>
              <Typography
                color="gray"
                variant="p"
                sx={{ textTransform: "uppercase" }}
                fontWeight="400"
                fontSize="16px"
              >
                Gift Card
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{ fontSize: "16px", color: "#4B64E9" }}
              >
                ${giftCardData.amount}
              </Typography>
            </Box>
          ) : (
            ""
          )}
        </Box>
      ) : (
        ""
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ margin: "1rem 0", borderBottom: "1px solid #ddd" }}
      >
        <Box marginY="7px">
          <Typography
            color="gray"
            variant="p"
            sx={{ textTransform: "uppercase" }}
            fontWeight="400"
            fontSize="16px"
          >
            Total Price
          </Typography>
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ fontSize: "16px", color: "#4B64E9" }}
          >
            ${PriceFormatter.format(total)}
          </Typography>
        </Box>
        <Box marginY="7px" textAlign="end">
          <Typography
            color="gray"
            variant="p"
            sx={{ textTransform: "uppercase" }}
            fontWeight="400"
            fontSize="16px"
          >
            Vat
          </Typography>
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ fontSize: "16px", color: "#4B64E9" }}
          >
            ${PriceFormatter.format(10)}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

function Payment({drawerData}) {
  const [activeTab, setActiveTab] = useState("SUMMRAY");

  const handelConfirm = () => {
    // navigate("/confirm");
  };
  return (
    <Box>
      <Grid
        sx={{
          boxShadow: 3,
          margin: "30px",
          padding: "30px",
          display: "flex",
          flexWrap: "wrap",
        }}
        xs={12}
      >
        <Grid
          item
          xs={12}
          md={3}
          sx={{ display: { xs: "flex", md: "inline", textAlign: "center" } }}
        >
          <Grid xs={4} md={12}>
            <Typography
              variant="h3"
              fontSize="16px"
              fontWeight="500"
              marginBottom="5px"
            >
              Staff
            </Typography>
          </Grid>
          <Typography variant="p" fontSize="14px" color="#6c70dc">
            {drawerData?.staff?.User.firstName || "-"} {drawerData?.staff?.User.lastName  || "-"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{ display: { xs: "flex", md: "inline" }, textAlign: "center" }}
        >
          <Grid xs={4} md={12}>
            <Typography
              variant="h3"
              fontSize="16px"
              fontWeight="500"
              marginBottom="5px"
            >
              Location
            </Typography>
          </Grid>
          <Typography variant="p" fontSize="14px" color="#828F9A">
          {drawerData?.location?.name || "-"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{ display: { xs: "flex", md: "inline" }, textAlign: "center" }}
        >
          <Grid xs={4} md={12}>
            <Typography
              variant="h3"
              fontSize="16px"
              fontWeight="500"
              marginBottom="5px"
            >
              Service
            </Typography>
          </Grid>
          <Typography variant="p" fontSize="14px" color="#828F9A">
          {drawerData?.service?.name || '-'}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{ display: { xs: "flex", md: "inline" }, textAlign: "center" }}
        >
          <Grid xs={4} md={12}>
            <Typography
              variant="h3"
              fontSize="16px"
              fontWeight="500"
              marginBottom="5px"
            >
              Date
            </Typography>
          </Grid>
          <Typography variant="p" fontSize="14px" color="#828F9A">
          {drawerData?.date || '-'} <span style={{display: "block"}}>{drawerData?.time || "-"}</span>
          </Typography>
        </Grid>
      </Grid>
      {/* tabs */}
      <Grid xs={12} className={classes.tabs__bar}>
        <Box
          className={classes.tab}
          borderBottom={activeTab === "SUMMRAY" && "4px solid #4B64E9"}
          onClick={() => {
            setActiveTab("SUMMRAY");
          }}
        >
          Summary
        </Box>
        <Box
          className={classes.tab}
          borderBottom={activeTab === "COUPON" && "4px solid #4B64E9"}
          onClick={() => {
            setActiveTab("COUPON");
          }}
        >
          Coupon
        </Box>
        <Box
          className={classes.tab}
          borderBottom={activeTab === "GIFTCARD" && "4px solid #4B64E9"}
          onClick={() => {
            setActiveTab("GIFTCARD");
          }}
        >
          Giftcard
        </Box>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {activeTab === "COUPON" && <CouponTab />}
        {activeTab === "SUMMRAY" && <SummrayTab drawerData={drawerData} />}
        {activeTab === "GIFTCARD" && <GiftcardTab />}

        <Box
          sx={{
            padding: "0 31px",
            display: "flex",
            flexDirection: "column",
            mt: 5,
          }}
        >
          <Box display="flex" alignItems="center">
            <Checkbox name="gilad" sx={{ padding: "0", margin: "10px 0" }} />
            <Typography sx={{ marginX: "10px" }}>
              I accept the <Link>terms & conditon</Link> of Upnize
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "11px",
              width: { xs: "100%", md: "47%", sm: "40%" },
              alignItems: "end",
            }}
          >
            <Button
              fullWidth
              variant="contained"
              sx={{
                fontSize: "22px",
                fontWeight: "bold",
                background: "#6373BC",
                marginTop: { xs: "1rem", md: "0.2rem" },

                width: "100%",
              }}
              onClick={handelConfirm}
            >
              Stripe
            </Button>
            <Button
              fullWidth
              variant="contained"
              sx={{
                fontSize: "22px",
                fontWeight: "bold",
                background: "#657BEF",
                marginTop: "1rem",
                width: "100%",
              }}
              onClick={handelConfirm}
            >
              invoice
            </Button>
            <Button
              fullWidth
              onClick={handelConfirm}
              variant="contained"
              sx={{
                fontSize: "22px",
                fontWeight: "bold",
                background: "#585858",
                marginTop: "1rem",
                width: "100%",
              }}
            >
              Paylocal
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Payment;
