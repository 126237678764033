import React, { useReducer } from 'react';

export const defaultContext = {
  user: { type: null },
  setUser: (data) => {},
};

let currentUserPreferences = localStorage.getItem('userPreferences');
let parsedCurrentUserPreferences = JSON.parse(currentUserPreferences);

const userPreferences = {
  ...defaultContext,
  user: parsedCurrentUserPreferences
    ? parsedCurrentUserPreferences
    : defaultContext.user,
};

export const Context = React.createContext(userPreferences);

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      localStorage.setItem('userPreferences', JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
      };

    default:
      return state;
  }
};

export const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, userPreferences);

  return (
    <Context.Provider
      value={{
        ...state,
        setUser: (data) => {
          dispatch({ type: 'SET_USER', payload: data });
          return data;
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};
