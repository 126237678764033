import { GridFooter, GridFooterContainer } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';

const CustomTableFooter = ({ selectionModel, handleDeleteOpenModal }) => {
  return (
    <GridFooterContainer>
      {/* Add what you want here */}
      {selectionModel.length > 0 && (
        <Button
          sx={{
            marginLeft: '10px',
            backgroundColor: '#c63f44',
            color: 'white',
            ':hover': { backgroundColor: '#892a30', width: '10px' },
          }}
          onClick={() => {
            handleDeleteOpenModal(selectionModel);
          }}
        >
          <DeleteIcon />
        </Button>
      )}
      <GridFooter
        sx={{
          border: 'none', // To delete double border.
          width: '100%',
        }}
      />
    </GridFooterContainer>
  );
};

export default CustomTableFooter;
