import { getLocationList } from '../../../Api/tenant/locations';
import { getCategoryList } from '../../../Api/tenant/categories';
import {
  addService,
  addSubService,
  editServiceById,
  getServiceById,
} from '../../../Api/tenant/services';
import { getStaffList } from '../../../Api/tenant/staff';
import { crudTypes } from '../../../constants/crudTypes';
import { dayList } from '../../../constants/dayList';
import { fixedDurationOptions } from '../../../constants/fixedDurationOptions';
import { drawersActions } from '../../../redux/slices/drawers.slice';
import { selectUser } from '../../../redux/slices/user.slice.js';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const requiredFields = [
  'name',
  'categoryId',
  'price',
  'duration',
  'slotLength',
  'deposit',
  'capacity',
];

const useServiceDrawer = ({ crudType, data, refetchData = null }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(selectUser);
  //  service details tab
  const [image, setImage] = useState(null);
  const [serviceData, setServiceData] = useState({
    name: null,
    categoryId: null,
    price: null,
    duration: null,
    slotLength: null,
    BTB: null,
    BTA: null,
    note: '',
    enableDeposit: false,
    deposit: 20,
    depositType: true,
    hideDur: false,
    hidePrice: false,
    capacity: 1,
    recurring: false,
    isFullPeriod: false,
    fixedPeriod: '3', // delete after fn is complete
    fixedPeriodType: 'week', // delete
    isFixedFrequency: false,
    fixedFreq: '2', // delete
    // delete
    repeat: false, //same as recurring
    payment: true,
  });
  const [durationOptions, setDurationOptions] = useState([]);

  // linked staff - staff tab
  const [selectedStaff, setSelectedStaff] = useState([]);

  // linked locations - location tab
  const [locations, setLocations] = useState([]);

  // timesheet tab
  const [timesheets, setTimesheets] = useState(dayList);
  // Marking service to have seperate timesheet enabled
  const [spts, setSpts] = useState(false);

  // extraService tab
  const [extraService, setExtraService] = useState([]);

  // error handling
  const [errors, setErrors] = useState({});

  const toggleDrawer = () => {
    dispatch(drawersActions.closeDrawer());
  };

  const handelHidedata = () => {
    //handelHide(data.id);
    toggleDrawer();
  };

  const fetchServiceData = useCallback(async () => {
    try {
      const response = await getServiceById(data?.id);
      if (response.status === 200) {
        const result = response.data;
        setTimesheets(result.timesheets);
        setSpts(result.spts);
        setServiceData(result);
        setSelectedStaff(result.staff);
        setLocations(result.locations);
        setImage(result.image);
      }
    } catch (err) {
      console.log(err);
    }
  }, [data?.id]);

  // API

  const calculateDurationOptions = useCallback(() => {
    let date = new Date('August 19, 1975 00:00:00');
    let minutesToAdd = user?.settings?.GeneralSettings?.timeSlotLength;
    let endDate = new Date(date.getTime());

    let result = [];
    // calculating dybamic duration options based on timeslot length in user settings
    while (endDate.getDate() === date.getDate()) {
      let hours = endDate.getHours();
      let minutes = endDate.getMinutes();
      result.push({
        label: hours + 'h' + ' ' + minutes + 'm',
        duration: hours * 60 + minutes,
      });
      endDate = new Date(endDate.getTime() + minutesToAdd * 60000);
    }

    // then adding fixed options
    result.push(...fixedDurationOptions);

    setDurationOptions(result);
  }, [user?.settings?.GeneralSettings?.timeSlotLength]);

  const fetchData = useCallback(async () => {
    try {
      if (crudType === crudTypes.ADD || crudType === crudTypes.EDIT) {
        // preselecting all staff and locations
        getStaffList(1000, 1).then((res) => {
          if (res.data?.rows) {
            setSelectedStaff(res.data.rows.map((st) => ({ id: st.id })));
          }
        });
        getLocationList(1000, 1).then((res) => {
          if (res.data?.rows) {
            setLocations(res.data.rows.map((loc) => ({ id: loc.id })));
          }
        });

        // setting default timeslot (from settings)
        setServiceData((prev) => ({
          ...prev,
          slotLength: 'default',
        }));

        // get duration options based on time slot length
        calculateDurationOptions();
      }

      if (crudType === crudTypes.EDIT && data?.id) {
        fetchServiceData();
      }
    } catch (err) {
      console.log(err);
    }
  }, [calculateDurationOptions, crudType]);

  useLayoutEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAddExtra = () => {
    let extraArrayPromises = [];

    extraService.map(
      ({
        serviceName,
        image,
        maxQuantity,
        minQuantity,
        isEnabled,
        price,
        hidePrice,
        hideDuration,
        duration,
      }) => {
        const promise = addSubService({
          duration,
          hideDur: hideDuration,
          hidePrice,
          image,
          isEnabled,
          maxQty: maxQuantity,
          minQty: minQuantity,
          price,
          name: serviceName,
        });
        extraArrayPromises = [...extraArrayPromises, promise];
      }
    );

    return extraArrayPromises;
  };

  // adding service logic
  const handleAddService = async () => {
    // error/validation handling
    let err = {};
    let data = { ...serviceData };

    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    // timesheet validation

    setErrors({ ...err });

    // request logic

    if (Object.keys(err).length === 0) {
      // add extras
      // format "id-id-id..."
      const extraArrayPromises = handleAddExtra();
      const extraServiceIds = await Promise.all(extraArrayPromises).then(
        (resArray) => resArray.map((res) => res.data['Service Id'])
      );
      const extraId = extraServiceIds.join('-');
      console.log(extraId);

      // add timesheet

      const specificStaff = selectedStaff.map((staff) => {
        return {
          id: staff.id,
          isSpecificPrice: staff.isSpecificPrice,
          price: staff.price,
          priceType: staff.priceType,
          deposit: staff.deposit,
        };
      });

      // service body
      let formData = new FormData();
      for (const el in serviceData) {
        if (el === 'slotLength' && serviceData[el] === 'duration') {
          formData.append(el, serviceData['duration']);
        } else if (serviceData[el] === 'default') {
          formData.append(el, user?.settings?.GeneralSettings?.timeSlotLength);
        } else {
          formData.append(el, serviceData[el]);
        }
      }
      extraId && formData.append('subServiceId', extraId);
      formData.append('specificStaff', JSON.stringify(specificStaff));

      formData.append('timesheets', JSON.stringify(timesheets));

      selectedStaff.map((st) => {
        formData.append('staff', JSON.stringify(st));
      });
      locations.map((lc) => {
        formData.append('locations', JSON.stringify(lc));
      });
      formData.append('spts', spts);
      formData.append('image', image);
      formData.append('userId', user?.id);
      formData.append('visible', true);
      // formData.append('capacity', serviceData.capacity);
      // formData.append('BTA', serviceData.BTA);
      // formData.append('BTB', serviceData.BTB);
      // formData.append('slotLength', serviceData.slotLength);

      for (var pair of formData.entries()) {
        console.log(pair[0], pair[1]);
      }

      // calling api
      addService(formData).then((res) => {
        if (res.status === 200) {
          toggleDrawer();
          if (refetchData) refetchData();
        }
      });
    }
  };

  // editing service
  const handleEditService = async () => {
    // add extras
    // format "id-id-id..."

    // const extraArrayPromises = handleAddExtra();
    // const extraServiceIds = await Promise.all(extraArrayPromises).then(
    //   (resArray) => resArray.map((res) => res.data["Service Id"])
    // );
    // const extraId = extraServiceIds.join("-");
    // console.log(extraId);

    // staff members for the service
    const specificStaff = selectedStaff.map((staff) => {
      return {
        id: staff.id,
        isSpecificPrice: staff.isSpecificPrice,
        deposit: staff.deposit,
        priceType: staff.priceType,
      };
    });

    // service body
    let formData = new FormData();
    for (const el in serviceData) {
      if (el == 'spts') {
        formData.append('spts', spts);
        continue;
      }

      if (
        el !== 'timesheets' &&
        el !== 'locations' &&
        el !== 'staff' &&
        el !== 'image'
      ) {
        formData.append(el, serviceData[el]);
      }
    }

    // extra service
    // extraId && formData.append("subServiceId", extraId);

    image && formData.append('image', image);

    const newTimesheets = timesheets.map((timesheet) => {
      timesheet.breaks = timesheet.breaks.map((breakTime) => {
        if (breakTime?.id?.toString().includes('breaks')) delete breakTime.id;
        return breakTime;
      });
      return timesheet;
    });

    // staff and locations links
    selectedStaff.map((st) => {
      formData.append('staff', JSON.stringify(st));
    });
    locations.map((lc) => {
      formData.append('locations', JSON.stringify(lc));
    });

    // timesheet
    // formData.set("timesheetId", timesheetId);
    formData.append('timesheets', JSON.stringify(newTimesheets));

    // calling api
    editServiceById(formData, data.id).then((res) => {
      if (res.status === 200) {
        if (refetchData) refetchData();
        toggleDrawer();
      }
    });
  };

  const handleSubmit = () => {
    switch (crudType) {
      case crudTypes.ADD:
        handleAddService();
        break;
      case crudTypes.EDIT:
        handleEditService();
        break;
      default:
        break;
    }
  };

  return {
    durationOptions,
    errors,
    serviceData,
    image,
    extraService,
    selectedStaff,
    timesheets,
    spts,
    locations,
    toggleDrawer,
    setLocations,
    setImage,
    setSelectedStaff,
    setTimesheets,
    setSpts,
    setServiceData,
    setExtraService,
    setErrors,
    handleSubmit,
  };
};

export default useServiceDrawer;
