import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const CustomSettingsTitle = ({ title, subTitle }) => {
  return (
    <Typography
      sx={{
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
        // marginTop: 2.5,
        // marginBottom: 1,
        fontSize: 24,
        color: '#3A3A50',
      }}
    >
      {title}
      <KeyboardArrowRightIcon sx={{ marginX: '3px' }} />{' '}
      <span
        style={{
          // color: '#c1c1c1',
          color: '#1976d2',
        }}
      >
        {subTitle}
      </span>
    </Typography>
  );
};

export default CustomSettingsTitle;
