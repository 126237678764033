import { UpnizeBackend } from '../Axios';

export const getSettings = async () => {
  let response = await UpnizeBackend.get(`/settings`);
  console.log(response.data);
  return response;
};

export const editSettings = async (body) => {
  let response = await UpnizeBackend.put(`/settings/edit`, body);
  console.log(response.data);
  return response;
};

export const editCDetails = async (body) => {
  let response = await UpnizeBackend.put(`/cDetails/edit`, body);
  console.log(response.data);
  return response;
};
