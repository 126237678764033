import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  DataGrid,
  GridActionsCellItem,
  GridMoreVertIcon,
  gridClasses,
} from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import CustomTableFooter from '../../components/CustomTableFooter';
import Modal from '../../components/Modal';
import AddTax from './AddTax';
import UsageHistory from '../../components/UsageHistory';
import StatusChip from '../../components/StatusChip';
import { deleteTaxById, editTaxById, getTaxList } from '../../Api/tenant/taxes';
import { Box } from '@mui/system';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import { defaultPageSizeOptions } from '../../constants';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CustomTooltip from '../../components/CustomTooltip';
import styled from '@emotion/styled';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useFilterBar from '../../hooks/useFilterBar';
import { getQueries } from '../../utils/GetQueries';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useMediaQuery, useTheme } from '@mui/material';
import NoData from '../../components/NoData';
import { useTranslation } from 'react-i18next';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    color: theme.palette.grey[500],
  },
}));

const filters = [
  {
    id: 1,
    title: 'Quick search',
    type: 'search',
    queryLabel: 'keyword',
  },
];

const Tax = () => {
  const [addTaxDrawer, setAddTaxDrawer] = useState(false);
  const [taxes, setTaxes] = useState();
  const [rows, setRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [drawerType, setDrawerType] = useState('add');
  const [drawerData, setDrawerData] = useState(null);
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);
  const [drawer, setDrawer] = useState(null);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('lg'));
  const { FilterBar, state, FilterButton } = useFilterBar({
    filters,
  });

  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  const { t } = useTranslation(['tax', 'common']);

  const toggleAddTaxDrawer = () => {
    setAddTaxDrawer(!addTaxDrawer);
  };

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  const handleDeleteOpenModal = () => {
    setOpenModal({ open: true, type: 'delete-modal' });
  };

  const toggleUsageHistoryModal = (e) => {
    e.stopPropagation();
    setDrawer((prevState) =>
      prevState !== 'usage-history' ? 'usage-history' : ''
    );
  };

  // API

  const fetchTaxes = () => {
    setTableLoading(true);
    getTaxList(pageSize, pageCount).then((res) => {
      setTaxes(res.data);
    });
  };

  // fetch on render
  useEffect(() => {
    fetchTaxes();
  }, []);

  // fetch on search
  useEffect(() => {
    getQueries(state).then((queries) => {
      setTableLoading(true);
      getTaxList(pageSize, pageCount, queries).then((res) => {
        setTaxes(res.data);
      });
    });
  }, [state, pageSize, pageCount]);

  // update table rows on fetch
  useEffect(() => {
    if (taxes) {
      setRows(taxes);
      setTableLoading(false);
    }
  }, [taxes]);

  const deleteTax = (id) => {
    deleteTaxById(id).then((res) => {
      fetchTaxes();
    });
  };

  const columns = [
    {
      field: 'name',
      headerName: t('common:tableHeaders.name'),
      flex: 1,
      minWidth: 230,
    },
    {
      field: 'value',
      headerName: t('common:tableHeaders.amount'),
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'location',
      headerName: t('common:tableHeaders.locations'),
      flex: 1,
      minWidth: 250,
      renderCell: (params) => (
        <>
          {params.row.location.map((loc) => {
            return (
              <Box
                sx={{
                  color: 'white',
                  backgroundColor: '#3E79CB',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '25px',
                  fontSize: '14px',
                  padding: '0 .8rem',
                  minWidth: '24px',
                  lineHeight: '14px',
                  borderRadius: '15px',
                  width: 'fit',
                  ml: 1,
                }}
              >
                {loc?.name}
              </Box>
            );
          })}
        </>
      ),
    },
    {
      field: 'enabled',
      headerName: t('common:tableHeaders.status'),
      type: 'singleSelect',
      valueOptions: ['Enabled', 'Disabled'],
      flex: 1,
      minWidth: 180,
      renderCell: (params) => (
        <>
          {params.value ? (
            <StatusChip
              text={'Enabled'}
              color={'#FFFFFF'}
              bgColor={'#25D08E'}
              rounded={true}
            />
          ) : (
            <StatusChip
              text={'Disabled'}
              color={'#FFFFFF'}
              bgColor={'#FF6C6C'}
              rounded={true}
            />
          )}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: t('common:tableHeaders.actions'),
      type: 'actions',
      align: 'right',
      minWidth: 100,
      maxWidth: 100,
      getActions: (params) => [
        <GridActionsCellItem
          label="hide"
          icon={
            params.row.visible ? (
              <CustomTooltip
                arrow
                placement="top"
                title="Disable item may not be available in other pages and network"
              >
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    padding: '3px 5px',
                    borderRadius: '5px',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                  }}
                >
                  <VisibilityIcon />
                </Box>
              </CustomTooltip>
            ) : (
              <Box
                sx={{
                  backgroundColor: '#fff',
                  padding: '3px 5px',
                  borderRadius: '5px',
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                }}
              >
                <VisibilityOffIcon />
              </Box>
            )
          }
          onClick={() =>
            handelHideRow(
              {
                visible: !params.row.visible,
              },
              params.row.id
            )
          }
        />,
        <GridActionsCellItem
          label="Edit"
          onClick={() => {
            setDrawerType('edit');
            setDrawerData(params.row);
            toggleAddTaxDrawer();
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => {
            setOpenModal({ open: true, type: 'delete-modal', id: params.id });
          }}
          showInMenu
        />,
      ],
    },
  ];

  const handelHideRow = async (body, id) => {
    await editTaxById(body, id);
    fetchTaxes();
  };

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        px: '20px',
      }}
    >
      {openModal.type === 'delete-modal' && (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'DELETE'}
          open={openModal.open}
          handleDelete={deleteTax}
          id={openModal.id}
        />
      )}
      {addTaxDrawer && (
        <AddTax
          toggleAddTaxDrawer={toggleAddTaxDrawer}
          drawerType={drawerType}
          drawerData={drawerData}
          fetchTaxes={fetchTaxes}
        />
      )}
      {drawer === 'usage-history' && (
        <UsageHistory toggleDrawer={toggleUsageHistoryModal} />
      )}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          // alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          sx={{
            marginTop: 2.5,
            marginBottom: 1,
            fontSize: 24,
            color: '#3A3A50',
          }}
        >
          {t('title')}
        </Typography>
      </Grid>{' '}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <CustomTextButton
          text={'+ ' + t('addTaxBtn')}
          onClick={() => {
            setDrawerType('add');
            toggleAddTaxDrawer();
          }}
        />
        <Box sx={{ display: 'flex', gap: '10px', ml: '10px' }}>
          {FilterButton}
        </Box>
      </Box>
      {/* --------------------SEARCH------------------------------- */}
      {FilterBar}
      {/* --------------------TABLE--------------------------------- */}
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ marginTop: '20px' }}
      >
        <div style={{ width: '100%' }}>
          <StripedDataGrid
            sx={{
              border: 0,
              '& .MuiDataGrid-virtualScroller': {
                minHeight: '300px !important',
              },
              minHeight: '60vh',
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: '#EFF1FC',
                color: '#3A3A50',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px',
                fontWeight: '900 !important',
              },
              '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
                border: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
            rows={rows}
            columns={columns}
            // checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            loading={tableLoading}
            autoHeight
            getRowClassName={(params) => {
              return !params.row.visible ? 'even' : 'odd';
            }}
            selectionModel={selectionModel}
            rowCount={1000}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
            }}
            rowsPerPageOptions={defaultPageSizeOptions}
            page={pageCount - 1}
            onPageChange={(newPage) => {
              setPageCount(newPage + 1);
            }}
            paginationMode="server"
            components={{
              NoRowsOverlay: NoData,
              NoResultsOverlay: NoData,
              MoreActionsIcon: () => (
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    padding: '3px 5px',
                    borderRadius: '5px',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                  }}
                >
                  <MoreVertIcon sx={{}} />
                </Box>
              ),
              Footer: () => (
                <CustomTableFooter
                  selectionModel={selectionModel}
                  handleDeleteOpenModal={handleDeleteOpenModal}
                />
              ),
              MoreActionsIcon: () => (
                <GridMoreVertIcon
                  sx={{
                    backgroundColor: 'white',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                    borderRadius: '4px',
                    padding: '3px',
                    cursor: 'pointer',
                  }}
                />
              ),
              NoRowsOverlay: () => {
                return (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'Center',
                      gap: '20px',
                    }}
                  >
                    <img
                      src="https://user-images.githubusercontent.com/109676648/254145031-57027cd6-fecd-4ce2-96fc-9d891c8de53a.svg"
                      alt="no data"
                      style={{
                        width: '100px',
                        height: '100px',
                      }}
                    />
                    No data at the moment 😔
                  </Box>
                );
              },
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Tax;
