import React, { useLayoutEffect } from 'react';
import { FormControlLabel, TextareaAutosize, Typography } from '@mui/material';
import ProfileImageField from '../../../../components/ProfileImageField';
import Grid from '@mui/material/Grid';
import CustomTextField from '../../../../components/CustomTextField';
import CustomMuiTelInput from '../../../../components/CustomMuiTelInput';
import { IOSSwitch } from '../../../../components/CustomCheckbox';
import { useTranslation } from 'react-i18next';

const StaffDetails = ({
  staffData,
  setStaffData,
  image,
  setImage,
  drawerType,
  errors,
  setErrors,
}) => {
  const { t } = useTranslation(['staff', 'common']);

  const handleStaffDataChange = (fieldName, value) => {
    setStaffData((prev) => {
      return {
        ...prev,
        [fieldName]: value,
      };
    });
  };

  useLayoutEffect(() => {
    if (drawerType === 'edit') {
      setImage(staffData?.User?.image);
    }
  }, [staffData]);

  return (
    <Grid container>
      <Grid container>
        <Grid xs={12} sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}>
          <ProfileImageField image={image} setImage={setImage} />
        </Grid>
      </Grid>
      <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography variant="subtitle2">
            {t('staff:drawer.staffDetails.firstName')}{' '}
            <span style={{ color: 'red' }}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            size="small"
            variant="outlined"
            sx={{
              width: '100%',
              fontSize: '14px',
            }}
            value={staffData.firstName}
            onChange={(e) => {
              handleStaffDataChange('firstName', e.target.value);
              setErrors({ ...errors, firstName: false });
            }}
            error={errors.firstName}
            helperText={errors?.firstName ? 'Value is missing' : ' '}
          />
        </Grid>
      </Grid>
      <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 } }}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography variant="subtitle2">
            {t('staff:drawer.staffDetails.lastName')}{' '}
            <span style={{ color: 'red' }}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            size="small"
            fullWidth
            value={staffData.lastName}
            onChange={(e) => {
              handleStaffDataChange('lastName', e.target.value);
              setErrors({ ...errors, lastName: false });
            }}
            error={errors.lastName}
            helperText={errors?.lastName ? 'Value is missing' : ' '}
          />
        </Grid>
      </Grid>

      <Grid xs={12} sm={12}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography variant="subtitle2">
            {t('staff:drawer.staffDetails.profession')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            size="small"
            variant="outlined"
            sx={{
              width: '100%',
              fontSize: '14px',
            }}
            onChange={(e) => {
              handleStaffDataChange('profession', e.target.value);
              setErrors({ ...errors, profession: false });
            }}
            value={staffData.profession}
            error={errors.profession}
            helperText={errors.profession ? 'Value is missing' : ' '}
          />
        </Grid>
      </Grid>
      <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography variant="subtitle2">
            {t('staff:drawer.staffDetails.email')}{' '}
            <span style={{ color: 'red' }}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            size="small"
            variant="outlined"
            sx={{
              width: '100%',
              fontSize: '14px',
            }}
            type="email"
            placeholder="example@gmail.com"
            value={staffData.email}
            onChange={(e) => {
              handleStaffDataChange('email', e.target.value);
            }}
            error={errors.email}
            helperText={errors?.email ? 'Value is missing' : ' '}
          />
        </Grid>
      </Grid>

      <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 } }}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography variant="subtitle2">
            {t('staff:drawer.staffDetails.phone')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomMuiTelInput
            InputProps={{
              style: {
                height: '40px',
                padding: '0 10px',
                width: '100%',
                marginRight: '6rem',
              },
            }}
            sx={{ width: '100%' }}
            value={staffData?.phone}
            onChange={(e) => {
              handleStaffDataChange('phone', e);
            }}
            error={errors.phone}
            helperText={errors?.phone ? 'Value is missing' : ' '}
          />
        </Grid>
      </Grid>
      <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
        <Grid
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            my: 3,
            height: 40,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">
            {t('staff:drawer.staffDetails.allowToLogIn')}
          </Typography>
          <FormControlLabel
            control={
              <IOSSwitch
                checked={staffData.active}
                onChange={(e) => {
                  handleStaffDataChange('active', e.target.checked);
                }}
              />
            }
          />
        </Grid>
      </Grid>

      <Grid xs={12} sm={12} sx={{ marginTop: 1, marginRight: 1 }}>
        <Grid item xs={12} sm={12} sx={{ marginY: 1 }}>
          <Typography variant="subtitle2">
            {t('staff:drawer.staffDetails.aboutStaff')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextareaAutosize
            style={{
              width: '99%',
              height: '150px',
              padding: '5px',
              border: '1px solid #9d9b9b',
              fontSize: '16px',
            }}
            value={staffData.note}
            onChange={(e) => {
              handleStaffDataChange('note', e.target.value);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StaffDetails;
