import React, { useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './style.css';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import CustomTooltip from '../../components/CustomTooltip';
import ResponsiveCart from './ResponsiveCart';
import ModuleCard from '../../components/ModuleCard';

const modulesData = [
  {
    id: '1',
    name: 'Dashboard',
    tagline: 'A concise overview for quick insights and data visualization.',
    c: false,
  },
  {
    id: '2',
    name: 'Calendar',
    tagline:
      'organize and schedule events, tasks, and appointments efficiently.',
    c: false,
  },
  {
    id: '3',
    name: 'Staff login + Role management',
    tagline: 'Let your user help you manage pages by giving them role access.',
    c: false,
  },
  {
    id: '4',
    name: 'Booking widget',
    tagline:
      'Easily share URLs from your services or staff and incorporate it on your website.',
    c: false,
  },
];

const tooltipListData = [
  'As a tenant, you can still add multiple staff members',
  'You can add and manage customers',
  'Add and manage your staff members',
  'Add and manage service item',
  'A full management appointment module is included in the free version',
  'Manage your sub- and categories for service, product, and restaurant menu',
  'Let customers book group appointments',
  'Add and manage taxes for services and products',
  'Add and manage coupons',
  'All customers with completed appointments can rate and send you a review',
  'Send and communicate with customers with an appointment',
  '+ more feature',
];

const comingSoonLinks = [
  'https://upnize.com/help-center/multiple-locations',
  'https://upnize.com/help-center/product-management',
  'https://upnize.com/help-center/email-notifications',
  'https://upnize.com/help-center/reports',
  'https://upnize.com/help-center/restaurants',
];
const viewDetailsLinks = [
  'https://upnize.com/help-center/dahsboard',
  'https://upnize.com/help-center/calendar',
  'https://upnize.com/help-center/role-management',
  'https://upnize.com/help-center/booking-widget',
];

const comingSoonData = [
  {
    name: 'Multiple locations',
    tagline: 'Manage all your locations from one application',
  },
  {
    name: 'Product management',
    tagline: 'Add / Edit and market your product.',
  },
  {
    name: 'Email notifications',
    tagline: 'Customise your email for customer and staff member.',
  },
  {
    name: 'Reports',
    tagline: 'Valuable data analysis, enabling informed decision-making.',
  },
  {
    name: 'Restaurants',
    tagline: 'Marketing your food and lunch menu in network.',
  },
];

const listData = [
  'Up to 1 team members',
  'Unlimited customers',
  'Unlimited Staff member',
  'Unlimited services',
  'Unlimited appointments',
  'Multi-level categories',
  'Group appointments',
  'Manage taxes',
  'Coupons',
  'Rating and reviews',
  'Private messages',
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: '3px',
      ml: '-5px',
    }}
  >
    <AddOutlinedIcon
      sx={{
        fontSize: '14px',
      }}
    />
    more
  </Box>,
];

const ModulesStep = ({
  modules,
  setModules,
  selectedModules,
  setSelectedModules,
  numOfUsers,
  setNumOfUsers,
  setIsPaymentStep,
  perUser,
  setPerUser,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleModuleClick = (item) => {
    if (selectedModules.find((module) => module.code === item.code)) {
      setSelectedModules((prev) =>
        prev.filter((module) => module.code != item.code)
      );
    } else {
      setSelectedModules((prev) => [...prev, item]);
    }
  };

  const isSelected = (item) =>
    selectedModules.some((module) => module.code === item.code);

  return (
    <Box
      sx={{
        backgroundColor: '#f5f7fa',
        px: '20px',
      }}
    >
      <ResponsiveCart
        {...{
          open,
          handleClose,
          selectedModules,
          numOfUsers,
          setNumOfUsers,
          setPerUser,
          setSelectedModules,
          setIsPaymentStep,
          perUser,
        }}
      />
      <Grid
        sx={{
          backgroundColor: '#f5f7fa',
          minHeight: '100vh',
          py: '40px',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '1600px',
          width: '100%',
          mx: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: '40px',
          }}
        >
          <Button
            variant="contained"
            size="large"
            sx={{
              width: 'auto',
              backgroundColor: 'WHITE',
              color: '#1976d2',
              display: 'flex',
              gap: '5px',
              ':hover': { backgroundColor: 'GREY', color: 'WHITE' },
            }}
            onClick={() => {
              navigate('/');
            }}
          >
            <KeyboardBackspaceIcon />
            Back
          </Button>
          <Button
            sx={{
              display: { xs: '', sm: 'none' },
            }}
          >
            <AddShoppingCartIcon onClick={handleOpen} />
          </Button>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#32313E',
            textAlign: 'center',
            mb: '20px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 900,
              fontSize: '40px',
              userSelect: 'none',
            }}
          >
            Monthly subscription with{' '}
            <span
              style={{
                color: '#39D581',
                userSelect: 'none',
              }}
            >
              lower cost
            </span>
          </Typography>
          <Typography
            sx={{
              userSelect: 'none',
              fontSize: '22px',
            }}
          >
            Choose modules that best match your business needs .
          </Typography>
        </Box>
        <Grid container columnSpacing={2}>
          {/* Freemuim plan */}
          <Grid item xs={12} md={3}>
            <Box
              sx={{
                backgroundColor: 'white',
                padding: '20px',
                border: '1px solid #e5e5fd',
                borderRadius: '5px',
                height: '600px',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 900,
                  // mb: '5px',
                  fontSize: '25px',
                  // height: '80px',
                  color: '#32313E',
                }}
              >
                Freemium user
              </Typography>
              <Typography
                sx={{
                  fontWeight: 1000,
                  fontSize: '38px',
                  mb: '20px',
                  color: '#32313E',
                }}
              >
                0€/forever
              </Typography>
              <Divider
                color="#32313E"
                sx={{
                  mb: '25px',
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '6px',
                }}
              >
                {listData.map((info, i) => (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#32313E',
                      }}
                    >
                      {info}
                    </Typography>
                    <CustomTooltip
                      placement="top"
                      arrow
                      title={tooltipListData[i]}
                    >
                      <InfoOutlinedIcon
                        sx={{
                          color: '#32313E',
                        }}
                      />
                    </CustomTooltip>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
          {/* Left Hero Modules */}
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {modules
              .filter((mod) => mod.available)
              .slice(0, 2)
              .map((item, i) => (
                <Box
                  sx={{
                    mt: { xs: 2, md: 0 },
                    width: '100%',
                  }}
                >
                  <ModuleCard {...{ item, handleModuleClick, isSelected }} />
                </Box>
              ))}
          </Grid>
          {/* Right Hero Modules */}
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {modules
              .filter((mod) => mod.available)
              .slice(2, 4)
              .map((item, i) => (
                <Box
                  sx={{
                    mt: { xs: 2, md: 0 },
                    width: '100%',
                  }}
                >
                  <ModuleCard {...{ item, handleModuleClick, isSelected }} />
                </Box>
              ))}
          </Grid>
          {/* Cart */}
          <Grid
            item
            md={3}
            // className="right-sidebar"
          >
            <Box
              sx={{
                height: '600px',
                display: { xs: 'none', sm: 'block' },
                padding: '20px',
                border: '1px solid #e5e5fd',
                borderRadius: '5px',
                background:
                  'linear-gradient(180deg, rgba(236,249,254,1) 50%, rgba(212,226,253,1) 80%, rgba(255,255,255,1) 100%)',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: '10px',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 900,
                    fontSize: '25px',
                  }}
                ></Typography>
                <Typography
                  sx={{
                    fontWeight: 900,
                    fontSize: '25px',
                    color: '#32313E',
                  }}
                >
                  Your cart
                </Typography>
              </Box>
              <Box>
                <Divider
                  sx={{
                    mt: '10px',
                    mb: '10px',
                  }}
                />
                {selectedModules.map((m) => (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#32313E',
                        }}
                      >
                        {m.name}
                      </Typography>
                      <Typography
                        sx={{
                          color: '#39d581',
                          fontWeight: 'bold',
                        }}
                      >
                        2€/Month
                      </Typography>
                    </Box>
                    <Divider
                      sx={{
                        mt: '10px',
                        mb: '10px',
                      }}
                    />
                  </>
                ))}
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: 500,
                    mt: '30px',
                    mb: '15px',
                    color: '#32313E',
                  }}
                >
                  Choose users:
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderRadius: '10px',
                    border: '1px solid #eee',
                    padding: '10px',
                    backgroundColor: '#fff',
                    mb: '20px',
                  }}
                >
                  <RemoveCircleOutlineRoundedIcon
                    sx={{
                      cursor: 'pointer',
                      color: '#32313E',
                    }}
                    onClick={() => {
                      if (numOfUsers > 0) {
                        setNumOfUsers(numOfUsers - 1);
                        setPerUser(numOfUsers * 3 - 3);
                      } else {
                        setNumOfUsers(0);
                      }
                    }}
                  />
                  <Box
                    sx={{
                      color: '#32313E',
                    }}
                  >
                    {numOfUsers}
                  </Box>
                  <AddCircleOutlineRoundedIcon
                    sx={{
                      cursor: 'pointer',
                      color: '#32313E',
                    }}
                    onClick={() => {
                      setNumOfUsers((prev) => prev + 1);
                      setPerUser(3 + numOfUsers * 3);
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: '10px',
                }}
              >
                <Typography
                  sx={{
                    color: '#32313E',
                  }}
                >
                  Freemium package
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    color: '#32313E',
                  }}
                >
                  0€/month
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: '10px',
                }}
              >
                <Typography
                  sx={{
                    color: '#32313E',
                  }}
                >
                  Users (Per user 3€)
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    color: '#32313E',
                  }}
                >
                  {perUser}€/month
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: '10px',
                  mb: '20px',
                }}
              >
                <Typography
                  sx={{
                    color: '#32313E',
                  }}
                >
                  Extension:
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    color: '#32313E',
                  }}
                >
                  {selectedModules.length * 2}€/month
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderRadius: '10px',
                  border: '1px solid #eee',
                  padding: '10px',
                  backgroundColor: '#fff',
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '20px',
                      color: '#32313E',
                    }}
                  >
                    {perUser + selectedModules.length * 2}€
                  </Typography>
                  <Typography
                    sx={{
                      color: '#32313E',
                    }}
                  >
                    /month
                  </Typography>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setIsPaymentStep(true);
                    }}
                  >
                    Subscribe
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#ccc',
                  gap: '10px',
                  mt: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setPerUser(0);
                  setSelectedModules([]);
                  setNumOfUsers(0);
                  window.location.reload(false);
                }}
              >
                <CancelOutlinedIcon
                  sx={{
                    fontSize: '22px',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '22px',
                  }}
                >
                  Reset
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            mt: '0',
          }}
        >
          {modules
            .filter((mod) => mod.available)
            .slice(4)
            .map((item) => (
              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                <ModuleCard {...{ item, handleModuleClick, isSelected }} />
              </Grid>
            ))}

          {modules
            .filter((mod) => !mod.available)
            .map((item, i) => (
              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                <ModuleCard
                  {...{ item, handleModuleClick, isSelected, comingSoon: true }}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ModulesStep;
