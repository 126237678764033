import React, { useCallback, useMemo } from 'react';
import CustomerDrawer from './CustomerDrawer';
import NotFound from '../NotFound';
import { useSelector } from 'react-redux';
import { selectDrawers } from '../../redux/slices/drawers.slice';
import ServiceDrawer from './ServiceDrawer';
import { Grid } from '@mui/material';
import { drawerTypes } from '../../constants/drawerTypes';
import CategoryDrawer from './CategoryDrawer';
import StaffDrawer from './StaffDrawer';
import TaskDrawer from './TaskDrawer';

const PublicDrawers = () => {
  const drawersState = useSelector(selectDrawers);

  const openedDrawer = useCallback((drawer) => {
    switch (drawer.type) {
      case drawerTypes.CUSTOMER:
        return (
          <CustomerDrawer
            title={drawer?.title}
            crudType={drawer?.crudType}
            submitTitle={drawer?.submitTitle}
            data={drawer?.data}
            refetchData={drawer?.refetchData}
          />
        );
      case drawerTypes.SERVICE:
        return (
          <ServiceDrawer
            title={drawer?.title}
            crudType={drawer?.crudType}
            submitTitle={drawer?.submitTitle}
            data={drawer?.data}
            refetchData={drawer?.refetchData}
          />
        );
      case drawerTypes.CATEGORY:
        return (
          <CategoryDrawer
            title={drawer?.title}
            crudType={drawer?.crudType}
            submitTitle={drawer?.submitTitle}
            data={drawer?.data}
            refetchData={drawer?.refetchData}
          />
        );
      case drawerTypes.STAFF:
        return (
          <StaffDrawer
            title={drawer?.title}
            crudType={drawer?.crudType}
            submitTitle={drawer?.submitTitle}
            data={drawer?.data}
            refetchData={drawer?.refetchData}
          />
        );
      case drawerTypes.TASK:
        return (
          <TaskDrawer
            title={drawer?.title}
            crudType={drawer?.crudType}
            submitTitle={drawer?.submitTitle}
            data={drawer?.data}
            refetchData={drawer?.refetchData}
          />
        );
      default:
        return <NotFound />;
    }
  }, []);

  const openedDrawers = useMemo(() => {
    return drawersState.map((drawer) => (
      <div key={drawer.type}>{openedDrawer(drawer)}</div>
    ));
  }, [drawersState, openedDrawer]);

  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        {openedDrawers}
      </Grid>
    </Grid>
  );
};

export default PublicDrawers;
