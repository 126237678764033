import React, { useEffect, useLayoutEffect, useState } from 'react';
import Drawer from '../../components/Drawer';
import DrawerFooter from '../../components/DrawerFooter';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useMediaQuery, useTheme } from '@mui/material';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import { addGiftCard, editGiftCardById } from '../../Api/tenant/giftCrads';
import { getStaffList } from '../../Api/tenant/staff';
import { getServiceList } from '../../Api/tenant/services';
import { getLocationList } from '../../Api/tenant/locations';
import SelectService from '../../components/AysncAutoComplete/SelectService';
import SelectLocation from '../../components/AysncAutoComplete/SelectLocation';
import SelectStaff from '../../components/AysncAutoComplete/SelectStaff';

const AddGiftcard = ({
  toggleDrawer,
  drawerType,
  drawerData,
  fetchGiftCards,
}) => {
  const [formData, setFormData] = useState({
    code: null,
    amount: null,
    locations: [],
    services: [],
    staff: [],
    status: 'Active',
  });

  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const handleFormData = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  useLayoutEffect(() => {
    if (drawerType !== 'add') {
      setFormData({
        ...drawerData,
      });
    }
  }, [drawerType]);

  // API
  // error handling
  const [errors, setErrors] = useState({});
  const requiredFields = ['code', 'amount', 'status', 'locations'];
  const handleAddGiftCard = () => {
    let data = formData;
    let err = {};
    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
        console.log('s', data[key], data);
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });
    if (Object.keys(err).length === 0) {
      const body = {
        ...formData,
        services: formData.services,
        staff: formData.staff.map((staff) => staff.id),
        locations: formData.locations.map((loc) => loc.id),
      };
      addGiftCard(body).then((res) => {
        toggleDrawer();
        fetchGiftCards();
      });
    }
  };

  const handleEditGiftCard = () => {
    let data = formData;
    let err = {};
    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
        console.log('s', data[key], data);
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });
    if (Object.keys(err).length === 0) {
      const body = {
        ...formData,
        services: formData.services.map((ser) => ser.id),
        staff: formData.staff.map((staff) => staff.id),
        locations: formData.locations.map((loc) => loc.id),
      };

      editGiftCardById(body, formData.id).then((res) => {
        toggleDrawer();
        fetchGiftCards();
      });
    }
  };

  return (
    <Grid container>
      {/* sx={{ width: "100vw"}} */}
      <Grid item xs={12} sm={2}>
        <Drawer
          title={drawerType === 'add' ? 'Add Giftcard' : 'Edit Giftcard'}
          type={drawerType === 'add' ? 'ADD' : 'EDIT'}
          toggleDrawer={toggleDrawer}
        >
          {/* -----------FORM----- */}
          <Grid
            sx={{
              paddingX: 3,
              paddingTop: 3,
              height: '100%',
              overflowY: 'auto',
            }}
          >
            <Grid container>
              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">
                    Code <span style={{ color: 'red' }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    value={formData.code}
                    onChange={(e) => {
                      handleFormData('code', e.target.value);
                      setErrors({ ...errors, code: false });
                    }}
                    helperText={errors.code ? 'Value is missing' : ' '}
                    error={errors.code}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">
                    Amount {'($)'} <span style={{ color: 'red' }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    value={formData.amount}
                    onChange={(e) => {
                      handleFormData('amount', e.target.value);
                      setErrors({ ...errors, amount: false });
                    }}
                    helperText={errors.amount ? 'Value is missing' : ' '}
                    error={errors.amount}
                  />
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">
                    Location filter <span style={{ color: 'red' }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <SelectLocation
                    selectedValue={formData.locations}
                    setSelectedValue={(v) => {
                      handleFormData('locations', v);
                      setErrors({ ...errors, locations: false });
                    }}
                    helperText={errors.locations ? 'Value is missing' : ' '}
                    error={errors.locations}
                    sx={{ marginBottom: '1rem' }}
                    multiple
                  />
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Services filter</Typography>
                </Grid>
                <Grid item xs={12}>
                  <SelectService
                    value={formData.services}
                    onChange={(e, v) => {
                      handleFormData('services', v);
                    }}
                    sx={{ marginBottom: '1rem' }}
                    multiple
                  />
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Staff filter</Typography>
                </Grid>
                <Grid item xs={12}>
                  <SelectStaff
                    selectedValue={formData.staff}
                    setSelectedValue={(v) => {
                      handleFormData('staff', v);
                    }}
                    sx={{ marginBottom: '1rem' }}
                    multiple
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* -----Button */}

          <DrawerFooter>
            <div
              style={{
                display: 'flex',
                gap: '15px',
                justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                width: '100%',
              }}
            >
              <CustomTextButton
                variant="contained"
                size="large"
                onClick={toggleDrawer}
              >
                Cancel
              </CustomTextButton>
              <CustomTextButton
                type="blue"
                variant="contained"
                size="large"
                onClick={() => {
                  if (drawerType === 'add') {
                    handleAddGiftCard();
                  } else {
                    handleEditGiftCard();
                  }
                }}
              >
                {drawerType === 'add' ? 'Save' : 'Save'}
              </CustomTextButton>
            </div>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default AddGiftcard;
