import { useLayoutEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Grid,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { editSettings } from '../../../Api/tenant/settings';
import SaveSlideBar from '../../SaveSlideBar';
import CustomSettingsTitle from '../../CustomSettingsTitle/CustomSettingsTitle';
import timezones from '../../../assets/lists/timezones.json';
import { fetchUser } from '../../../redux/slices/user.slice.js';
import { useDispatch } from 'react-redux';
import { priorTimeConstant } from './priorTime';
import { IOSSwitch } from '../../CustomCheckbox';
import { useTranslation } from 'react-i18next';

const timeSlotLengthArr = [
  {
    label: '1m',
    duration: '1',
  },
  {
    label: '2m',
    duration: '2',
  },
  {
    label: '3m',
    duration: '3',
  },
  {
    label: '4m',
    duration: '4',
  },
  {
    label: '5m',
    duration: '5',
  },
  {
    label: '10m',
    duration: '10',
  },
  {
    label: '12m',
    duration: '12',
  },
  {
    label: '15m',
    duration: '15',
  },
  {
    label: '20m',
    duration: '20',
  },
  {
    label: '25m',
    duration: '25',
  },
  {
    label: '30m',
    duration: '30',
  },
  {
    label: '35m',
    duration: '35',
  },
  {
    label: '40m',
    duration: '40',
  },
  {
    label: '50m',
    duration: '50',
  },
  {
    label: '55m',
    duration: '55',
  },
  {
    label: '1h',
    duration: '60',
  },
  {
    label: '1h 30m',
    duration: '90',
  },
  {
    label: '2h',
    duration: '120',
  },
  {
    label: '2h 30m',
    duration: '150',
  },
  {
    label: '3h',
    duration: '180',
  },
  {
    label: '3h 30m',
    duration: '210',
  },
  {
    label: '4h',
    duration: '240',
  },
  {
    label: '4h 30m',
    duration: '270',
  },
  {
    label: '5h',
    duration: '300',
  },
];

const slotLengthAsServiceDurationArr = ['Disabled', 'Enabled'];
const minimumTimeRequirementPriorToBooking = ['Disabled', '1', '2', '3', '4'];
const weekStartsOn = ['Sunday', 'Monday'];
const dateFormat = [
  '2022-09-23 [Y-m-d]',
  '09/23/2022 [m/d/Y]',
  '09-23-2022 [m-d-Y]',
  '23/09/2022 [d/m/Y]',
  '23.09.2022 [d.m.Y]',
];
const timeFormat = ['12 hour format', '24 hour format'];
const defaultAppointmentStatus = [
  'Pending Approval',
  'Confirmed',
  'In Progress',
  'Rescheduled',
  'On Hold',
  'Completed',
  'Cancelled',
  'Await Payment',
  'No Show',
  'Rejected',
  'Paid',
];
const timeZones = [
  'Stockholm',
  'Approved',
  'Canceled',
  'Rejected',
  'Completed',
  'No Show',
  'Waiting for payment',
];
const linkExpiresAfter = ['Disabled', '1', '2', '3', '4', '5', '10'];

const GeneralSettingsComp = ({ fetchCurrentSettings, currentSettings }) => {
  const [isSaveBar, setIsSaveBar] = useState(false);
  const [context, setContext] = useState({
    timeSlotLength: '',
    sLengthSD: '',
    mtrptb: '',
    lbd: '',
    weekStart: '',
    dFormat: '',
    tFormat: '',
    das: '',
    showTimeSlots: true,
    linkExpire: '',
    timezone: '',
    adminBook: false,
    registredBook: true,
  });
  const mediaQuery1238 = useMediaQuery('(min-width:1238px)');

  const dispatch = useDispatch();

  const { t } = useTranslation('settings');

  const handleChange = (value, key) => {
    setIsSaveBar(true);
    setContext({ ...context, [key]: value });
  };

  useLayoutEffect(() => {
    setContext(currentSettings.GeneralSettings);
  }, []);

  // click handlers
  const handleSave = () => {
    editSettings({
      ...currentSettings,
      GeneralSettings: {
        ...context,
        timeSlotLength: context.timeSlotLength.duration,
      },
    }).then((res) => {
      if (res.status === 200) {
        fetchCurrentSettings();
        dispatch(fetchUser())
          .unwrap()
          .then((res) => {
            setIsSaveBar(false);
          });
      }
    });
  };

  const handleCancel = () => {
    setContext(currentSettings.GeneralSettings);
    setIsSaveBar(false);
  };

  return (
    <>
      <Grid
        xs={12}
        sx={{
          marginTop: { xs: 9, sm: 14 },
          backgroundColor: 'white',
          pb: 1,
          mb: 5,
        }}
      >
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <CustomSettingsTitle
            title={t('settings:general.title')}
            subTitle={t('settings:general.general.title')}
          />
        </Grid>
        <Grid container sx={{ padding: { xs: '15px', md: '15px 25px' } }}>
          <Grid
            xs={12}
            sm={mediaQuery1238 ? 6 : 12}
            sx={{ paddingRight: { sm: 1 } }}
          >
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                {t('settings:general.general.TimeSlotLength')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={timeSlotLengthArr}
                value={context.timeSlotLength}
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                onChange={(e, data) => {
                  handleChange(data, 'timeSlotLength');
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Grid>
          </Grid>

          <Grid
            xs={12}
            sm={mediaQuery1238 ? 6 : 12}
            sx={{ paddingRight: { sm: 1 } }}
          >
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                {t('settings:general.general.SetSlotLengthAsServiceDuration')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={slotLengthAsServiceDurationArr}
                value={context.sLengthSD}
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                onChange={(e, data) => {
                  handleChange(data, 'sLengthSD');
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Grid>
          </Grid>

          <Grid
            xs={12}
            sm={mediaQuery1238 ? 6 : 12}
            sx={{ paddingRight: { sm: 1 } }}
          >
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                {t(
                  'settings:general.general.MinimumTimeRequirementPriorToBooking'
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={priorTimeConstant}
                value={
                  priorTimeConstant.filter(
                    (opt) => opt.duration == context.mtrptb
                  )[0]
                }
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                onChange={(e, data) => {
                  handleChange(data.duration, 'mtrptb');
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Grid>
          </Grid>

          <Grid
            xs={12}
            sm={mediaQuery1238 ? 6 : 12}
            sx={{ paddingRight: { sm: 1 } }}
          >
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                {t('settings:general.general.LimitedBookingDays')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="number"
                id="outlined-basic"
                size="small"
                required
                value={context.lbd}
                variant="outlined"
                onChange={(e, data) => {
                  handleChange(data, 'lbd');
                }}
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>

          <Grid
            xs={12}
            sm={mediaQuery1238 ? 6 : 12}
            sx={{ paddingRight: { sm: 1 } }}
          >
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                {t('settings:general.general.WeekStartsOn')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={weekStartsOn}
                value={context.weekStart}
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                onChange={(e, data) => {
                  handleChange(data, 'weekStart');
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            sm={mediaQuery1238 ? 6 : 12}
            sx={{ paddingRight: { sm: 1 } }}
          >
            <Grid
              xs={12}
              sm={mediaQuery1238 ? 6 : 12}
              sx={{ paddingRight: { sm: 1 } }}
            >
              <Grid item xs={12} sx={{ marginY: 1 }}>
                <Typography variant="subtitle2">
                  {t('settings:general.general.DateFormat')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  options={dateFormat}
                  value={context.dFormat}
                  sx={{ marginBottom: '2rem', fontSize: '14px' }}
                  onChange={(e, data) => {
                    handleChange(data, 'dFormat');
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              xs={12}
              sm={mediaQuery1238 ? 6 : 12}
              sx={{ paddingRight: { sm: 1 } }}
            >
              <Grid item xs={12} sx={{ marginY: 1 }}>
                <Typography variant="subtitle2">
                  {t('settings:general.general.TimeFormat')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  options={timeFormat}
                  value={context.tFormat}
                  sx={{ marginBottom: '2rem', fontSize: '14px' }}
                  onChange={(e, data) => {
                    handleChange(data, 'tFormat');
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            xs={12}
            sm={mediaQuery1238 ? 6 : 12}
            sx={{ paddingRight: { sm: 1 } }}
          >
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                {t('settings:general.general.DefaultAppointmentStatus')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={defaultAppointmentStatus}
                value={context.das}
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                onChange={(e, data) => {
                  handleChange(data, 'das');
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Grid>
          </Grid>

          <Grid
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsSaveBar(true);
              setContext((prev) => {
                return { ...prev, showTimeSlots: !prev.showTimeSlots };
              });
            }}
            item
            xs={mediaQuery1238 ? 6 : 12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              paddingX: 2,
              paddingY: 1,
              marginTop: 4.8,
              height: '40px',
            }}
          >
            <Typography variant="subtitle2">
              {t('settings:general.general.ShowTimeSlotsInClientTimezone')}
            </Typography>
            <IOSSwitch
              size="small"
              defaultChecked
              sx={{ marginLeft: 2 }}
              onChange={(e) => {
                setIsSaveBar(true);
                handleChange(e.currentTarget.checked, 'showTimeSlots');
              }}
              checked={context.showTimeSlots}
            />
          </Grid>

          <Grid
            xs={12}
            sm={mediaQuery1238 ? 6 : 12}
            sx={{ paddingRight: { sm: 1 } }}
          >
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                {t('settings:general.general.LinkExpiresAfter')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={linkExpiresAfter}
                value={context.linkExpire}
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                onChange={(e, data) => {
                  handleChange(data, 'linkExpire');
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Grid>
          </Grid>

          <Grid xs={mediaQuery1238 ? 6 : 12}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                {t('settings:general.general.Timezone')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                size="small"
                id="multiple-limit-tags"
                options={timeZones.map((option) => option)}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select..." />
                )}
                value={context.timezone}
                onChange={(e, newValue) => {
                  console.log(newValue);
                  handleChange(newValue, 'timezone');
                }}
                fullWidth
                sx={{
                  marginBottom: '2rem',
                }}
              />
            </Grid>
          </Grid>

          <Grid
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setContext((prev) => {
                return { ...prev, adminBook: !prev.adminBook };
              });
              setIsSaveBar(true);
            }}
            item
            xs={mediaQuery1238 ? 6 : 12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              paddingX: 2,
              paddingY: 3,
              marginTop: 4.8,
              height: '40px',
            }}
          >
            <Typography variant="subtitle2">
              {t(
                'settings:general.general.AllowStaffToBookAppointmentsOutsideWorkingHours'
              )}
            </Typography>
            <IOSSwitch
              size="small"
              defaultChecked
              sx={{ marginLeft: 2 }}
              onChange={(e) => {
                handleChange(e.currentTarget.checked, 'adminBook');
                setIsSaveBar(true);
              }}
              checked={context.adminBook}
            />
          </Grid>

          <Grid
            xs={mediaQuery1238 ? 6 : 12}
            sx={{ paddingLeft: mediaQuery1238 ? 1 : 0 }}
          >
            <Grid
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setContext((prev) => {
                  return { ...prev, registredBook: !prev.registredBook };
                });
                setIsSaveBar(true);
              }}
              item
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
                paddingX: 2,
                paddingY: 3,
                marginTop: 4.8,
                height: '40px',
              }}
            >
              <Typography variant="subtitle2">
                {t('settings:general.general.OnlyRegisteredUsersCanBook')}
              </Typography>
              <IOSSwitch
                size="small"
                defaultChecked
                sx={{ marginLeft: 2 }}
                onChange={(e) => {
                  handleChange(e.currentTarget.checked, 'registredBook');
                  setIsSaveBar(true);
                }}
                checked={context.registredBook}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default GeneralSettingsComp;
