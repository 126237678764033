import React from 'react';
import StatusChip from '../StatusChip';
import { Menu, MenuItem } from '@mui/material';
import { statusList, tasksStatusList } from '../../constants/statusList';

const StatusControllerComp = ({ inf, updateStatus, tasks }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const displayStatusChangeMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {!tasks
          ? statusList.map((status) => (
              <MenuItem
                onClick={() => {
                  updateStatus(inf, status, handleClose);
                }}
                style={{ padding: '3px 15px' }}
              >
                {status.name}
              </MenuItem>
            ))
          : tasksStatusList.map((status) => (
              <MenuItem
                onClick={() => {
                  updateStatus(inf, status, handleClose);
                }}
                style={{ padding: '3px 15px' }}
              >
                {status.name}
              </MenuItem>
            ))}
      </Menu>
      {inf.status === 'Custom' ? (
        <StatusChip
          onClick={displayStatusChangeMenu}
          color={inf.status.statusColor}
          bgColor={inf.status.statusBgColor}
          text={inf.status.statusText}
          rounded={true}
          width={'max-content'}
        />
      ) : (
        <StatusChip
          onClick={displayStatusChangeMenu}
          type={inf.status}
          width={'max-content'}
        />
      )}
    </>
  );
};

export default StatusControllerComp;
