import { UpnizeCustomerBackend } from '../Axios';

export const getCustomerDashboard = async (id) => {
  let response = await UpnizeCustomerBackend.get(`/dashboard`);
  return response;
};

export const bookingDashboard = async ({ locationId, serviceId, date }) => {
  let response = await UpnizeCustomerBackend.post(
    `/booking?locationId=${locationId}&serviceId=${serviceId}&date=${date}`
  );
  return response;
};
