import { TextField } from '@mui/material';
import React from 'react';
import CustomTypography from '../CustomTypography';

function CustomTextField(props) {
  const customData = props.customData;

  return (
    <>
      <CustomTypography
        sx={{
          color: '#1976D2 ',
          fontSize: '14px',
          mb: '5px',
          ...customData?.labelSx,
        }}
      >
        {customData?.label} {props.required ? '*' : ''}
      </CustomTypography>
      <TextField
        inputProps={{
          style: {
            height: '18px',
            padding: 11,
          },
        }}
        {...props}
        helperText={
          props.error || props.helperText
            ? props.helperText
              ? <span e2e={props.e2e+"-validation"}>{props.helperText}</span>
              : <span e2e={props.e2e+"-validation"}>This field is required</span>
            : ' '
        }
        sx={{
          width: '100%',
          '& fieldset': { borderRadius: 1.5 },
          ...props.sx,
        }}
      ></TextField>
    </>
  );
}

export default CustomTextField;
