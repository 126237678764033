import { useState, useEffect, useLayoutEffect } from 'react';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import 'react-phone-number-input/style.css';
import SaveSlideBar from '../../SaveSlideBar';
import ProfileImageField from '../../ProfileImageField';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, selectUser } from '../../../redux/slices/user.slice.js';
import countrydata from './Countrydata.json';
import { editUserById } from '../../../Api/common/users';
import CustomPhoneInput from '../../CustomPhoneInput';
import { useTranslation } from 'react-i18next';

const ProfileSettings = ({ fetchCurrentSettings, currentSettings }) => {
  const [states, setStates] = useState([]);
  const [isSaveBar, setIsSaveBar] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: '',
    city: '',
    gender: '',
    dob: '',
    image: null,
  });
  const { user } = useSelector(selectUser);
  const dispatch = useDispatch();

  const { t } = useTranslation(['cpSettings']);

  const genderList = ['Male', 'Female', 'Other'];

  useEffect(() => {
    const getStates = countrydata.find(
      (country) => country.country_name === formData.country
    )?.states;
    setStates(getStates || []);
  }, [formData.country]);

  const setImage = (image) => {
    setFormData((prev) => {
      return { ...formData, image };
    });
  };

  useLayoutEffect(() => {
    setFormData(user || {});
    console.log(user);
  }, [user]);

  const handleFormData = (key, selectedValue) => {
    if (key === 'country') {
      setFormData({
        ...formData,
        city: '',
        [key]: selectedValue,
      });
      setIsSaveBar(true);
      return;
    }
    setFormData({ ...formData, [key]: selectedValue });
    setIsSaveBar(true);
  };

  // click handlers
  const handleSave = () => {
    const formdata = new FormData();

    for (const key in formData) {
      formdata.append(key, formData[key]);
    }

    editUserById(formdata, user.id).then((res) => {
      if (res.status === 200) {
        dispatch(fetchUser())
          .unwrap()
          .then((res) => {
            setIsSaveBar(false);
          });
      }
    });
  };

  const handleCancel = () => {
    setFormData(user || {});
    setIsSaveBar(false);
  };

  return (
    <>
      <Grid
        xs={12}
        sx={{
          marginTop: { xs: 9, sm: 14 },
          backgroundColor: 'white',
          pb: 1,
          mb: 5,
        }}
      >
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>
            {t('cpSettings:profileSettings.title')}
            <span style={{ color: '#c1c1c1' }}>
              <CircleIcon sx={{ fontSize: '7px', marginX: '3px' }} />{' '}
              {t('cpSettings:profileSettings.profileSettings.title')}
            </span>
          </Typography>
        </Grid>
        <Grid container sx={{ padding: { xs: '15px', md: '15px 25px' } }}>
          <Grid xs={12} sx={{ marginY: '10px' }}>
            <ProfileImageField image={formData.image} setImage={setImage} />
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                {t('cpSettings:profileSettings.profileSettings.firstName')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formData.firstName}
                onChange={(e) => handleFormData('firstName', e.target.value)}
                id="outlined-basic"
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                {t('cpSettings:profileSettings.profileSettings.lastName')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formData.lastName}
                onChange={(e) => handleFormData('lastName', e.target.value)}
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                {t('cpSettings:profileSettings.profileSettings.email')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formData.email}
                onChange={(e) => handleFormData('email', e.target.value)}
                id="outlined-basic"
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                {t('cpSettings:profileSettings.profileSettings.phoneNumber')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomPhoneInput
                value={formData.phone}
                onChange={(value) => handleFormData('phone', value)}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                {t('cpSettings:profileSettings.profileSettings.country')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={countrydata.map(
                  (getcountry, index) => getcountry.country_name
                )}
                value={formData.country}
                onSelect={(e) =>
                  handleFormData('country', e.target.value.toString())
                }
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Choose Country..."
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                {t('cpSettings:profileSettings.profileSettings.city')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={states.map((getstate, index) => getstate.state_name)}
                value={formData.city}
                onSelect={(e) =>
                  handleFormData('city', e.target.value.toString())
                }
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Choose City..."
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                {t('cpSettings:profileSettings.profileSettings.gender')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={genderList}
                value={formData.gender}
                onSelect={(e) =>
                  handleFormData('gender', e.target.value.toString())
                }
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                {t('cpSettings:profileSettings.profileSettings.birthday')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formData.dob}
                onChange={(e) => handleFormData('dob', e.target.value)}
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default ProfileSettings;
