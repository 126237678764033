import { useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { Grid, Switch, Typography } from '@mui/material';

const Information = ({
  separateFirstAndLastNameInputs,
  setSeparateFirstAndLastNameInputs,
  setEmailAsRequiredField,
  setSetEmailAsRequiredField,
  setPhoneNumberAsRequiredField,
  setSetPhoneNumberAsRequiredField,
  defaultPhoneCountryCode,
  setDefaultPhoneCountryCode,
  setIsSaveBar,
}) => {
  return (
    <>
      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid
          style={{ cursor: 'pointer' }}
          onClick={() => {
            {
              setSeparateFirstAndLastNameInputs(
                !separateFirstAndLastNameInputs
              );
              setIsSaveBar(true);
            }
          }}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            paddingX: 2,
            paddingY: 1,
            height: '40px',
          }}
        >
          <Typography variant="subtitle2" sx={{ lineHeight: '1.2 !important' }}>
            Separate First and Last name inputs
          </Typography>
          <Switch
            size="small"
            defaultChecked
            sx={{ marginLeft: 2 }}
            onChange={(e) => {
              setSeparateFirstAndLastNameInputs(e.target.checked);
              setIsSaveBar(true);
            }}
            checked={separateFirstAndLastNameInputs}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid
          style={{ cursor: 'pointer' }}
          onClick={() => {
            {
              setSetEmailAsRequiredField(!setEmailAsRequiredField);
              setIsSaveBar(true);
            }
          }}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            paddingX: 2,
            paddingY: 1,
            height: '40px',
          }}
        >
          <Typography variant="subtitle2" sx={{ lineHeight: '1.2 !important' }}>
            Set Email as a required field
          </Typography>
          <Switch
            size="small"
            defaultChecked
            sx={{ marginLeft: 2 }}
            onChange={(e) => {
              setSetEmailAsRequiredField(e.target.checked);
              setIsSaveBar(true);
            }}
            checked={setEmailAsRequiredField}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setSetPhoneNumberAsRequiredField(!setPhoneNumberAsRequiredField);
            setIsSaveBar(true);
          }}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            paddingX: 2,
            paddingY: 1,
            height: '40px',
          }}
        >
          <Typography variant="subtitle2" sx={{ lineHeight: '1.2 !important' }}>
            Set Phone number as a required field
          </Typography>
          <Switch
            size="small"
            defaultChecked
            sx={{ marginLeft: 2 }}
            onChange={(e) => {
              setSetPhoneNumberAsRequiredField(e.target.checked);
              setIsSaveBar(true);
            }}
            checked={setPhoneNumberAsRequiredField}
          />
        </Grid>
      </Grid>

      <Grid xs={12}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography variant="subtitle2">Phone Number</Typography>
        </Grid>
        <Grid item xs={12}>
          <PhoneInput
            placeholder="Enter phone number"
            value={defaultPhoneCountryCode}
            onChange={(value) => {
              setDefaultPhoneCountryCode(value);
              setIsSaveBar(true);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Information;
