import React, { useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Grid,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link } from 'react-router-dom';
import { IOSSwitch } from '../../../components/CustomCheckbox';
import i18n from '../../../i18next';

// TODO: Use it if we need to set the tsc
{
  // const [currentLang, setCurrentLang] = useState({
  //   label: 'ENG',
  //   text: 'Write here your company’s terms and conditions for buyers…',
  // });
  // const [quillHtml, setQuillHtml] = useState('');
  // const langs = [
  //   {
  //     label: 'ENG',
  //     text: 'Write here your company’s terms and conditions for buyers…',
  //   },
  //   { label: 'SV', text: 'Skriv företags köp villkor…' },
  //   { label: 'ESP', text: 'Escribir condiciones de compra de la empresa...' },
  //   { label: 'DE', text: 'Firmeneinkaufsbedingungen schreiben…' },
  // ];
  //
  /* <ReactQuill
value={quillHtml}
theme="snow"
modules={modules}
// formats={formats}
placeholder={currentLang.text}
// onChange={handleProcedureContentChange}
style={{ height: '100%' }}
ref={placeholderRef}
></ReactQuill> */
}

function LocationSettings({ locationSettings, setLocationSettings }) {
  const settingsItemsForRender = [
    {
      title: i18n.t(
        'location:drawer.networkSettings.makeMyCompanyVisibleOnWebsite'
      ),
      checkboxTitle: i18n.t('location:drawer.networkSettings.visible'),
      key: 'website',
      tooltip: 'test',
    },
    {
      title: i18n.t('location:drawer.networkSettings.makeMyServiceVisible'),
      checkboxTitle: i18n.t('location:drawer.networkSettings.viewServices'),
      key: 'services',
    },
    {
      title: i18n.t('location:drawer.networkSettings.viewMyRestaurantMenu'),
      checkboxTitle: i18n.t(
        'location:drawer.networkSettings.viewRestaurantFoodMenu'
      ),
      key: 'menus',
    },
    {
      title: i18n.t('location:drawer.networkSettings.showMyProductList'),
      checkboxTitle: i18n.t('location:drawer.networkSettings.viewProducts'),
      key: 'products',
    },
    {
      title: i18n.t(
        "location:drawer.networkSettings.allowPeopleToReadCustomer'sReviews"
      ),
      checkboxTitle: i18n.t('location:drawer.networkSettings.viewReviews'),
      key: 'reviews',
    },
    {
      title: i18n.t('location:drawer.networkSettings.showHeaderImage'),
      checkboxTitle:
        i18n.t('location:drawer.networkSettings.uploadImages') + ' 1920x300',
      key: 'headerImage',
    },
    {
      title: i18n.t(
        'location:drawer.networkSettings.allowPeopleToReadTheTerms&Conditions'
      ),
      checkboxTitle: i18n.t(
        'location:drawer.networkSettings.termsAndConditions'
      ),
      key: 'tcs',
    },
    {
      title: i18n.t(
        'location:drawer.networkSettings.makeMyBookingWidgetVisible'
      ),
      checkboxTitle: i18n.t(
        'location:drawer.networkSettings.viewBookingWidget'
      ),
      key: 'bookingWidget',
    },
    {
      title: i18n.t(
        'location:drawer.networkSettings.showStaffMemberAvailability'
      ),
      checkboxTitle: i18n.t('location:drawer.networkSettings.viewStaffMembers'),
      key: 'staff',
    },
    {
      title: i18n.t('location:drawer.networkSettings.showWeeklyLunchMenu'),
      checkboxTitle: i18n.t('location:drawer.networkSettings.viewLunchMenu'),
      key: 'weeklyLunch',
    },
    {
      title: i18n.t('location:drawer.networkSettings.showProductPrice'),
      checkboxTitle: i18n.t('location:drawer.networkSettings.viewPrice'),
      key: 'productPrices',
    },
    {
      title: i18n.t(
        'location:drawer.networkSettings.allowPeopleToReadAboutTheCompany'
      ),
      checkboxTitle: i18n.t('location:drawer.networkSettings.aboutCompany'),
      key: 'about',
    },
    {
      title: i18n.t(
        'location:drawer.networkSettings.showInteriorAndExteriorImages'
      ),
      checkboxTitle:
        i18n.t('location:drawer.networkSettings.uploadImages') + ' 500x300',
      key: 'IntExtImages',
    },
  ];

  return (
    <>
      <Grid
        container
        style={{
          backgroundColor: 'white',
          marginTop: '20px',
        }}
      >
        {settingsItemsForRender.map((sett) => {
          return (
            <Grid
              xs={12}
              md={6}
              sx={{
                paddingRight: { md: 1 },
                marginBottom: '30px',
              }}
            >
              <Grid
                item
                sx={{ marginY: 1, display: 'flex', alignItems: 'center' }}
              >
                <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                  {sett.title}
                </Typography>
                {sett.tooltip && (
                  <InfoIcon
                    sx={{
                      fontSize: '14px',
                      color: '#857C93',
                      marginLeft: '5px',
                    }}
                  />
                )}
              </Grid>
              <Box
                style={{ cursor: 'pointer' }}
                item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  borderRadius: '4px',
                  paddingX: 2,
                  height: '38px',
                }}
                onClick={() => {
                  setLocationSettings((prev) => {
                    return { ...prev, [sett.key]: !prev[sett.key] };
                  });
                }}
              >
                <Typography variant="subtitle2">
                  {sett.checkboxTitle}
                </Typography>
                <IOSSwitch
                  size="small"
                  defaultChecked
                  sx={{ marginLeft: 2 }}
                  checked={locationSettings[sett.key]}
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default LocationSettings;
