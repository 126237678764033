import { createSlice } from "@reduxjs/toolkit";

const initialState = []; // { type: null, data: null, options: null }

export const drawers = createSlice({
  name: "drawers",
  initialState,
  reducers: {
    openDrawer: (state, action) => {
        if(!action?.payload?.type) return;
        state.push(action.payload)
    },
    closeDrawer: (state) => {
      state.pop()
    },
  },
});

export const drawersActions = drawers.actions;

export const selectDrawers = (state) => state.drawers;

export default drawers.reducer;
