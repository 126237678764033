import moment from 'moment';
import React, { createContext, useContext, useEffect } from 'react';
import { useReducer } from 'react';
import { infoData } from '../utils/infoData';
import reducer from '../reducers/DashboardFilterReducer';
import {
  GET_ALL_DATA,
  GET_CUSTOM_RANGE_DATA,
  GET_LAST_WEEK_DATA,
  GET_THIS_MONTH_DATA,
  GET_THIS_WEEK_DATA,
  GET_THIS_YEAR_DATA,
  GET_TODAY_DATA,
  GET_TOMORROW_DATA,
  SET_FILTER_DATA,
  SET_ACTIVE_STATUS_TAB,
  SET_ACTIVE_DATE,
} from '../actions/filterAction';
import { getDashboardData } from '../Api/common/users';

const FilterContext = createContext(null);

const initalState = {
  filterData: null,
  activeStatusTab: 'ongoing',
  activeDate: 0
};

const FilterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initalState);

  useEffect(() => {
    getTodayData();
  }, []);

  const getTodayData = async () => {
    const todayData = await getDashboardData({
      from: moment(),
      to: moment(),
    }).then((res) => res.data);
    return dispatch({ type: SET_FILTER_DATA, payload: todayData });
  };
  const getYesterdayData = async () => {
    const yesterdayDate = moment().subtract(-1, 'days');

    const yesterdayData = await getDashboardData({
      from: yesterdayDate,
      to: yesterdayDate,
    }).then((res) => res.data);

    dispatch({ type: SET_FILTER_DATA, payload: yesterdayData });
  };
  const getTomorrowData = async () => {
    const tomorrowDate = moment().subtract(-1, 'days');
    const tomorrowData = await getDashboardData({
      from: tomorrowDate,
      to: tomorrowDate,
    }).then((res) => res.data);
    dispatch({ type: SET_FILTER_DATA, payload: tomorrowData });
  };
  const getThisWeekData = async () => {
    const thisWeekData = await getDashboardData({
      from: moment().startOf('week'),
      to: moment().endOf('week'),
    }).then((res) => res.data);

    dispatch({ type: SET_FILTER_DATA, payload: thisWeekData });
  };
  const getLastWeekData = async () => {
    const lastData = await getDashboardData({
      from: moment().subtract(1, 'weeks').startOf('week'),
      to: moment().subtract(1, 'weeks').endOf('week'),
    }).then((res) => res.data);
    dispatch({ type: SET_FILTER_DATA, payload: lastData });
  };
  const getThisMonthData = async () => {
    const thisMonthData = await getDashboardData({
      from: moment().startOf('month'),
      to: moment().endOf('month'),
    }).then((res) => res.data);

    dispatch({ type: SET_FILTER_DATA, payload: thisMonthData });
  };
  const getThisYearData = async () => {
    const thisYearData = await getDashboardData({
      from: moment().startOf('year'),
      to: moment().endOf('year'),
    }).then((res) => res.data);
    dispatch({ type: SET_FILTER_DATA, payload: thisYearData });
  };
  const getCustomRangeData = async (startDate, endDate) => {
    const customRangeData = await getDashboardData({
      from: moment(startDate),
      to: moment(endDate),
    }).then((res) => res.data);

    dispatch({ type: SET_FILTER_DATA, payload: customRangeData });
  };
  const setActiveStatusTab = (activeStatusTab) => {
    dispatch({ type: SET_ACTIVE_STATUS_TAB, activeStatusTab });
  };
  const setActiveDate = (activeDate) => {
    dispatch({ type: SET_ACTIVE_DATE, activeDate });
  };


  return (
    <FilterContext.Provider
      value={{
        ...state,
        getTodayData,
        getYesterdayData,
        getTomorrowData,
        getThisWeekData,
        getLastWeekData,
        getThisMonthData,
        getThisYearData,
        getCustomRangeData,
        setActiveStatusTab,
        setActiveDate
      }}
    >
      {children}
    </FilterContext.Provider >
  );
};

export const useDashboardFilter = () => useContext(FilterContext);

export default FilterProvider;
