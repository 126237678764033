import { useState } from 'react';
import React from 'react';
import {
  useLocation,
  Link as SideBarLink,
  useNavigate,
} from 'react-router-dom';

import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import UpnizeLogo from '../../assets/images/upnize-customer-logo.png';
import LogoutIcon from '@mui/icons-material/Logout';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import TranslateIcon from '@mui/icons-material/Translate';
import {
  Button,
  colors,
  FormControlLabel,
  FormGroup,
  Link,
  Menu,
  MenuItem,
  MenuList,
  Switch,
} from '@mui/material';
import { customerPages } from '../../utils/pages';
import { Context } from '../../context/Context';
import { useContext } from 'react';
import { clearUser, selectUser } from '../../redux/slices/user.slice.js';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../Modal';
import AvatarComp from '../Avatar';
import { logout } from '../../Api/common/users';
import WelcomeModal from '../WelcomeModal';
import ErrorBoundary from '../ErrorBoundary';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;

const System = ({ children }) => {
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [panelSwitch, setPanelSwitch] = useState(true);
  const [openModal, setOpenModal] = useState({ open: false, type: null });

  const open = Boolean(anchorEl);
  const { i18n } = useTranslation();
  const context = useContext(Context);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isUserLoading } = useSelector(selectUser);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClickMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handlePanelSwitch = () => {
    setPanelSwitch(!panelSwitch);
    context.setUser({ type: 'tenant' });
  };

  const drawer = (
    <div>
      <Toolbar style={{ height: '10vh', minHeight: '64px' }}>
        <img src={UpnizeLogo} style={{ height: '35px' }} alt="logo" />
      </Toolbar>
      <Divider sx={{ bgcolor: '#EEEEEE' }} />

      <Box paddingY={'8px'}>
        {customerPages.map(
          (page, index) =>
            page && (
              <>
                <SideBarLink
                  key={index}
                  to={page.link}
                  style={{
                    textDecoration: 'none',
                    '&:hover': { background: '#8367F8' },
                  }}
                  onClick={() => {
                    if (mobileOpen) {
                      handleDrawerToggle();
                    }
                  }}
                >
                  <ListItem
                    sx={
                      location.pathname === page.link
                        ? {
                            backgroundColor: '#8367F8 ',
                            color: '#fff',
                            '&:hover': {
                              backgroundColor: '#8367F8',
                            },
                          }
                        : { color: '#959595' }
                    }
                    key={index}
                  >
                    <ListItemIcon
                      sx={
                        location.pathname === page.link
                          ? { color: '#FFFFFF' }
                          : // : { color: "#8468FA" }
                            { color: '#959595' }
                      }
                    >
                      {page.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={page.text}
                      sx={
                        location.pathname === page.link
                          ? { color: 'white' }
                          : { color: '#626c76' }
                      }
                    />
                  </ListItem>
                </SideBarLink>
                {page.child?.map((childPage, index) => {
                  return (
                    <SideBarLink
                      to={childPage.link}
                      style={{
                        textDecoration: 'none',
                        '&:hover': { background: '#2E314A' },
                      }}
                      key={index}
                    >
                      {' '}
                      <ListItem
                        sx={
                          location.pathname === childPage.link
                            ? {
                                backgroundColor: '#5D5D7E',
                                color: '#fff',
                                '&:hover': {
                                  backgroundColor: '#2E314A',
                                },
                              }
                            : { color: '#959595' }
                        }
                        key={index}
                      >
                        <ListItemIcon
                          sx={
                            location.pathname === childPage.link
                              ? { color: '#FFFFFF' }
                              : { color: '#8468FA' }
                          }
                        >
                          {/* {page.icon} */}
                        </ListItemIcon>
                        <ListItemText
                          primary={childPage.text}
                          sx={
                            location.pathname === childPage.link
                              ? { color: 'white' }
                              : { color: '#626c76' }
                          }
                        />
                      </ListItem>
                    </SideBarLink>
                  );
                })}
              </>
            )
        )}
      </Box>
      <Box>
        <Box
          sx={{ m: '16px', border: '#d3d8fd 1px solid', borderRadius: '5px ' }}
        >
          <Box sx={{ m: 1.5 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                mb: 2,
              }}
            >
              <Avatar
                alt="profile image"
                src={user?.image}
                sx={{ width: '55px', height: '55px', mb: 0.5 }}
              />
              <Typography
                variant="subtitle2"
                noWrap
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 2,
                  color: '#5b537e',
                  fontWeight: 'bold',
                }}
              >
                {`${user?.firstName} ${user?.lastName}`}
              </Typography>
            </Box>
            <Typography sx={{ fontSize: '14px', color: '#5b537e' }}>
              Invite and help your friends discover the Upnize App and the
              amazing services we offer.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                variant="contained"
                sx={{
                  mt: 1,
                  fontSize: '12px',
                  fontWeight: 'bold',
                  backgroundColor: '#14b980',
                  color: 'white',
                  ':hover': { backgroundColor: '#14b980', boxShadow: 'none' },
                  boxShadow: 'none',
                }}
                onClick={() => {
                  setOpenModal({
                    open: true,
                    type: 'invite-customer-modal',
                  });
                }}
              >
                Invite friends
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  return (
    <div
      style={{
        overflow: 'hidden',
      }}
    >
      {user.createdAt === user.updatedAt && <WelcomeModal />}
      {openModal.type === 'invite-customer-modal' && (
        <Modal
          handleOpenModal={handleOpenModal}
          type="INVITE_CUSTOMER_CP"
          open={openModal.open}
          id={openModal.id}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        id="navbar-menu"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleCloseMenu}
        elevation={0}
        MenuListProps={{
          sx: {
            // width: anchorEl && anchorEl.offsetWidth,
            border: '1px solid #e3eaf3',
            borderRadius: '0px',
          },
        }}
      >
        <SideBarLink
          to={'/setting?main=profile-settings&sub=profile-settings'}
          onClick={() => {
            handleCloseMenu();
          }}
          style={{
            textDecoration: 'none',
          }}
        >
          <MenuItem
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <ListItemText>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color: '#303439',
                }}
              >{`${user?.firstName} ${user?.lastName}`}</Typography>
            </ListItemText>
            <ListItemText>{user?.email}</ListItemText>
          </MenuItem>
          {/* <MenuItem></MenuItem> */}
        </SideBarLink>
        {user.TenantId && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              ':hover': { backgroundColor: '#fff' },
              mx: '16px',
            }}
          >
            <Button
              onClick={handlePanelSwitch}
              sx={{
                color: '#303439',
                backgroundColor: '#fff',
                height: '40px',
                width: '100%',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#fff',
                },
                borderRadius: '999px',
                boxShadow:
                  '0px 2px 4px -1px rgb(0 0 0 / 5%), 0px 4px 5px 0px rgb(0 0 0 / 5%), 0px 1px 10px 0px rgb(0 0 0 / 5%), 0px 1px 10px 0px rgb(0 0 0 / 5%)',
                my: 1,
              }}
            >
              Switch to company
            </Button>
          </Box>
        )}
        <Divider sx={{ bgcolor: '#EEEEEE' }} />
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            navigate('/setting?main=profile-settings&sub=profile-settings');
          }}
        >
          <PersonIcon sx={{ marginRight: '1rem' }} />
          <ListItemText>Profile</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            i18n.changeLanguage(i18n.language === 'sv' ? 'en' : 'sv');
            navigate(0);
          }}
        >
          <TranslateIcon sx={{ marginRight: '1rem' }} />
          <ListItemText>
            Translate to {i18n.language === 'sv' ? 'English' : 'Swedish'}
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            navigate('/setting?main=profile-settings&sub=change-password');
          }}
        >
          <ChangeCircleIcon sx={{ marginRight: '1rem' }} />
          <ListItemText>Edit Password</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            logout().then((res) => {
              if (res.status === 200) {
                dispatch(clearUser())
                  .unwrap()
                  .then((res) => {
                    navigate('/login');
                  });
              }
            });
          }}
        >
          <LogoutIcon sx={{ marginRight: '1rem' }} />
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        // aria-label="mailbox folders"
      >
        <AppBar
          position="fixed"
          sx={{
            width: { md: `calc(100% - ${drawerWidth}px)` },
            minHeight: '64px',
            height: '10vh',
            ml: { md: `${drawerWidth}px` },
            backgroundColor: 'white',
            justifyContent: 'center',
            color: 'black',
            boxShadow:
              '0px 2px 4px -1px rgb(0 0 0 / 5%), 0px 4px 5px 0px rgb(0 0 0 / 5%), 0px 1px 10px 0px rgb(0 0 0 / 5%)',
          }}
        >
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: '10px 32px !important',
              m: 0,
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: 'flex' }}></Box>
            <Box
              sx={{ display: 'flex', justifyContent: { md: 'space-between' } }}
            >
              <Grid
                container
                xs={12}
                onClick={handleClickMenu}
                sx={{
                  width: '100%',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <AvatarComp
                  username={`${user?.firstName} ${user?.lastName}`}
                  img={user?.image}
                  sx={{
                    border: open ? '2px solid #1976d2' : 'none',
                  }}
                />
              </Grid>
            </Box>
          </Toolbar>
        </AppBar>

        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: 'white',
              color: '#6c757d',
              fontSize: '24px',
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& ::-webkit-scrollbar': { width: '5px' },
            '& ::-webkit-scrollbar-track': {
              // boxShadow: "inset 0 0 5px grey",
              // borderRadius:" 10px",
              backgroundColor: '#292D32',
            },
            '& ::-webkit-scrollbar-thumb': {
              backgroundColor: '#6c757d',
              borderRadius: '10px',
            },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: '#ffffff',
              color: '#6c757d',
              fontWeight: '700',
            },
          }}
          open
        >
          {drawer}
        </Drawer>

        <Grid
          container
          sx={{
            maxWidth: { xs: '100vw', md: `calc(100% - ${drawerWidth}px)` },
            minWidth: { xs: '100vw', md: `calc(100vw - ${drawerWidth}px)` },
            mt: '10vh',
            ml: { xs: '0px', md: `${drawerWidth}px` },
            backgroundColor: '#F5F7FA',
          }}
        >
          <div
            id="system"
            style={{
              backgroundColor: '#F5F7FA',
              width: '100%',
              overflowY: 'hidden',
              minHeight: '90vh',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <ErrorBoundary location={window.location.pathname}>
              <Box sx={{ flex: 1 }}>{children}</Box>
            </ErrorBoundary>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                color: '#656565',
              }}
            >
              <Link
                href="https://upnize.com/"
                variant="p"
                sx={{
                  paddingX: '5px',
                  color: '#656565',
                  textDecoration: 'none',
                  '&:hover': {
                    color: colors.blue[900],
                  },
                }}
              >
                © 2022 Upnize
              </Link>
              <p>|</p>
              <Link
                href="https://upnize.com/help-center"
                variant="a"
                target="_blank"
                sx={{
                  paddingX: '5px',
                  color: '#656565',
                  textDecoration: 'none',
                  '&:hover': {
                    color: colors.blue[900],
                  },
                }}
              >
                Need help?
              </Link>
              <p>|</p>
              <Link
                href="https://upnize.com/terms-of-service"
                variant="a"
                target="_blank"
                sx={{
                  paddingX: '5px',
                  color: '#656565',
                  textDecoration: 'none',
                  '&:hover': {
                    color: colors.blue[900],
                  },
                }}
              >
                Terms of service
              </Link>
            </Box>
          </div>
        </Grid>
      </Box>
    </div>
  );
};

export default System;
