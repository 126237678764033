import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../redux/slices/user.slice.js";
import { fetchInitialGuideProgress, selectInitialGuide } from "../redux/slices/initialGuide.slice";

const useInitialGuide = () => {
    const dispatch = useDispatch()
    const { user } = useSelector(selectUser);
    const initialGuideData = useSelector(selectInitialGuide);

    const fetchInitialGuide = useCallback(async () => {
        try {
            dispatch(fetchInitialGuideProgress(user.id))
        } catch(err) {
            console.log(err)
        }
    }, [dispatch, user.id])

  return {
    initialGuideData,
    fetchInitialGuide,
  };
};

export default useInitialGuide
