import { UpnizeBackend } from '../Axios';

export const getServiceList = async (rows, page, queries) => {
  let response = await UpnizeBackend.get(
    `/services?rows=${rows}&page=${page}${queries ? queries : ''}`
  );
  return response;
};

export const getSubServiceList = async (rows, page, searchQuery) => {
  let response = await UpnizeBackend.get(
    `/subServiceList?rows=${rows}&page=${page}${
      searchQuery ? `&keyword=${searchQuery}` : ''
    }`
  );
  return response;
};

export const getServiceById = async (id) => {
  let response = await UpnizeBackend.get(`/services/byId/${id}`);
  return response;
};

export const addService = async (body) => {
  let response = await UpnizeBackend.post(`/services/add`, body);
  return response;
};

export const addSubService = async (body) => {
  let response = await UpnizeBackend.post(`/services/addSubService`, body);
  return response;
};

export const editServiceById = async (body, id) => {
  let response = await UpnizeBackend.put(`/services/edit/${id}`, body);
  return response;
};

export const editSubServiceById = async (body, id) => {
  let response = await UpnizeBackend.put(
    `/services/editSubService/${id}`,
    body
  );
  return response;
};

export const deleteServiceById = async (id) => {
  let response = await UpnizeBackend.delete('/services/delete', {
    data: {
      ids: id,
    },
  });
  return response;
};

export const deleteSubServiceById = async (id) => {
  let response = await UpnizeBackend.delete(`/services/deleteSubService`, {
    data: { ids: id },
  });
  return response;
};

// breaks

export const addBreak = async (body) => {
  let response = await UpnizeBackend.post(`/timesheets/breaks/add`, body);
  return response;
};

// timesheets
/**
 @params {breaksId: Number, from: String, "to": String}
*/
export const addTimesheet = async (body) => {
  let response = await UpnizeBackend.post(`/timesheets/add`, body);
  return response;
};

export const editTimesheetById = async (body, id) => {
  let response = await UpnizeBackend.put(`/timesheets/edit/${id}`, body);
  return response;
};

export const getTimesheetById = async (id) => {
  let response = await UpnizeBackend.get(`/timesheets/byid/${id}`);
  return response;
};
