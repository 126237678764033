import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import NotesCard from '../../../components/NotesCard';
import PriceFormatter from '../../../utils/PriceFormatter';
import ResponsiveTable from '../../../components/ResponsiveTable';
import { getCustomerDashboard } from '../../../Api/customer/dashboard';
import NoData from '../../../components/NoData';
import BookAppointmnt from './BookAppointmnt';
import AboutUpnize from './AboutUpnize';
import './customer.module.css';
import { durationFormater } from '../../../utils/durationFormater';
import { useTranslation } from 'react-i18next';
const notes = [
  {
    name: 'Name',
    company: '{{CompanyName}}',
    date: '3 days ago',
    body: 'Your time at 2nd April is cancelled. I could help you booking a new appointment. let me know? ',
    image: 'url',
  },
  {
    name: 'name',
    company: 'company',
    date: 'date',
    body: 'body data',
    image: 'url',
  },
  {
    name: 'name',
    date: 'date',
    body: 'body data',
    image: 'url',
  },
  {
    name: 'name',
    date: 'date',
    body: 'body data',
    image: 'url',
  },
  {
    name: 'name',
    date: 'date',
    body: 'body data',
    image: 'url',
  },
  {
    name: 'name',
    date: 'date',
    body: 'body data',
    image: 'url',
  },
  {
    name: 'name',
    date: 'date',
    body: 'body data',
    image: 'url',
  },
  {
    name: 'name',
    date: 'date',
    body: 'body data',
    image: 'url',
  },
  {
    name: 'name',
    date: 'date',
    body: 'body data',
    image: 'url',
  },
  {
    name: 'name',
    date: 'date',
    body: 'body data',
    image: 'url',
  },
];

const row = [
  {
    id: 1,
    name: 'Tenant corp',
    date: 'Wed Sep 28 2022 04:15:53',
    service: 'Dental implants',
    price: '250',
    duration: '30m',
  },
  {
    id: 2,
    name: 'Company with so so long name',
    date: 'Wed Sep 28 2022 04:15:53',
    service: 'Hair dress',
    price: '2450',
    duration: '2h',
  },
  {
    id: 3,
    name: 'Someone',
    date: 'Wed Sep 28 2022 04:15:53',
    service: 'Nail',
    price: '250',
    duration: '60m',
  },
  {
    id: 4,
    name: 'Soemone',
    date: 'Wed Sep 28 2022 04:15:53',
    service: 'Dental implants',
    service: 'Car wash',
    price: '2450',
    duration: '1h 45m',
  },
  {
    id: 5,
    name: 'Alexander',
    date: 'Wed Sep 28 2022 04:15:53',
    service: 'Dental implants',
    price: '250',
    duration: '30m',
  },
];

const fRows = [
  {
    id: 1,
    name: 'Tenant corp',
    email: 'company@company.com',
    phone: '250 323 500',
  },
  {
    id: 2,
    name: 'Someone',
    email: 'company@company.com',
    phone: '245 034 500',
  },
  {
    id: 3,
    name: 'Alexander',
    email: 'company@company.com',
    phone: '2503 4534 500',
  },
];

const Index = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [rows, setRows] = useState([]);
  const [favouriteRows, setFavouriteRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const mediaQuery1600 = useMediaQuery('(min-width:1600px)');

  const { t } = useTranslation(['cpDashboard', 'common']);

  const about = [
    {
      title: t('cpDashboard:aboutUpnize.findBusiness'),
      text: t('cpDashboard:aboutUpnize.findBusinessDescription'),
    },
    {
      title: t('cpDashboard:aboutUpnize.makeAppointment'),
      text: t('cpDashboard:aboutUpnize.makeAppointmentDescription'),
    },
    {
      title: t('cpDashboard:aboutUpnize.inviteFriendsToUpnize'),
      text: t('cpDashboard:aboutUpnize.inviteFriendsToUpnizeDescription'),
    },
  ];

  // API
  const fetchDashboardData = () => {
    setTableLoading(true);
    getCustomerDashboard(100, 1).then((res) => {
      setDashboardData(res.data);
    });
    setTableLoading(false);
  };

  // fetch on render
  useEffect(() => {
    fetchDashboardData();
  }, []);

  // update table rows on fetch
  useEffect(() => {
    if (dashboardData?.upcomingAppointments && dashboardData?.topProviders) {
      setRows(dashboardData?.upcomingAppointments);
      setFavouriteRows(
        dashboardData?.topProviders.map((pro, i) => ({ ...pro, id: i }))
      );
      setTableLoading(false);
    }
  }, [dashboardData]);

  //  Table Columns
  const columns = [
    {
      field: 'date',
      headerName: t('common:tableHeaders.date'),
      minWidth: 250,
      flex: 1,
      renderCell: (params) => (
        <Grid
          sx={{
            marginY: { lg: 2, md: 2 },
            ml: { lg: 2, md: 2 },
            width: '100%',
          }}
        >
          <Box
            sx={{
              width: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {params.value}
          </Box>
        </Grid>
      ),
      renderHeader: (params) => (
        <Grid
          container
          xs={7}
          sx={{ marginY: 1, ml: 2, fontWeight: 900, fontSize: 12 }}
        >
          {params.colDef.headerName}
        </Grid>
      ),
    },
    {
      field: 'service',
      headerName: t('common:tableHeaders.service'),
      minWidth: 150,
      flex: 1,
      valueGetter: (p) => p.value.name,
    },
    {
      field: 'price',
      headerName: t('common:tableHeaders.price'),
      minWidth: 100,
      flex: 1,

      renderCell: (params) =>
        params.row.service ? (
          <div>{PriceFormatter.format(params.row.service.price)}</div>
        ) : (
          '-'
        ),
    },
    {
      headerName: t('common:tableHeaders.duration'),
      minWidth: 100,
      flex: 1,
      valueGetter: (p) => durationFormater(p.row.service.duration),
    },
  ];

  const favouriteColumns = [
    {
      field: 'name',
      headerName: t('common:tableHeaders.companyName'),
      flex: 1,
      minWidth: 300,
      renderCell: (params) => (
        <Grid
          container
          xs={7}
          sx={{ marginY: { lg: 2, md: 2 }, ml: { lg: 2, md: 2 } }}
        >
          <div
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {params.value}
          </div>
        </Grid>
      ),
      renderHeader: (params) => (
        <Grid
          container
          xs={7}
          sx={{ marginY: 1, ml: 2, fontWeight: 900, fontSize: 12 }}
        >
          {params.colDef.headerName}
        </Grid>
      ),
    },
    {
      field: 'email',
      headerName: t('common:tableHeaders.email'),
      minWidth: 300,
      flex: 1,
    },
    {
      field: 'phone',
      headerName: t('common:tableHeaders.phone'),
      flex: 1,
      minWidth: 300,
    },
  ];

  return (
    <Grid
      // item
      container
      sx={{
        minWidth: { xs: '100%' },
        paddingLeft: 4,
        paddingRight: 4,
        height: { lg: 'fit', xs: 'fit' },
        mt: '60px',
      }}
    >
      <Grid
        container
        // item
        justifyContent="space-between"
        sx={{
          marginTop: '20px',
        }}
      >
        <Grid
          item
          spacing={'20px'}
          sx={{
            display: 'felx',
            pl: '20px',
          }}
          container
        >
          <Grid
            spacing={'20px'}
            sx={{
              display: 'felx',
              justifyContent: 'space-between',
            }}
            container
          >
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <BookAppointmnt width="100%" height={'440px'} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4.5}>
              <NotesCard
                sort="ASC"
                comments={dashboardData?.comments}
                width="100%"
                height={'440px'}
                isLoading={tableLoading}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4.5}>
              <AboutUpnize
                about={about}
                width="100%"
                height={'440px'}
                isLoading={tableLoading}
              />
            </Grid>
          </Grid>
          <Grid lg={mediaQuery1600 && 12} xs={12}>
            <Box
              sx={{
                backgroundColor: 'white',
                borderBottom: 1,
                borderBottomColor: '#F5F7FA',
              }}
            >
              <Typography
                sx={{
                  marginTop: 5,
                  fontSize: 16,
                  fontWeight: 700,
                  p: 1.6,
                  pl: 3,
                  width: '100%',
                }}
              >
                {t('cpDashboard:upcomingAppointments.title')}
              </Typography>
            </Box>

            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                flexDirection: 'column',
                height: 900,
                width: '100%',
              }}
            >
              <DataGrid
                sx={{
                  border: 0,
                  '& .MuiDataGrid-virtualScroller': {
                    minHeight: '300px !important',
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontSize: '12px',
                    fontWeight: '900 !important',
                  },
                  '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                    backgroundColor: 'white',
                    border: 'none',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                }}
                getRowHeight={() => 'auto'}
                rows={rows}
                columns={columns}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                loading={tableLoading}
                selectionModel={selectionModel}
                hideFooter
                components={{
                  NoRowsOverlay: NoData,
                  NoResultsOverlay: NoData,
                }}
              />
              <Box
                sx={{
                  mt: 9,
                  backgroundColor: 'white',
                  borderBottom: 1,
                  borderBottomColor: '#F5F7FA',
                }}
              >
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 700,
                    p: 1.6,
                    pl: 3,
                    width: '100%',
                  }}
                >
                  {t('cpDashboard:yourFavouriteProviders.title')}
                </Typography>
              </Box>
              {/* desktop favo table */}
              <DataGrid
                sx={{
                  border: 0,
                  '& .MuiDataGrid-virtualScroller': {
                    minHeight: '300px !important',
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontSize: '12px',
                    fontWeight: '900 !important',
                  },
                  '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                    backgroundColor: 'white',
                    border: 'none',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                }}
                getRowHeight={() => 'auto'}
                rows={favouriteRows}
                loading={tableLoading}
                columns={favouriteColumns}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
                components={{
                  NoRowsOverlay: NoData,
                  NoResultsOverlay: NoData,
                }}
              />
            </Box>
            {/* <ResponsiveTable rows={rows} columns={columns} /> */}
            <Box
              sx={{
                mt: 9,
                backgroundColor: 'white',
                borderBottom: 1,
                borderBottomColor: '#F5F7FA',
              }}
            >
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 700,
                  p: 1.6,
                  pl: 3,
                  width: '100%',
                  display: { xs: 'flex', md: 'none' },
                }}
              >
                Your favourite providers
              </Typography>
            </Box>
            {/* <ResponsiveTable rows={favouriteRows} columns={favouriteColumns} /> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Index;
