import { Grid, Typography, Box, TextField, Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CustomUploadImg = ({
  w,
  h,
  text,
  image,
  setImage,
  imagesList,
  setImagesList,
  setIsSaveBar,
  aspectRatio,
  maxWidth,
  maxHeight,
}) => {
  const uploadedFile = useRef();
  const uploadedFileChange = useRef();
  const [isOnHover, setIsOnHover] = useState(false);
  const [currentImage, setCurrentImge] = useState();

  const { t } = useTranslation('location');

  const handleUpload = () => {
    uploadedFile.current.click();
  };
  const handleUploadChange = () => {
    uploadedFileChange.current.click();
  };

  const readURL = (file) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e) => res(e.target.result);
      reader.onerror = (e) => rej(e);
      reader.readAsDataURL(file);
    });
  };

  const handleUploadedFile = async (e) => {
    if (e.target.files.length !== 0) {
      if (imagesList) {
        setImagesList((oldList) => [...oldList, ...e.target.files]);
      } else {
        setImage(e.target.files[0]);
      }
    }
  };

  const handleChangeImage = async () => {
    if (image) {
      if (typeof image === 'string') {
        setCurrentImge(image);
      } else {
        const url = await readURL(image);
        setCurrentImge(url);
        setIsSaveBar && setIsSaveBar(true);
      }
    } else {
      setCurrentImge(image);
    }
  };

  useEffect(() => {
    if (!imagesList) {
      handleChangeImage();
    }
  }, [image]);

  return (
    <Box
      sx={{
        borderRadius: '4px',
        border: '3px solid #eee',
        padding: '8px',
        maxWidth: maxWidth ? maxWidth : 'none',
        maxHeight: maxHeight ? maxHeight + 8 : 'none',
        height: h ? h : 'auto',
        width: w ? w : 'auto',
      }}
    >
      <Box
        sx={{
          borderRadius: '4px',
          border: '3px dashed #eee',
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'cetner',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            width: '100%',
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#ccc',
              position: 'relative',
              width: '100%',
              height: '100%',
              aspectRatio,
            }}
            onClick={handleUpload}
            onMouseEnter={() => {
              setIsOnHover(true);
            }}
            onMouseLeave={() => {
              setIsOnHover(false);
            }}
          >
            {currentImage && (
              <img
                onClick={handleUploadChange}
                src={
                  currentImage.includes('base64')
                    ? currentImage
                    : process.env.REACT_APP_UPNIZE_BACKEND_URL +
                      '/' +
                      currentImage
                }
                alt=""
                style={{
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  objectFit: 'cover',
                }}
              />
            )}
            {currentImage && isOnHover && (
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // width: "100%",
                  // height: "100%",
                }}
              >
                <Button
                  onClick={handleUploadChange}
                  variant="contained"
                  sx={{
                    zIndex: '1',
                    color: '#216478',
                    backgroundColor: '#ffffff',
                    '&:hover': {
                      backgroundColor: '#ffffff',
                    },
                    width: '60px',
                    height: '30px',
                    textTransform: 'capitalize',
                    padding: '5px',
                    border: 'none',
                    borderRadius: '0',
                    outline: 'none',
                    cursor: 'pointer',
                    marginRight: '20px',
                  }}
                >
                  Change
                </Button>
                <Button
                  onClick={() => setImage(null)}
                  variant="contained"
                  color="error"
                  sx={{
                    // position: "relative",
                    zIndex: '1000',
                    color: '#fff',
                    width: '60px',
                    height: '30px',
                    textTransform: 'capitalize',
                    padding: '5px',
                    border: 'none',
                    borderRadius: '0',
                    outline: 'none',
                    cursor: 'pointer',
                  }}
                >
                  Delete
                </Button>
              </Box>
            )}

            {!currentImage && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'cetner',
                  alignItems: 'center',
                  position: 'absolute',
                }}
              >
                <Typography sx={{ textAlign: 'center', fontSize: '14px' }}>
                  {imagesList
                    ? t(
                        'location:drawer.networkImages.ClickHereToUploadAnImageWithAResolutionOf'
                      ) + ' '
                    : t(
                        'location:drawer.networkImages.ClickHereToUploadAnImageWithAResolutionOf'
                      )}
                </Typography>
                <Typography sx={{ textAlign: 'center' }}>{text}</Typography>
              </Box>
            )}
            {!currentImage && (
              <TextField
                required
                sx={{
                  marginBottom: '1rem',
                  width: '100%',
                  fontSize: '14px',
                  display: 'none',
                }}
                onChange={(e) => {
                  handleUploadedFile(e);
                }}
                inputRef={uploadedFile}
                type="file"
                inputProps={{ multiple: imagesList ? true : false }}
              />
            )}
            <TextField
              required
              sx={{
                marginBottom: '1rem',
                width: '100%',
                fontSize: '14px',
                display: 'none',
              }}
              onChange={(e) => {
                handleUploadedFile(e);
              }}
              inputRef={uploadedFileChange}
              type="file"
              inputProps={{ multiple: imagesList ? true : false }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomUploadImg;
