import {
  Autocomplete,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';

const footerTextPerStaffOptions = [
  'Show both phone number and emaill address',
  'Show only Staff email address',
  'Show only Staff phone number',
  "Don't show both phone number and emaill address",
];

const autoAssignmentRuleOptions = [
  'Least assigned by the day',
  'Most assigned by the day',
  'Least assigned by the week',
  'Most assigned by the week',
  'Least assigned by the month',
  'Most assigned by the month',
  'Most expensive',
  'Least expensive',
];

const Staff = ({
  footerTextPerStaff,
  setFooterTextPerStaff,
  enableAnyStaffOption,
  setEnableAnyStaffOption,
  autoAssignmentRule,
  setAutoAssignmentRule,
  setIsSaveBar,
}) => {
  return (
    <>
      <Grid xs={12}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography variant="subtitle2">Footer text per staff</Typography>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={footerTextPerStaffOptions}
            value={footerTextPerStaff}
            sx={{ marginBottom: '2rem', fontSize: '14px' }}
            onChange={(e, data) => {
              setFooterTextPerStaff(data);
              setIsSaveBar(true);
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setEnableAnyStaffOption(!enableAnyStaffOption);
            setIsSaveBar(true);
          }}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            paddingX: 2,
            paddingY: 1,
            height: '40px',
          }}
        >
          <Typography variant="subtitle2" sx={{ lineHeight: '1.2 !important' }}>
            Enable Any staff option
          </Typography>
          <Switch
            size="small"
            defaultChecked
            sx={{ marginLeft: 2 }}
            onChange={(e) => {
              setEnableAnyStaffOption(e.target.checked);
              setIsSaveBar(true);
            }}
            checked={enableAnyStaffOption}
          />
        </Grid>
      </Grid>

      <Grid xs={12} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography variant="subtitle2">Auto assignment rule</Typography>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={autoAssignmentRuleOptions}
            value={autoAssignmentRule}
            sx={{ marginBottom: '2rem', fontSize: '14px' }}
            onChange={(e, data) => {
              setAutoAssignmentRule(data);
              setIsSaveBar(true);
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Staff;
