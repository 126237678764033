import {
  SET_FILTER_DATA,
  SET_ACTIVE_STATUS_TAB,
  SET_ACTIVE_DATE,
} from '../actions/filterAction';
import { getDashboardData } from '../Api/common/users';

const filterReducer = (state, action) => {
  switch (action.type) {
    case SET_ACTIVE_STATUS_TAB:
      return { ...state, activeStatusTab: action.activeStatusTab };
    case SET_FILTER_DATA:
      return { ...state, filterData: action.payload };
    case SET_ACTIVE_DATE:
      return { ...state, activeDate: action.activeDate }
    default:
      return state;
  }
};
export default filterReducer;
