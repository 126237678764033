import { BrowserRouter } from 'react-router-dom';
import { Routes } from './routes';
import './assets/styles/App.css';
import { ContextProvider } from './context/Context';
import SnackbarProvider from './providers/SnackbarProvider';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      defaultProps: {
        disableSelectionOnClick: true,
      },
    },
  },
});

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>UPNIZE - Booking appointments Free forever</title>
        <meta
          name="description"
          content="UPNIZE booking appointment is built for multiple industries to handle bookings, encounters, payments, notifications, and website sales in a single place."
        ></meta>
        <meta
          name="keywords"
          content="Upnize, Booking, Appointment, Restaurant, Beauty Salon, Payment, Notifications, Sales"
        ></meta>
      </Helmet>
      <BrowserRouter>
        <Provider store={store}>
          <SnackbarProvider>
            <ContextProvider>
              <ThemeProvider theme={theme}>
                <Routes />
              </ThemeProvider>
            </ContextProvider>
          </SnackbarProvider>
        </Provider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
