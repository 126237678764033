export const sortCategories = (categories) => {
    const newList = categories.filter((cat) => cat.level);
    const parentIds = newList.map((cat) => cat.id);

    for (let i = 0; i < newList.length; i++) {
      const parentIndex = parentIds.includes(newList[i].id) ? i : null;

      if (parentIndex === null) continue;

      const children = categories.filter((cat) => cat?.parentId === newList[i]?.id)

      if(children.length === 0) continue;

      newList.splice(
        parentIndex + 1,
        0,
        ...children
      );
    }

    return newList;
  };