import { useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import PhoneInput from 'react-phone-number-input';
import CustomSettingsTitle from '../../CustomSettingsTitle/CustomSettingsTitle';

const SMSTwilio = () => {
  const [formData, setFormData] = useState({
    acountSID: '',
    authToken: '',
    phoneNumberForSMS: '',
  });

  const handleFormData = (key, selectedValue) => {
    setFormData({ ...formData, [key]: selectedValue });
  };

  return (
    <>
      <Grid container xs={12} style={{ justifyContent: 'flex-end' }}>
        <Button
          sx={{
            width: { xs: '100%', sm: 'auto' },
            marginTop: { xs: 9, sm: 5 },
            marginBottom: 1,
            marginLeft: { xs: 0, sm: 1 },
            backgroundColor: '#4abf60',
            height: '45px',
            borderRadius: '2px',
            fontSize: '12px',
            fontWeight: 600,
          }}
          variant="contained"
          color="success"
          onClick={() => {}}
        >
          <CheckIcon sx={{ marginRight: 1, fontSize: '20px' }} />
          Save Changes
        </Button>
      </Grid>

      <Grid xs={12} style={{ backgroundColor: 'white', marginTop: '20px' }}>
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <CustomSettingsTitle
            title="Integrations Settings"
            subTitle="SMS Twilio settings"
          />
        </Grid>
        <Grid container sx={{ padding: { xs: '15px', md: '15px 25px' } }}>
          <Grid container xs={12}>
            <Grid xs={12} sm={6}>
              <Grid item xs={12} sx={{ marginY: 1 }}>
                <Typography variant="subtitle2">Account SID</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value=""
                  onChange={(e) => handleFormData('accountSID', e.target.value)}
                  id="outlined-basic"
                  size="small"
                  required
                  variant="outlined"
                  sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container xs={12}>
            <Grid xs={12} sm={6}>
              <Grid item xs={12} sx={{ marginY: 1 }}>
                <Typography variant="subtitle2">Auth Token</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value=""
                  onChange={(e) => handleFormData('authToken', e.target.value)}
                  id="outlined-basic"
                  size="small"
                  required
                  variant="outlined"
                  sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container xs={12}>
            <Grid xs={6}>
              <Grid item xs={12} sx={{ marginY: 1 }}>
                <Typography variant="subtitle2">
                  Sender phone number for SMS
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <PhoneInput
                  placeholder="Enter phone number"
                  value={formData.phoneNumber}
                  onChange={(e) =>
                    handleFormData('phoneNumberForSMS', e.target.value)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SMSTwilio;
