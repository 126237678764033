import { UpnizeBackend } from '../Axios';

// categories

export const addCategory = async (body) => {
  let response = await UpnizeBackend.post(`/categories/add`, body);
  return response;
};

export const getCategoryList = async (rows, page, type) => {
  let response = await UpnizeBackend.get(
    `/categories/${type ? type : 'all'}?rows=${rows}&page=${page}`
  );
  return response;
};

export const getCategoryById = async (id) => {
  let response = await UpnizeBackend.get(
    `/categories/byId/${id}`
  );
  return response;
};

export const getCategoryChildrenListById = async (catId) => {
  let response = await UpnizeBackend.get(`/categories/children/${catId}`);
  return response;
};

export const deleteCategoryById = async (id) => {
  let response = await UpnizeBackend.delete(`/categories/delete`, {
    data: { ids: id },
  });
  return response;
};

export const editCategoryById = async (body, id) => {
  let response = await UpnizeBackend.put(`/categories/edit/${id}`, body);
  return response;
};
