import { UpnizeCustomerBackend } from '../Axios';

export const getCustomerSettings = async () => {
  let response = await UpnizeCustomerBackend.get(`/settings`);
  return response;
};

export const editCustomerSettings = async (body) => {
  let response = await UpnizeCustomerBackend.put(`/settings/edit`, body);
  return response;
};
