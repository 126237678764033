import { Avatar, Box, Card, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

function NotesCard({
  comments = [],
  hideHeader,
  width,
  height,
  isLoading,
  sort = 'DESC',
}) {
  const { t } = useTranslation(['cpDashboard']);

  const sortedComments = useMemo(
    () => (sort === 'DESC' ? comments.slice().reverse() : comments),
    [comments, sort]
  );

  return (
    <Card
      sx={{
        height: height ? height : 450,
        width: width ? width : 400,
        boxShadow: 'none',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          borderBottom: 1,
          borderBottomColor: '#F5F7FA',
        }}
      >
        {hideHeader ? (
          ''
        ) : (
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 700,
              p: 1.6,
              pl: 2,
              width: '100%',
            }}
          >
            {t('cpDashboard:latestComments.title')}
          </Typography>
        )}
      </Box>
      <Box sx={{ overflowY: 'auto', height: 400 }}>
        {!isLoading &&
          sortedComments.map((comment) => (
            <Box
              sx={{
                my: 1,
                mx: 1,
                boxShadow: '0 0 25px 0 rgb(0 0 0 / 5%)',
                background:
                  'linear-gradient(to right,#c0d6df 50%,transparent 0%),linear-gradient(#c0d6df 50%,transparent 0%),linear-gradient(to right,#c0d6df 50%,transparent 0%),linear-gradient(#c0d6df 50%,transparent 0%)',
                backgroundPosition: 'top,right,bottom,left',
                backgroundRepeat: 'repeat-x,repeat-y',
                backgroundSize: '8px 1px,1px 8px',
                padding: '10px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Avatar>
                  {comment?.User?.firstName[0]?.toUpperCase()}{' '}
                  {comment?.User?.lastName[0]?.toUpperCase()}
                </Avatar>
                <Box
                  sx={{
                    pl: 1,
                    color: '#ADADAD',
                    fontSize: '14px',
                    fontWeight: '700',
                  }}
                >
                  {comment?.User?.firstName} {comment?.User?.lastName}
                  <Typography sx={{ color: '#ADADAD', fontSize: '14px' }}>
                    {comment?.company && comment?.company + ', '}
                    {dayjs(comment?.createdAt).fromNow()}
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{ ml: 1, fontSize: '14px', wordBreak: 'break-word' }}
              >
                {comment?.text}
              </Typography>
            </Box>
          ))}

        {isLoading && (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Card>
  );
}

export default NotesCard;
