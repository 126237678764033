import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, CircularProgress } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function AboutUpnize({ about = [], hideHeader, width, height, isLoading }) {
  const { t } = useTranslation(['cpDashboard', 'common']);

  return (
    <Card
      sx={{
        height: height ? height : 450,
        width: width ? width : 400,
        boxShadow: 'none',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          borderBottom: 1,
          borderBottomColor: '#F5F7FA',
        }}
      >
        {hideHeader ? (
          ''
        ) : (
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 700,
              p: 1.6,
              pl: 2,
              width: '100%',
            }}
          >
            {t('cpDashboard:aboutUpnize.title')}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          height: 400,
        }}
      >
        {!isLoading &&
          about.map((item) => (
            <Box
              sx={{
                my: 2,
                backgroundPosition: 'top,right,bottom,left',
                backgroundRepeat: 'repeat-x,repeat-y',
                backgroundSize: '8px 1px,1px 8px',
                padding: '10px',
              }}
            >
              <Typography
                sx={{
                  ml: 1,
                  fontSize: '14px',
                  color: '#1976d2',
                  fontWeight: 700,
                }}
              >
                {item.title}
              </Typography>
              <Typography
                sx={{ ml: 1, fontSize: '14px', wordBreak: 'break-word' }}
              >
                {item.text}
              </Typography>
            </Box>
          ))}

        {isLoading && (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Card>
  );
}

export default AboutUpnize;
