import InventoryIcon from '@mui/icons-material/Inventory';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';
import PollRoundedIcon from '@mui/icons-material/PollRounded';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import NearMeRoundedIcon from '@mui/icons-material/NearMeRounded';
import LoyaltyRoundedIcon from '@mui/icons-material/LoyaltyRounded';
import CardGiftcardRoundedIcon from '@mui/icons-material/CardGiftcardRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import PersonAddIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import PercentRoundedIcon from '@mui/icons-material/PercentRounded';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import StoreIcon from '@mui/icons-material/Store';
import ReviewsIcon from '@mui/icons-material/Reviews';
import Review from '../pages/Review';
import Billing from '../pages/Billing';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import ProductionQuantityLimitsRoundedIcon from '@mui/icons-material/ProductionQuantityLimitsRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { disableSectionFunc } from './disableSectionFunc';

export const pages = [
  {
    text: 'Dashboard',
    link: '/',
    icon: <DashboardRoundedIcon />,
  },
  {
    text: 'Tasks',
    link: '/task',
    icon: <CheckCircleOutlineRoundedIcon />,
  },
  disableSectionFunc({
    text: 'Reports',
    link: '/report',
    icon: <QueryStatsRoundedIcon />,
  }),
  {
    text: 'Appointments',
    link: '/appointment',
    icon: <AccessTimeRoundedIcon />,
  },
  disableSectionFunc({
    text: 'Calendar',
    link: '/calendar',
    icon: <CalendarMonthRoundedIcon />,
  }),
  disableSectionFunc({
    text: 'Payments',
    link: '/payment',
    icon: <AccountBalanceWalletRoundedIcon />,
  }),

  { text: 'Customers', link: '/customer', icon: <Groups2RoundedIcon /> },

  disableSectionFunc({
    text: 'Services',
    link: '/service',
    icon: <PollRoundedIcon />,
  }),

  { text: 'Staff', link: '/staff', icon: <BadgeRoundedIcon /> },
  disableSectionFunc({
    text: 'Restaurant menu',
    link: '/restaurant',
    icon: <RestaurantMenuIcon />,
  }),

  disableSectionFunc({
    text: 'Products',
    link: '/products',
    icon: <ProductionQuantityLimitsRoundedIcon />,
  }),

  disableSectionFunc({
    text: 'Reviews',
    link: '/review',
    icon: <ReviewsIcon />,
  }),

  { text: 'Locations', link: '/location', icon: <NearMeRoundedIcon /> },
  { text: 'Taxes', link: '/tax', icon: <PercentRoundedIcon /> },
  disableSectionFunc({
    text: 'Coupons',
    link: '/coupon',
    icon: <LoyaltyRoundedIcon />,
  }),
  disableSectionFunc({
    text: 'Giftcards',
    link: '/giftcard',
    icon: <CardGiftcardRoundedIcon />,
  }),
  disableSectionFunc({
    text: 'Invoices',
    link: '/invoice',
    icon: <DescriptionRoundedIcon />,
  }),
  disableSectionFunc({
    text: 'Notifications',
    link: '/notification',
    icon: <NotificationsRoundedIcon />,
  }),
  disableSectionFunc({
    text: 'Workflows',
    link: '/workflow',
    icon: <AccountTreeRoundedIcon />,
  }),
  disableSectionFunc({
    text: 'Role Manager',
    link: '/role',
    icon: <PersonAddIcon />,
  }),
  { text: 'Settings', link: '/setting', icon: <SettingsRoundedIcon /> },
];

export const customerPages = [
  { text: 'Dashboard', link: '/', icon: <DashboardRoundedIcon /> },
  { text: 'Appointments', link: '/appointments', icon: <WatchLaterIcon /> },
  disableSectionFunc({
    text: 'Providers',
    link: '/providers',
    icon: <StoreIcon />,
  }),
  disableSectionFunc({
    text: 'Invoices',
    link: '/invoice',
    icon: <DescriptionRoundedIcon />,
  }),
  { text: 'Settings', link: '/setting', icon: <SettingsRoundedIcon /> },
];
