import { useState, useLayoutEffect } from 'react';
import { Grid } from '@mui/material';
import { Calendar } from 'react-multi-date-picker';
import SaveSlideBar from '../../SaveSlideBar';
import { editSettings } from '../../../Api/tenant/settings';
import CustomSettingsTitle from '../../CustomSettingsTitle/CustomSettingsTitle';
import { selectUser } from '../../../redux/slices/user.slice.js';
import { useSelector } from 'react-redux';

const Holidays = ({ fetchCurrentSettings, currentSettings }) => {
  const [isSaveBar, setIsSaveBar] = useState(false);
  const [calendarState, setCalendarState] = useState(['2023/01/19']);
  const { user } = useSelector(selectUser);

  useLayoutEffect(() => {
    setCalendarState(currentSettings.holidays);
  }, []);

  // useEffect(() => {
  //   console.log(calendarState);
  //   console.log(calendarState[0].toString());
  // }, [calendarState]);

  // click handlers
  const handleSave = () => {
    editSettings({
      ...currentSettings,
      holidays: calendarState,
    }).then((res) => {
      if (res.status === 200) {
        fetchCurrentSettings();
        setIsSaveBar(false);
      }
    });
  };

  const handleCancel = () => {
    setCalendarState(currentSettings.holidays);
    setIsSaveBar(false);
  };

  return (
    <>
      <Grid
        xs={12}
        sx={{
          marginTop: { xs: 9, sm: 14 },
          backgroundColor: 'white',
          pb: 1,
          mb: 5,
        }}
      >
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <CustomSettingsTitle title="Company" subTitle="Holidays" />
        </Grid>
        <Grid
          id="holidays-settings"
          container
          xs={12}
          sm={12}
          md={12}
          justifyContent="center"
          sx={{ paddingX: '10px', paddingY: '15px' }}
        >
          <Calendar
            style={{ width: '100%' }}
            multiple
            fullYear
            value={calendarState}
            onChange={(selectedDates) => {
              setCalendarState(selectedDates);
              setIsSaveBar(true);
            }}
            weekStartDayIndex={
              user.settings.GeneralSettings.weekStart.toLowerCase() == 'monday'
                ? 1
                : 0
            }
          />
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default Holidays;
