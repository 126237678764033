import React, { useReducer } from 'react';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Typography,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { tempData } from './tableData';
import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment/moment';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import classes from './Providers.module.css';
import ResponsiveTable from '../../../components/ResponsiveTable';
import Drawer from './Drawer';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ClearIcon from '@mui/icons-material/Clear';
import ServiceChip from './ServiceChip';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Modal from '../../../components/Modal';
import NoData from '../../../components/NoData';
import useFilterBar from '../../../hooks/useFilterBar';
import {
  deleteLocationById,
  getLocationList,
} from './../../../Api/tenant/locations';

import { getCustomerProviderList } from './../../../Api/customer/provider';
import { getQueries } from '../../../utils/GetQueries';
import { useTranslation } from 'react-i18next';

function Index() {
  const [sortedData, setSortedData] = useState([]);
  const [sortInfo, setSortInfo] = useState([]);
  const [tableData, setTableData] = useState(tempData.coloum);
  const [drawerData, setDrawerData] = useState(null);
  const [drawerType, setDrawerType] = useState('DETAILS');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeFilterElement, setActiveFilterElement] = useState(null);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const mediaQuery1250 = useMediaQuery('(min-width:1250px)');
  const [tableLoading, setTableLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [locations, setLocations] = useState([]);
  const [rows, setRows] = useState([]);

  const { FilterBar, FilterButton, state } = useFilterBar({
    filters: [
      {
        id: 1,
        title: 'Quick search',
        type: 'search',
      },
      {
        id: 3,
        title: 'Category',
        type: 'select',
        options: [
          'Shakil Ahmed',
          'Kristian Dawns',
          ' Jennifer Landr',
          ' Jennifer Landry',
          ' Hellen Ballar',
          'Emille Burhess',
          ' Albert Swift',
          ' Diana Martin',
          'Ava Gilmore',
          'Axel Juarez',
          'Charlie Deyer',
          'Francisco Jefferson',
        ],
        isOpen: false,
        search: '',
      },
      {
        id: 4,
        title: 'Service',
        type: 'select',
        options: [
          'Oral hygiene',
          'Tooth whitening',
          'Implants',
          '3d/4d Ultrasound',
          'OB/GYN',
          'Maternity',
          'Buttock Lift',
          'Liposuction',
          'Fat Transfer',
        ],
        isOpen: false,
        search: '',
      },

      {
        id: 5,
        title: 'Country',
        type: 'select',
        options: [
          'Roland Mark',
          'Mark S.Brochet,MD',
          'Joseph Church, MD',
          'Thomas Coates,MD',
          'Rima Jurban,MD',
          ' David Freyer,Do',
          'Dennis R. Maceri',
          'MD44',
        ],
        isOpen: false,
        search: '',
      },
      {
        id: 6,
        title: 'City',
        type: 'select',
        options: ['Pending', 'Paid', 'Canceled', 'Not Paid'],
        isOpen: false,
        search: '',
      },
    ],
  });

  const { t } = useTranslation(['cpProvider', 'common']);

  const handelClick = (type, id) => {
    setDrawerType(type);
    setIsDrawerOpen(true);
    console.log(id);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const header = [
    {
      field: 'name',
      headerName: t('common:tableHeaders.companyName'),
      width: mediaQuery1250 ? 250 : 200,
      renderCell: (params) => (
        <Grid container sx={{ marginY: { lg: 2 }, ml: { lg: 2, md: 2 } }}>
          <Box
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {params.value}
          </Box>
        </Grid>
      ),
      renderHeader: (params) => (
        <Grid
          container
          xs={7}
          sx={{ marginY: 1, ml: 2, fontWeight: 900, fontSize: 12 }}
        >
          {params.colDef.headerName}
        </Grid>
      ),
    },

    {
      field: 'categories',
      headerName: t('common:tableHeaders.category'),
      width: mediaQuery1250 ? 400 : 200,
      renderCell: (params) => {
        console.log(params.value, 'params');
        return (
          <ServiceChip
            params={params}
            handleShowmore={() => handelClick('DETAILS', params.id, params)}
          />
        );
      },
    },

    {
      field: 'location',
      headerName: t('common:tableHeaders.locations'),
      width: mediaQuery1250 ? 300 : 200,
      // renderCell: (params) => (params.value ? params.value : '-'),
      renderCell: (params) =>
        params.row.address + ', ' + params.row.city + ', ' + params.row.country,
    },
    {
      field: 'phone',
      headerName: t('common:tableHeaders.phone'),
      width: mediaQuery1250 ? 225 : 150,
      renderCell: (params) => (params.value ? params.value : '-'),
    },
  ];

  // API

  const fetchLocations = async () => {
    setTableLoading(true);

    getCustomerProviderList(pageSize, pageCount).then((res) => {
      setLocations(res.data);
      console.log(res.data);
    });
  };

  const handleDeleteAppointment = async (id) => {
    deleteLocationById(id).then((res) => {
      fetchLocations();
    });
  };

  // fetch on search & page change
  useEffect(() => {
    getQueries(state).then((queries) => {
      setTableLoading(true);

      getCustomerProviderList(pageSize, pageCount, queries).then((res) => {
        setLocations(res.data);
      });
    });
  }, [state, pageSize, pageCount]);

  // fetch on render
  useEffect(() => {
    fetchLocations();
  }, []);

  // update table rows on fetch
  useEffect(() => {
    if (locations) {
      setRows(locations);
      setTableLoading(false);
    }
  }, [locations]);

  return (
    <>
      {isInviteModalOpen && (
        <Modal
          handleOpenModal={() => {
            setIsInviteModalOpen((prev) => !prev);
          }}
          type="INVITE_PROVIDER"
          open={isInviteModalOpen}
        />
      )}
      {isDrawerOpen && (
        <Drawer
          toggleDrawer={toggleDrawer}
          drawerType={drawerType}
          drawerData={drawerData}
          setDrawerType={setDrawerType}
        />
      )}
      <Grid
        item
        container
        sx={{
          minWidth: { xs: '100%' },
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        {/* header */}
        <Box item className={classes.page__header}>
          <Typography sx={{ fontSize: 28 }}>
            {t('cpProvider:title')}
            <span
              style={{
                fontSize: '14px',
                backgroundColor: '#ffbb44',
                color: 'white',
                minWidth: '25px',
                height: '25px',
                verticalAlign: 'middle',
                fontWeight: 500,
                textAlign: 'center',
                borderRadius: '10px',
                padding: '5px 9px',
                lineHeight: '18px',
                marginLeft: '10px',
              }}
            >
              {tableData.length}
            </span>
          </Typography>
        </Box>

        {/* table */}
        <Box className={classes.container}>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', md: 'flex' },
            }}
          >
            {/* desktop table */}
            <DataGrid
              sx={{
                border: 0,
                '& .MuiDataGrid-virtualScroller': {
                  minHeight: '300px !important',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontSize: '12px',
                  fontWeight: '900 !important',
                  textOverflow: 'clip',
                  whiteSpace: 'break-spaces',
                  lineHeight: 1,
                },
                '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                  backgroundColor: 'white',
                  border: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
              }}
              rows={rows}
              columns={header}
              autoHeight={true}
              rowHeight={70}
              loading={tableLoading}
              components={{
                NoRowsOverlay: NoData,
                NoResultsOverlay: NoData,
              }}
            />
          </Box>
          {/* mobile table */}
          <ResponsiveTable rows={rows} columns={header} />
        </Box>
      </Grid>
    </>
  );
}

export default Index;
