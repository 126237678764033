import {
  Autocomplete,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProfileImageField from '../../../components/ProfileImageField';
import CustomPhoneInput from '../../../components/CustomPhoneInput';
import countrydata from './Countrydata.json';
import { useTranslation } from 'react-i18next';

const CompanyLocation = ({
  formData,
  setFormData,
  logo,
  setLogo,
  errors,
  setErrors,
}) => {
  const [states, setStates] = useState([]);
  const { t } = useTranslation('location');

  const handleFormData = (fieldName, value) => {
    if (fieldName === 'country') {
      const getStates = countrydata.find(
        (country) => country.country_name === value
      ).states;
      setStates(getStates);
    }
    setFormData({ ...formData, [fieldName]: value });
  };

  return (
    <Grid
      sx={{
        // paddingX: "24px",
        paddingTop: 3,
        height: '100%',
        overflowY: 'auto',
      }}
    >
      <Grid container>
        <Grid xs={12} sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}>
          <ProfileImageField
            image={logo}
            setImage={setLogo}
            title={'Upload Logo'}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={12} sm={12}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">
              {t('location:drawer.companyLocation.locationName')}{' '}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              size="small"
              required
              variant="outlined"
              sx={{
                marginBottom: '0rem',
                width: '100%',
                fontSize: '14px',
              }}
              type="text"
              onChange={(e) => {
                handleFormData('name', e.target.value);
                setErrors({ ...errors, name: false });
              }}
              value={formData.name}
              helperText={errors.name ? 'Value is missing' : ' '}
              error={errors.name}
            />
          </Grid>
        </Grid>

        <Grid xs={12} sm={6}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              {t('location:drawer.companyLocation.address')}{' '}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              size="small"
              required
              variant="outlined"
              sx={{
                marginBottom: '0rem',
                width: '100%',
                fontSize: '14px',
              }}
              onChange={(e) => {
                handleFormData('address', e.target.value);
                setErrors({ ...errors, address: false });
              }}
              value={formData.address}
              helperText={errors.address ? 'Value is missing' : ' '}
              error={errors.address}
            />
          </Grid>
        </Grid>
        <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 } }}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              {t('location:drawer.companyLocation.ZIPCode')}{' '}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              required
              variant="outlined"
              sx={{
                marginBottom: '0rem',
                width: '100%',
                fontSize: '14px',
              }}
              onChange={(e) => {
                handleFormData('zipCode', e.target.value);
                setErrors({ ...errors, zipCode: false });
              }}
              value={formData.zipCode}
              helperText={errors.zipCode ? 'Value is missing' : ' '}
              error={errors.zipCode}
            />
          </Grid>
        </Grid>

        <Grid xs={12} sm={6}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">
              {t('location:drawer.companyLocation.country')}{' '}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              options={countrydata.map(
                (getcountry, index) => getcountry.country_name
              )}
              value={formData.country}
              onSelect={(e) => {
                handleFormData('country', e.target.value.toString());
                setErrors({ ...errors, country: false });
              }}
              sx={{ marginBottom: '0rem', fontSize: '14px' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Choose Country..."
                  helperText={errors.country ? 'Value is missing' : ' '}
                  error={errors.country}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 } }}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">
              {t('location:drawer.companyLocation.city')}{' '}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              options={states.map((getstate, index) => getstate.state_name)}
              value={formData.city}
              onSelect={(e) => {
                handleFormData('city', e.target.value.toString());
                setErrors({ ...errors, city: false });
              }}
              sx={{ marginBottom: '0rem', fontSize: '14px' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Choose City..."
                  helperText={errors.city ? 'Value is missing' : ' '}
                  error={errors.city}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid xs={12} sm={6}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">
              {t('location:drawer.companyLocation.phone')}{' '}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <CustomPhoneInput
                sx={{
                  marginBottom: '0rem',
                }}
                style={{
                  borderColor: errors.phone ? '#d32f2f' : '',
                }}
                onChange={(e) => {
                  handleFormData('phone', e);
                  setErrors({ ...errors, phone: false });
                }}
                value={formData.phone}
                helperText={errors.phone ? 'Value is missing' : ' '}
                error={errors.phone}
              />
              <FormHelperText
                error
                sx={{
                  paddingBottom: '0.5rem',
                }}
              >
                {errors.phone ? 'Value is missing' : ' '}
              </FormHelperText>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 } }}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">
              {t('location:drawer.companyLocation.email')}{' '}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              size="small"
              required
              variant="outlined"
              sx={{
                marginBottom: '0rem',
                width: '100%',
                fontSize: '14px',
              }}
              onChange={(e) => {
                handleFormData('email', e.target.value);
                setErrors({ ...errors, email: false });
              }}
              value={formData.email}
              helperText={errors.email ? 'Value is missing' : ' '}
              error={errors.email}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CompanyLocation;
