import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Details from './DrawerBodyTypes/Details';
import Payment from './DrawerBodyTypes/Payment';
import CustomDrawer from '../../../components/Drawer';
import Drawerfooter from '../../../components/DrawerFooter';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DrawerComponent = ({
  toggleDrawer,
  drawerType,
  drawerData = null,
  setDrawerType,
}) => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  return (
    <Grid container>
      <CustomDrawer
        title={
          drawerType === 'DETAILS'
            ? 'Appointment details'
            : drawerType === 'PAYMENT'
            ? 'Payment'
            : 'Error'
        }
        type={
          drawerType === 'DETAILS'
            ? 'INFO'
            : drawerType === 'PAYMENT'
            ? 'PAYMENT'
            : 'EDIT'
        }
        toggleDrawer={toggleDrawer}
      >
        {/* body */}
        <Grid
          sx={{
            paddingX: 3,
            paddingTop: 3,
            height: '100%',
            overflowY: 'auto',
            width: '100%',
          }}
        >
          {drawerType === 'DETAILS' ? (
            <Details drawerData={drawerData} />
          ) : drawerType === 'PAYMENT' ? (
            <Payment drawerData={drawerData} />
          ) : (
            'Error'
          )}
        </Grid>

        {/* -----Button */}

        <Drawerfooter>
          <div
            style={{
              display: 'flex',
              justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
              width: '100%',
            }}
          >
            {drawerType === 'DETAILS' && (
              <Button
                variant="contained"
                size="large"
                sx={{
                  width: { xs: '100%', sm: 'auto' },
                  color: 'white',
                  backgroundColor: '#82D376',
                  ':hover': { backgroundColor: 'green', color: 'WHITE' },
                }}
                onClick={() => {
                  setDrawerType('PAYMENT');
                }}
              >
                {isSmallScreen ? 'Pay' : 'Pay service'}
              </Button>
            )}
            <Button
              variant="contained"
              size="large"
              sx={{
                width: { xs: '100%', sm: 'auto' },
                marginLeft: { xs: 1, sm: 2 },
                backgroundColor: 'WHITE',
                color: 'GREY',
                ':hover': { backgroundColor: 'GREY', color: 'WHITE' },
              }}
              onClick={toggleDrawer}
            >
              {drawerType === 'DETAILS' ? t('common:buttons.cancel') : 'Close'}
            </Button>
          </div>
        </Drawerfooter>
      </CustomDrawer>
    </Grid>
  );
};

export default DrawerComponent;
