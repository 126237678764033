import { Button } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';

const StyledButton = styled(Button)({
  '& .MuiButton-label::first-letter': {
    color: 'red',
  },
});

const CustomTextButton = ({ text, onClick, children, type, sx, ...props }) => {
  return (
    <Button
      size="large"
      sx={{
        width: { xs: '100%', sm: 'auto' },
        height: '40px !important',
        marginBottom: 1,
        backgroundColor:
          type === 'delete' ? '#d32f2f' : type === 'blue' ? '#1976d2' : 'white',
        color: type === 'delete' || type === 'blue' ? 'white' : '#1976d2;',
        ':hover': {
          backgroundColor:
            type === 'delete'
              ? '#d32f2f'
              : type === 'blue'
              ? '#1976d2'
              : 'white',
        },
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        '&.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeLarge':
          {
            textTransform: 'none !important',
          },
        ...sx,
      }}
      onClick={onClick}
      {...props}
    >
      {text}
      {children}
    </Button>
  );
};

export default CustomTextButton;
