import { UpnizePublicBackend } from '../Axios';

export const getPaymentIntent = async (tenantId, body) => {
  let response = UpnizePublicBackend.post(
    `/Stripe/create-payment-intent/${tenantId}`,
    body
  );
  return response;
};

export const validatePayment = async (tenantId, body) => {
  let response = UpnizePublicBackend.post(`/Stripe/validate/${tenantId}`, body);
  return response;
};
