import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  DataGrid,
  GridActionsCellItem,
  GridMoreVertIcon,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import UploadIcon from '@mui/icons-material/Upload';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import CustomTableFooter from '../../components/CustomTableFooter';
import Modal from '../../components/Modal';
import UsageHistory from './UsageHistory';
import AvatarComp from '../../components/Avatar';
import {
  deleteCustomernById,
  getCustomerList,
  importCustomers,
} from '../../Api/tenant/customers';
import { Link } from 'react-router-dom';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import CustomIconButton from '../../components/CustomButtons/CustomIconButton';
import useFilterBar from '../../hooks/useFilterBar';
import { getQueries } from '../../utils/GetQueries';
import { useMediaQuery, useTheme } from '@mui/material';
import NoData from '../../components/NoData';
import styled from '@emotion/styled';
import { gridClasses } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { drawerTypes } from '../../constants/drawerTypes';
import { drawersActions } from '../../redux/slices/drawers.slice';
import { crudTypes } from '../../constants/crudTypes';
import { useTranslation } from 'react-i18next';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    // backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[500],
  },
  [`& .${gridClasses.columnHeader}`]: {
    backgroundColor: '#EFF1FC',
    color: '#3A3A50',
  },
}));

const filters = [
  {
    id: 1,
    title: 'Quick search',
    type: 'search',
    queryLabel: 'keyword',
  },
];

const Customer = () => {
  const navigate = useNavigate();
  const [dataId, setDataId] = useState(false);
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);
  const [drawer, setDrawer] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [tableLoading, setTableLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('lg'));
  const { FilterBar, state, FilterButton } = useFilterBar({
    filters,
  });
  const { t } = useTranslation(['customer', 'common']);

  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  const handleDeleteOpenModal = () => {
    setOpenModal({ open: true, type: 'delete-modal' });
  };

  const toggleUsageHistoryModal = (e) => {
    e.stopPropagation();
    setDrawer((prevState) =>
      prevState !== 'usage-history' ? 'usage-history' : ''
    );
  };

  // API

  const fetchCustomers = async () => {
    setTableLoading(true);
    getCustomerList(100, 1).then((res) => {
      setCustomers(res.data);
    });
  };

  // fetch on render
  useEffect(() => {
    fetchCustomers();
  }, []);

  // fetch on search
  useEffect(() => {
    getQueries(state).then((queries) => {
      setTableLoading(true);
      getCustomerList(100, 1, queries).then((res) => {
        setCustomers(res.data);
      });
    });
  }, [state]);

  // update table rows on fetch
  useEffect(() => {
    if (customers) {
      setRows(customers);
      console.log(customers);
      setTableLoading(false);
    }
  }, [customers]);

  const deleteCustomer = (id) => {
    deleteCustomernById(id).then((res) => {
      fetchCustomers();
    });
  };

  const handleUpload = async (file, setFile) => {
    console.log(file);
    const res = await importCustomers(file);
    // store.dispatch(
    //   callSnackbar({
    //     message: res.data.message,
    //     type: 'success',
    //   })
    // );
    handleOpenModal();
    setFile(null);
  };

  const columns = [
    {
      field: 'customerName',
      headerName: t('common:tableHeaders.customer'),
      sortComparator: (v1, v2) =>
        gridStringOrNumberComparator(v1.name, v2.name),
      valueGetter: (params) => params.row.User,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Box display="flex">
              <AvatarComp
                img={params.row.User.image}
                username={`${params.row.User.firstName} ${params.row.User.lastName}`}
                sx={{ mr: 1 }}
              />
              <Box>
                <Typography fontSize="14px" color="#292d32">
                  <Link
                    to={`/customer/${params?.row?.id}`}
                    state={{ cData: params.row.User }}
                    style={{
                      color: '#292d32',
                      textDecoration: 'none',
                      fontWeight: '600',
                    }}
                  >
                    {params.row.User.firstName} {params.row.User.lastName}
                  </Link>
                </Typography>
                <Typography color="#95a0a9" fontSize="14px">
                  {params.row.User.email}
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      },
      minWidth: 280,
      flex: 1,
    },
    {
      field: 'phone',
      headerName: t('common:tableHeaders.phone'),
      minWidth: 160,
      flex: 1,
      valueGetter: (params) => params.row.User.phone || '-',
    },
    {
      field: 'lastAppoinment',
      headerName: t('common:tableHeaders.lastAppointment'),
      valueGetter: (params) =>
        params.row?.appointments?.length === 0
          ? '-'
          : params.row.appointments[params.row?.appointments?.length - 1]
              ?.service?.name,

      minWidth: 160,
      flex: 1,
    },
    {
      field: 'gender',
      headerName: t('common:tableHeaders.gender'),
      minWidth: 160,
      flex: 1,
      valueGetter: (params) => params.row.User.gender || '-',
    },
    {
      field: 'dateOfBirth',
      headerName: t('common:tableHeaders.dateOfBirth'),
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => params.row.User.dob || '-',
    },
    {
      field: 'actions',
      headerName: t('common:tableHeaders.actions'),
      type: 'actions',
      align: 'right',
      maxWidth: 100,
      minWidth: 100,
      getActions: (params) => [
        <GridActionsCellItem
          e2e="action-overview"
          label="Overview"
          onClick={() => navigate(`/customer/${params?.row?.id}`)}
          showInMenu
        />,
        <GridActionsCellItem
          e2e="action-edit"
          label="Edit"
          onClick={() => {
            dispatch(
              drawersActions.openDrawer({
                type: drawerTypes.CUSTOMER,
                title: t('customer:drawer.editTitle'),
                crudType: crudTypes.EDIT,
                data: params.row,
                refetchData: () => {
                  fetchCustomers();
                },
              })
            );
          }}
          showInMenu
        />,
        <GridActionsCellItem
          e2e="action-delete"
          label="Delete"
          onClick={() => {
            setOpenModal({ open: true, type: 'delete-modal', id: params.id });
          }}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        paddingLeft: 4,
        paddingRight: 4,
      }}
    >
      {openModal.type === 'delete-modal' ? (
        <Modal
          handleOpenModal={handleOpenModal}
          setDataId={setDataId}
          type={'DELETE'}
          open={openModal.open}
          id={openModal.id}
          handleDelete={deleteCustomer}
        />
      ) : (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'UPLOAD'}
          open={openModal.open}
          handleUpload={handleUpload}
        />
      )}

      {drawer === 'usage-history' && (
        <UsageHistory toggleDrawer={toggleUsageHistoryModal} />
      )}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            flexWrap: 'wrap',
          }}
        >
          <Typography
            sx={{
              marginTop: 2.5,
              marginBottom: 1,
              fontSize: 24,
              color: '#3A3A50',
            }}
          >
            {t('title')}
          </Typography>
        </Grid>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <CustomTextButton
            e2e="new-customer"
            text="+ New customer"
            onClick={() => {
              dispatch(
                drawersActions.openDrawer({
                  type: drawerTypes.CUSTOMER,
                  title: t('customer:drawer.addTitle'),
                  crudType: crudTypes.ADD,
                  refetchData: () => {
                    fetchCustomers();
                  },
                })
              );
            }}
          />
          <Box sx={{ display: 'flex', gap: '10px', ml: '10px' }}>
            <CustomIconButton
              Icon={DownloadIcon}
              toolTipText="IMPORT CUSTOMERS"
            />
            {FilterButton}
          </Box>
        </Box>
      </Grid>
      {/* --------------------SEARCH------------------------------- */}
      {FilterBar}
      {/* --------------------TABLE--------------------------------- */}
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ marginTop: '20px' }}
      >
        <div style={{ width: '100%' }}>
          <StripedDataGrid
            sx={{
              minHeight: '60vh',
              border: 0,

              '& .MuiDataGrid-columnHeader': {
                backgroundColor: '#EFF1FC',
                color: '#3A3A50',
              },
              '& .MuiDataGrid-virtualScroller': {
                minHeight: '300px !important',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px',
                fontWeight: '900 !important',
              },
              '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
                border: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
            rows={rows}
            columns={columns}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            loading={tableLoading}
            selectionModel={selectionModel}
            autoHeight
            components={{
              NoRowsOverlay: NoData,
              NoResultsOverlay: NoData,
              Footer: () => (
                <CustomTableFooter
                  selectionModel={selectionModel}
                  handleDeleteOpenModal={handleDeleteOpenModal}
                />
              ),
              MoreActionsIcon: () => (
                <GridMoreVertIcon
                  sx={{
                    backgroundColor: 'white',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                    borderRadius: '4px',
                    padding: '3px',
                    cursor: 'pointer',
                  }}
                />
              ),
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Customer;
