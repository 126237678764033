import CategorySettings from './CategorySettings';
import SubCategorySettings from './SubCategorySettings';

const ProcessSettings = ({ subTab, fetchCurrentSettings, currentSettings }) => {
  return (
    <>
      {subTab === 'category' && (
        <CategorySettings
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {subTab === 'sub-category' && (
        <SubCategorySettings
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
    </>
  );
};

export default ProcessSettings;
