import { useLayoutEffect, useState } from 'react';
import {
  Autocomplete,
  Grid,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import SaveSlideBar from '../../SaveSlideBar';
import { editSettings } from '../../../Api/tenant/settings';
import CustomSettingsTitle from '../../CustomSettingsTitle/CustomSettingsTitle';
import CustomTooltip from '../../CustomTooltip';
import { IOSSwitch } from '../../CustomCheckbox';

const currencies = ['US Dollar ( $ )', 'Euro (€)'];
const currencyFormats = ['$100', '$ 100', '100$', '100 $'];
const priceNumberFormats = ['45 000.00', '45,000.00', '45 000,00', '45.000,00'];
const priceNumbersOfDecimals = [
  '100',
  '100.0',
  '100.00',
  '100.000',
  '100.0000',
];
const howLongToWaitForPayment = ['10m', '30m', '1h', '1d', '1w', '30d'];
const successfulPaymentBookingStatus = [
  'Pending',
  'Approved',
  'Canceled',
  'Rejected',
  'Completed',
  'No show',
  'Waiting for payment',
];
const failedPaymentBookingStatus = [
  'Pending',
  'Approved',
  'Canceled',
  'Rejected',
  'Completed',
  'No show',
  'Waiting for payment',
];

const General = ({ fetchCurrentSettings, currentSettings }) => {
  const [isSaveBar, setIsSaveBar] = useState(false);
  const mediaQuery967 = useMediaQuery('(min-width:967px)');
  const mediaQuery1238 = useMediaQuery('(min-width:1238px)');
  const [customerCanPayFullAmount, setCustomerCanPayFullAmount] =
    useState(false);
  const [priceDoesNotIncludeTaxes, setPriceDoesNotIncludeTaxes] =
    useState(false);
  const [selectedValues, setSelectedValues] = useState({
    currency: '',
    currencySymbol: '',
    currencyFormat: '',
    priceNumberFormat: '',
    priceNumberOfDecimals: '',
    howLongToWaitForPayment: '',
    successfulPaymentBookingStatus: '',
    failedPaymentBookingStatus: '',
  });

  const handleSelectedValue = (key, selectedValue) => {
    setIsSaveBar(true);
    setSelectedValues({ ...selectedValues, [key]: selectedValue });
  };

  useLayoutEffect(() => {
    setSelectedValues(currentSettings);
    setCustomerCanPayFullAmount(currentSettings?.customerCanPayFullAmount);
    setPriceDoesNotIncludeTaxes(currentSettings?.priceDoesNotIncludeTaxes);
  }, []);

  // click handlers
  const handleSave = () => {
    editSettings({
      ...currentSettings,

      PaymentSettings: {
        ...currentSettings.PaymentSettings,
        General: {
          ...selectedValues,
          customerCanPayFullAmount,
          priceDoesNotIncludeTaxes,
        },
      },
    }).then((res) => {
      if (res.status === 200) {
        fetchCurrentSettings();
        setIsSaveBar(false);
      }
    });
  };

  const handleCancel = () => {
    setSelectedValues(currentSettings.PaymentSettings.General);
    setCustomerCanPayFullAmount(
      currentSettings?.PaymentSettings.General.customerCanPayFullAmount
    );
    setPriceDoesNotIncludeTaxes(
      currentSettings?.PaymentSettings.General.priceDoesNotIncludeTaxes
    );
    setIsSaveBar(false);
  };

  return (
    <>
      <Grid
        xs={12}
        sx={{
          marginTop: { xs: 9, sm: 14 },
          backgroundColor: 'white',
          pb: 1,
          mb: 5,
        }}
      >
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <CustomSettingsTitle title="Payment settings" subTitle="General" />
        </Grid>
        <Grid container sx={{ padding: { xs: '15px', md: '15px 25px' } }}>
          <Grid container xs={12} sm={mediaQuery967 ? 6 : 12}>
            <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
              <Grid item xs={12} sx={{ marginY: 1 }}>
                <Typography variant="subtitle2">Currency</Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  options={currencies}
                  value={selectedValues.currency}
                  sx={{ marginBottom: '2rem', fontSize: '14px' }}
                  onChange={(e, selectedValue) =>
                    handleSelectedValue('currency', selectedValue)
                  }
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </Grid>
            </Grid>

            <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
              <Grid item xs={12} sx={{ marginY: 1 }}>
                <Typography variant="subtitle2">Currency symbol</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={selectedValues.currencySymbol}
                  onChange={(e) =>
                    handleSelectedValue('currencySymbol', e.target.value)
                  }
                  size="small"
                  required
                  variant="outlined"
                  sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={12} sm={mediaQuery967 ? 6 : 12}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">Currency format</Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={currencyFormats}
                value={selectedValues.currencyFormat}
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                onChange={(e, selectedValue) =>
                  handleSelectedValue('currencyFormat', selectedValue)
                }
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Grid>
          </Grid>

          <Grid
            xs={12}
            sm={mediaQuery967 ? 6 : 12}
            sx={{ paddingRight: { sm: 1 } }}
          >
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">Price number format</Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={priceNumberFormats}
                value={selectedValues.priceNumberFormat}
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                onChange={(e, selectedValue) =>
                  handleSelectedValue('priceNumberFormat', selectedValue)
                }
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={mediaQuery967 ? 6 : 12}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                Price number of decimals
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={priceNumbersOfDecimals}
                value={selectedValues.priceNumberOfDecimals}
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                onChange={(e, selectedValue) =>
                  handleSelectedValue('priceNumberOfDecimals', selectedValue)
                }
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Grid>
          </Grid>

          <Grid
            xs={12}
            sm={mediaQuery1238 ? 6 : 12}
            sx={{ paddingRight: { sm: 1 } }}
          >
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                How long to wait for payment
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={howLongToWaitForPayment}
                value={selectedValues.howLongToWaitForPayment}
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                onChange={(e, selectedValue) =>
                  handleSelectedValue('howLongToWaitForPayment', selectedValue)
                }
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Grid>
          </Grid>

          <Grid
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setCustomerCanPayFullAmount(!customerCanPayFullAmount);
            }}
            item
            xs={mediaQuery1238 ? 6 : 12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              paddingX: 2,
              paddingY: 1,
              marginTop: 4.8,
              height: '40px',
            }}
          >
            <Typography variant="subtitle2">
              Customer can pay full amount
            </Typography>
            <IOSSwitch
              size="small"
              defaultChecked
              sx={{ marginLeft: 2 }}
              onChange={(e) => {
                setCustomerCanPayFullAmount(e.currentTarget.checked);
              }}
              checked={customerCanPayFullAmount}
            />
          </Grid>

          <Grid
            xs={12}
            sm={mediaQuery1238 ? 6 : 12}
            sx={{ paddingRight: { sm: 1 } }}
          >
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                Successful payment booking status
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={successfulPaymentBookingStatus}
                value={selectedValues.successfulPaymentBookingStatus}
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                onChange={(e, selectedValue) =>
                  handleSelectedValue(
                    'successfulPaymentBookingStatus',
                    selectedValue
                  )
                }
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={mediaQuery1238 ? 6 : 12}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                Failed payment booking status
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={failedPaymentBookingStatus}
                value={selectedValues.failedPaymentBookingStatus}
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                onChange={(e, selectedValue) =>
                  handleSelectedValue(
                    'failedPaymentBookingStatus',
                    selectedValue
                  )
                }
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Grid>
          </Grid>

          <Grid
            container
            xs={mediaQuery1238 ? 6 : 12}
            sx={{ paddingRight: { sm: 1 } }}
          >
            <Grid
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setPriceDoesNotIncludeTaxes(!priceDoesNotIncludeTaxes);
              }}
              item
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
                paddingX: 2,
                paddingY: 3,
                marginTop: 4.8,
                height: '40px',
              }}
            >
              <Typography variant="subtitle2">
                Hide "Price does not include taxes" on the services step
                <CustomTooltip
                  arrow
                  title='If you enable this option, it allows to show "Price does not include taxes" on the services and extra services steps'
                >
                  <HelpOutlineIcon
                    sx={{ width: 20, height: 20, pl: 1, position: 'absolute' }}
                  />
                </CustomTooltip>
              </Typography>
              <IOSSwitch
                size="small"
                defaultChecked
                sx={{ marginLeft: 2 }}
                onChange={(e) => {
                  setPriceDoesNotIncludeTaxes(e.currentTarget.checked);
                }}
                checked={priceDoesNotIncludeTaxes}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default General;
