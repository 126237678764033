import React, { useEffect, useState, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../redux/slices/user.slice.js';
import { drawersActions } from '../../redux/slices/drawers.slice.js';
import { drawerTypes } from '../../constants/drawerTypes.js';
import { crudTypes } from '../../constants/crudTypes.js';
import Modal from '../../components/Modal/index.js';
import useInitialGuide from '../../hooks/useInitialGuide.js';
import { initialGuideAttributes } from '../../constants/initialGuideAttributes.js';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function InitialGuideWidget() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isUserLoading } = useSelector(selectUser);
  const [openInitialGuide, setOpenInitialGuide] = useState(false); // TODO: this should be showing the widget instead if open
  const [initialGuideProgress, setInitialGuideProgress] = useState([]);
  const { initialGuideData, fetchInitialGuide } = useInitialGuide();
  const { t } = useTranslation('dashboard');

  const getInitialGuide = useCallback(async () => {
    try {
      if (!isUserLoading && user?.id) {
        fetchInitialGuide();
      }
    } catch (err) {
      console.log(err);
    }
  }, [isUserLoading, user?.id]);

  useEffect(() => {
    setInitialGuideProgress([
      // {
      //   num: 1,
      //   title: 'Profile',
      //   isDone: initialGuideData[initialGuideAttributes?.updatedStaff] || false,
      //   onClick: () => {
      //     dispatch(
      //       drawersActions.openDrawer({
      //         type: drawerTypes.STAFF,
      //         title: 'Edit Staff',
      //         crudType: crudTypes.EDIT,
      //         submitTitle: crudTypes.EDIT,
      //         data: { id: initialGuideData.staffId },
      //         refetchData: () => {
      //           if (!initialGuideData[initialGuideAttributes?.updatedStaff])
      //             getInitialGuide();
      //         },
      //       })
      //     );
      //   },
      // },
      {
        num: 2,
        title: 'startWithUpnizeWidget.createCategories',
        isDone:
          initialGuideData[initialGuideAttributes?.createdCategory] || false,
        onClick: () => {
          dispatch(
            drawersActions.openDrawer({
              type: drawerTypes.CATEGORY,
              title: 'Add Category',
              crudType: crudTypes.ADD,
              submitTitle: crudTypes.ADD,
              refetchData: () => {
                if (!initialGuideData[initialGuideAttributes?.createdCategory])
                  getInitialGuide();
              },
            })
          );
        },
      },
      {
        num: 3,
        title: 'startWithUpnizeWidget.createAService',
        isDone:
          initialGuideData[initialGuideAttributes?.createdService] || false,
        onClick: () => {
          dispatch(
            drawersActions.openDrawer({
              type: drawerTypes.SERVICE,
              title: 'Add Service',
              crudType: crudTypes.ADD,
              submitTitle: crudTypes.ADD,
              refetchData: () => {
                if (!initialGuideData[initialGuideAttributes?.createdService])
                  getInitialGuide();
              },
            })
          );
        },
      },
      {
        num: 4,
        title: 'startWithUpnizeWidget.addACustomer',
        isDone:
          initialGuideData[initialGuideAttributes?.createdCustomer] || false,
        onClick: () => {
          dispatch(
            drawersActions.openDrawer({
              type: drawerTypes.CUSTOMER,
              title: 'Add Customer',
              crudType: crudTypes.ADD,
              submitTitle: crudTypes.ADD,
              refetchData: () => {
                if (!initialGuideData[initialGuideAttributes?.createdCustomer])
                  getInitialGuide();
              },
            })
          );
        },
      },
      {
        num: 4,
        title: 'startWithUpnizeWidget.inviteCustomers',
        isDone:
          initialGuideData[initialGuideAttributes?.invitedCustomer] || false,
        onClick: () => {
          setOpenModal({
            open: true,
            type: 'invite-customer-modal',
          });
        },
      },
      // {
      //   num: 5,
      //   title: 'Book an appointment',
      //   isDone:
      //     initialGuideData[initialGuideAttributes?.bookedAppointment] || false,
      //   onClick: () => {},
      // },
    ]);
  }, [dispatch, initialGuideData]);

  useEffect(() => {
    getInitialGuide();
  }, [getInitialGuide]);

  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  // setting inital guide data
  useEffect(() => {}, [user]);

  useEffect(() => {
    if (
      initialGuideProgress.filter((data) => !data.isDone).length > 0 &&
      typeof initialGuideProgress[0].isDone == 'boolean'
    ) {
      setOpenInitialGuide(true);
    }
  }, [initialGuideProgress]);
  return (
    <Grid
      item
      xs={12}
      sm={6}
      lg={3}
      sx={{
        mb: '20px',
        height: '368px',
        cursor: 'pointer',
      }}
    >
      {openModal.type === 'invite-customer-modal' && (
        <Modal
          handleOpenModal={handleOpenModal}
          type="INVITE_CUSTOMER"
          open={openModal.open}
          id={openModal.id}
          // fetchCategories={fetchCategories}
        />
      )}
      <Card
        sx={{
          borderRadius: '0px',
          height: '368px',
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          boxShadow: 'rgba(58, 58, 80, 0.1) 0px 0px 15px',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            px: '20px !important',
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#3A3A50',
            }}
          >
            {t('startWithUpnizeWidget.title')}
          </Typography>
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
            }}
          >
            {initialGuideProgress.map((data) => (
              <Box
                onClick={data.onClick}
                sx={{
                  display: 'flex',
                  gap: '20px',
                }}
              >
                {/* <Box>
                    <DoneOutlinedIcon
                      sx={{
                        backgroundColor: data.isDone ? '#00E180' : '#E3E3E3',
                        borderRadius: '50%',
                        color: '#fff',
                      }}
                    />
                  </Box> */}
                <Box
                  sx={{
                    color: '#3A3A50',
                  }}
                >
                  {t(data.title)}
                </Box>
              </Box>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default InitialGuideWidget;
