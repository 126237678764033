import {
  addCategory,
} from '../../../Api/tenant/categories';
import { crudTypes } from '../../../constants/crudTypes';
import useCategory from '../../../hooks/useCategory';
import { drawersActions } from '../../../redux/slices/drawers.slice';
import { selectUser } from '../../../redux/slices/user.slice.js';
import { useCallback, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useCategoryDrawer = ({ crudType, data=null, refetchData }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(selectUser);

  const [categoryOptions, setCategoryOptions] = useState([])
  //category drawer
  const [categoryName, setCategoryName] = useState('');
  const [selectedParentCategory, setSelectedParentCategory] = useState({
    name: 'Root category',
    id: "root-id",
  });
  const { categories, subCategories } = useCategory();
  const toggleDrawer = () => {
    dispatch(drawersActions.closeDrawer());
  };

  // error handling
  const [errors, setErrors] = useState({ name: [] });

  const fetchCategoriesList = useCallback(async () => {
    try {

      setCategoryOptions([{ name: 'Root category', id: "root-id" }, ...categories.filter(category => category.type === "service")]);
    } catch (err) {
      console.log(err);
    }
  }, [categories]);

  useLayoutEffect(() => {
    fetchCategoriesList();
  }, [fetchCategoriesList]);

  //  add category
  const handleAddCategory = async () => {
    const errs = {
      name: [],
    };

    let hasError = false;

    if (!categoryName) {
      errs.name.push('Category Name Required');
      hasError = true;
    }

    if (
      categories.find((category) => category.name === categoryName) ||
      subCategories.find((category) => category.name === categoryName)
    ) {
      errs.name.push('category name already exists');
      hasError = true;
    }

    if (hasError) {
      setErrors(errs);
      return;
    }

    try {
      const response = await addCategory({
        parentId:
          selectedParentCategory.id === "root-id" ? null : selectedParentCategory.id,
        type: 'service',
        userId: user.id,
        name: categoryName,
      });

      if (response.status >= 200 && response.status < 300) {
        toggleDrawer();
        if(refetchData) refetchData();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditCategory = () => {};

  const handleSubmit = () => {
    switch (crudType) {
      case crudTypes.ADD:
        handleAddCategory();
        break;
      case crudTypes.EDIT:
        handleEditCategory();
        break;
      default:
        break;
    }
  };

  return {
    selectedParentCategory,
    categoryOptions,
    setSelectedParentCategory,
    categoryName,
    toggleDrawer,
    setCategoryName,
    errors,
    handleSubmit,
  };
};

export default useCategoryDrawer;
